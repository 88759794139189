import Vue from 'vue'

// STYLES
import '../../styles/less/main.less'

require('../bootstrap')

const VueInputMask = require('vue-inputmask').default
Vue.use(VueInputMask)

import VModal from 'vue-js-modal'
Vue.use(VModal)

import VueTippy from 'vue-tippy'
Vue.use(VueTippy)

import Clipboard from 'v-clipboard'
Vue.use(Clipboard)

import VueAgile from 'vue-agile'
Vue.use(VueAgile)

import CKEditor from '@ckeditor/ckeditor5-vue2'
Vue.use(CKEditor)

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

Vue.component('test-user', require('./components/TestUser.vue').default)
Vue.component('login-form', require('./components/LoginForm.vue').default)

Vue.component('fa', require('../common/components/FA.vue').default)
Vue.component('v-icon', require('../common/components/VueIcon.vue').default)
Vue.component('vue-radio', require('../common/components/VueRadio.vue').default)
Vue.component('vue-dropdown', require('./components/VueDropdown.vue').default)
Vue.component('vue-checkbox', require('../common/components/VueCheckbox.vue').default)
Vue.component('vue-datepicker', require('../common/components/DatePickerNew.vue').default)
Vue.component('vue-loading', require('../common/components/VueLoading.vue').default)
Vue.component('input-plus-min', require('./components/InputPlusMin.vue').default)

import MultiSelect from 'vue-multiselect'
Vue.component('vue-multiselect', MultiSelect)

Vue.component('vue-select', require('../common/components/VueSelect.vue').default)

Vue.component('bookings-wizard', require('./components/BookingsWizard.vue').default)
Vue.component('payment-response', require('./components/PaymentResponse.vue').default)
Vue.component('flight-row', require('./components/FlightRow.vue').default)
Vue.component('flight-pax-list', require('./components/FlightPaxList.vue').default)
Vue.component('pax-block', require('./components/PaxBlock.vue').default)
Vue.component('on-click-outside', require('./components/OnClickOutside.vue').default)


require('pusher-js')
import Echo from 'laravel-echo'
window.Echo = new Echo({
  broadcaster: 'pusher',
  cluster: 'eu',
  encrypted: true,
  wsHost: window.location.hostname,
  wssPort: 6002,
  key: '53201578',
  authorizer: (channel) => {
    return {
      authorize: (socketId, callback) => {
        axios.post('/api/broadcasting/auth', {
          socket_id: socketId,
          channel_name: channel.name
        })
        .then(response => {
          callback(false, response.data)
        })
        .catch(error => {
          callback(true, error);
        })
      }
    }
  }
})

import router from './router'
import App from '../App.vue'

window.EventBus = new Vue()

window.vm = new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
