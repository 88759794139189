<template>
  <section id="main-content" class="flex-1 w-full max-w-5xl justify-start items-start mx-auto px-2 flex flex-col bg-white min-h-screen mt-3 mb-5">
    <div class="md:mx-auto w-full md:w-2/3">
      <div class="my-4 md:my-0 rounded-lg shadow-xl border w-full h-full relative bg-white">
        <vue-loading :loading="loading" />
        <div class="w-full p-4">
          <div class="flex flex-col">
            <div class="w-full flex justify-center h-6">
              <span class="text-xl font-semibold text-blue-900 text-center px-3">
                ACCOUNT
              </span>
            </div>
          </div> 
          <div class="md:pt-2 flex flex-wrap md:flex-no-wrap">
            <form class="w-full flex flex-col px-0 md:px-10">
              <div class="mt-4">
                <div class="border-t border-gray-300 flex text-xs py-1 mb-1 italic text-gray-500 select-none">
                  <div class="w-full" v-html="locale.user_forms.label1" />
                </div>                  

                <div class="flex items-stretch mb-4">
                  <label class="select-none text-right text-blue-700 text-xs pt-2 pr-3 align-middle w-1/4">E-Mail</label>
                  <div class="flex flex-col w-3/4">
                    <span v-text="form.email" class="h-8 px-2 rounded border flex items-center select-none bg-gray-100" />
                  </div>
                </div>

                <div class="flex items-stretch mb-4">
                  <label class="select-none text-right text-blue-700 text-xs pt-0 md:pt-2 pr-3 align-middle w-1/4">
                    <span v-if="lang == 'it'">Password corrente</span>
                    <span v-if="lang == 'en'">Current Password</span>
                  </label>
                  <div class="flex-1 flex w-full md:w-40 h-8">
                    <input v-model="form.password_current" :type="pwdInvisible ? 'password' : 'text'" class="flex-grow px-2 border rounded rounded-r-none">
                    <span @click="pwdInvisible = ! pwdInvisible" class="flex items-center leading-normal bg-gray-200 rounded rounded-l-none border border-l-0 border-gray-300 px-2 whitespace-no-wrap text-gray-600 text-sm cursor-pointer outline-none" content="mostra password" v-tippy="{ arrow: true }">
											<fa :class="{ 's-eye': pwdInvisible, 's-eye-slash': ! pwdInvisible }" />
										</span>
                  </div>                  
                </div>

                <div class="flex items-stretch mb-4">
                  <label class="select-none text-right text-blue-700 text-xs pt-0 md:pt-2 pr-3 align-middle w-1/4">
                    <span v-if="lang == 'it'">Nuova Password</span>
                    <span v-if="lang == 'en'">New Password</span>
                  </label>
                  <div class="flex-1 flex w-full md:w-40">
                    <input v-model="form.password" :type="pwdInvisible ? 'password' : 'text'" class="flex-grow h-8 px-2 border rounded">
                  </div>
                </div>

                <div class="flex items-stretch mb-4">
                  <label class="select-none text-right text-blue-700 text-xs pt-0 md:pt-2 pr-3 align-middle w-1/4">
                    {{ locale.user_forms.password_confirmation }}
                  </label>
                  <div class="flex-1 flex w-full md:w-40">
                    <input v-model="form.password_confirmation" :type="pwdInvisible ? 'password' : 'text'" class="flex-grow h-8 px-2 border rounded">
                  </div>
                </div>

                <div class="border-t border-gray-300 flex text-xs py-1 mb-1 italic text-gray-500 select-none">
                  <div v-show="type == 'user'" class="w-full" v-text="locale.user_forms.label2a" />
                  <div v-if="type == 'agency'" class="w-full" v-text="locale.user_forms.label2b" />
                </div>                  

                <div class="flex items-stretch mb-4">
                  <label class="select-none text-right text-blue-700 text-xs pt-2 pr-3 align-middle w-1/4">
                    <div v-show="type == 'user'" v-text="locale.user_forms.lastname" />
                    <div v-if="type == 'agency'" v-text="locale.user_forms.company_name" />
                  </label>
                  <div class="flex flex-col w-3/4">
                    <span v-text="form.lastname" class="h-8 px-2 rounded border flex items-center select-none bg-gray-100" />
                  </div>
                </div>

                <div class="flex items-stretch mb-4">
                  <label class="select-none text-right text-blue-700 text-xs pt-2 pr-3 align-middle w-1/4">
                    <div v-show="type == 'user'" v-text="locale.user_forms.firstname" />
                    <div v-if="type == 'agency'" v-text="locale.user_forms.vat" />
                  </label>
                  <div class="flex flex-col w-3/4">
                    <span v-text="form.firstname" class="h-8 px-2 rounded border flex items-center select-none bg-gray-100" />
                  </div>
                </div>

                <div v-show="type == 'user'" class="flex items-stretch mb-4">
                  <label class="select-none text-right text-blue-700 text-xs pt-2 pr-3 align-middle w-1/4">
                    {{ locale.user_forms.gender }}
                  </label>
                  <div class="relative flex">
                    <div class="inline-block relative w-16">
                      <!-- <select v-model="form.gender" class="block appearance-none w-full bg-white border border-gray-300 hover:border-gray-500 px-2 py-1 pr-8 rounded" data-cy="pax-gender">
                        <option value="M">M</option>
                        <option value="F">F</option>
                      </select> -->
                      <span v-text="form.gender" class="h-8 px-2 rounded border flex items-center select-none bg-gray-100" />
                    </div>
                    <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-1 text-gray-700">
                      <fa class="s-caret-down" />
                    </div>
                  </div>
                </div>

                <div v-show="type == 'user'" class="flex items-stretch mb-4">
                  <label class="select-none text-right text-blue-700 text-xs pt-2 pr-3 align-middle w-1/4">
                    {{ locale.user_forms.birthdate }}
                  </label>
                  <div class="flex w-3/4">
                    <span class="flex items-center leading-normal bg-gray-200 rounded rounded-r-none border border-r-0 border-gray-300 px-2 whitespace-no-wrap text-gray-600 text-sm">
											<fa class="s-calendar-alt" />
										</span>
                    <span v-text="form.birthdate" class="h-8 px-2 rounded rounded-l-none border flex items-center select-none bg-gray-100" />
                  </div>
                </div>

                <div class="flex items-stretch mb-4">
                  <label class="select-none text-right text-blue-700 text-xs pt-2 pr-3 align-middle w-1/4">
                    {{ locale.user_forms.address }}
                  </label>
                  <div class="flex flex-col w-3/4">
                    <textarea v-model="form.address" type="text" class="flex-grow px-2 rounded border" />
                  </div>
                </div>

                <div class="border-t border-gray-300 flex text-xs py-1 mb-1 italic text-gray-500 select-none">
                  <div class="w-full">
                    {{ locale.user_forms.label3 }}
                  </div>
                </div>                  

                <div class="flex items-stretch mb-4">
                  <label class="select-none text-right text-blue-700 text-xs pt-2 pr-3 align-middle w-1/4">
                    {{ locale.user_forms.telephone }}
                  </label>
                  <div class="flex flex-col w-3/4">
                    <input v-model="form.telephone" type="text" class="flex-grow h-8 px-2 rounded border">
                  </div>
                </div>

                <div v-show="type == 'user'" class="border-t border-gray-300 flex text-xs py-1 mb-1 italic text-gray-500 select-none">
                  <div class="w-full">
                    {{ locale.user_forms.label4 }}
                  </div>
                </div>

                <div v-show="type == 'user'" class="flex items-stretch mb-4">
                  <label class="select-none text-right text-blue-700 text-xs pt-2 pr-3 align-middle w-1/4">
                    {{ locale.user_forms.document_type }}
                  </label>
                  <div class="relative flex">
                    <div class="inline-block relative">
                      <select v-model="form.document_type" class="block appearance-none w-full bg-white border border-gray-300 hover:border-gray-500 px-2 py-1 pr-8 rounded" data-cy="pax-gender">
                        <option :value="null"></option>
                        <option v-for="doc_type in lists.document_types" :key="doc_type.id" :value="doc_type.id" v-text="doc_type.text" />
                      </select>
                    </div>
                    <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-1 text-gray-700">
                      <fa class="s-caret-down" />
                    </div>
                  </div>
                </div>

                <div v-show="type == 'user'" class="flex items-stretch mb-4">
                  <label class="select-none text-right text-blue-700 text-xs pt-2 pr-3 align-middle w-1/4">
                    {{ locale.user_forms.document_num }}
                  </label>
                  <div class="flex flex-col w-40">
                    <input v-model="form.document_num" type="text" class="flex-grow h-8 px-2 rounded border">
                  </div>
                </div>

                <div class="flex flex-col mt-10 md:mt-0">
                  <div class="w-full flex flex-col items-end">
                    <button type="button" @click="update" data-cy="submit-update-account" class="w-32 bg-green-600 hover:bg-green-700 text-white text-sm font-semibold py-2 px-4 rounded">
                      <span v-if="lang == 'it'">Aggiorna</span>
                      <span v-if="lang == 'en'">Update</span>                      
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Locales from '../../locales.json'

export default {
  name: 'manage-account',
  data:() => ({
    loading: true,
    locales: null,
    type: 'user',
    message: {
      status: null,
      content: null
    },
    pwdInvisible: true,
    form: {
      id: null,
      email: null,
      password_current: null,
      password: null,
      password_confirmation: null,
      firstname: null,
      lastname: null,
      gender: null,
      birthdate: null,
      address: null,
      telephone: null,
      document_type: null,
      document_num: null,
      // email: 'cami@alidaunia.it',
      // password: 'Alidaunia1',
      // password_confirmation: 'Alidaunia1',
      // firstname: 'Anto',
      // lastname: 'Cami',
      // gender: 'M',
      // birthdate: '01/01/1980',
      // address: 'via del corso, 10',
      // city: 'Foggia',
      // province: 'FG',
      // country: 'Italy',
      // zipcode: 71121,
      // telephone: '+393210123456',
      // document_type: 1,
      // document_num: 'AS123ZX'
    },
    lists: {
      document_types: null
    }
  }),
  computed: {
    lang() {
      return this.$parent.lang
    },
		locale() {
			return this.locales[this.lang]
		},    
    user() {
      return this.$parent.auth.user
    },
    messageStatus() {
      let { status } = this.message
      if (status == 'success') {
        return ['border-green-500', 'bg-green-600', 'text-white']
      }
      else if (status == 'error') {
        return ['border-red-500', 'bg-red-600', 'text-white']
      }
      else {
        return ['border-white']
      }
    },
    dynMask() {
      let obj = {
        alias: 'date',
        inputFormat: 'dd/mm/yyyy'
      }

      if (this.lang == 'it') {
        obj = { ...obj, ...{ placeholder: 'gg/mm/aaaa' } }
      }
      else {
        obj = { ...obj, ...{ placeholder: 'dd/mm/yyyy' } }
      }

      return obj
    }
  },
  watch: {
    type(val) {
      if (val == 'agency') {
        this.form.gender = null
        this.form.birthdate = null
        this.form.document_type = null
        this.form.document_num = null
      }
    }
  },
  methods: {
    async update() {
      try {
        this.loading = true
        
        let { form } = this

        let { data } = await api.put('api.auth.update', form)

        this.loading = false

        if (data.hasOwnProperty('errors')) {
          return
        }

        this.$parent.$refs['user-drop'].getUserData()

        notifyS('Dati aggiornati')
      }
      catch(error) {
        callErrors(error)
        this.loading = false
      }
    },
		checkToken() {
			let token = localStorage.getItem('token')
			if (token) {
				// location.href = '/it/prenotazioni'
			}
    },
    async startData() {
      try {
        this.loading = true
        let { data: { document_types } } = await api.get('api.document-types.index')
        this.lists.document_types = document_types
        this.loading = false
      }
      catch(error) {
        callErrors(error)
        this.loading = false
      }
    },
    readFromUser() {
      this.type = ! this.user.is_agency ? 'user' : 'agency'

      this.form.id = this.user.id
      this.form.email = this.user.email
      this.form.lastname = this.user.pax.lastname
      this.form.firstname = this.user.pax.firstname
      this.form.gender = this.user.pax.gender
      this.form.birthdate = this.user.pax.birthdate
      this.form.address = this.user.pax.address
      this.form.telephone = this.user.pax.telephone
      this.form.document_type = this.user.pax.document_type
      this.form.document_num = this.user.pax.document_num

      this.loading = false
    }
  },
	created() {
    this.locales = Locales

    this.startData()

    if (this.$parent.auth.token && this.$parent.auth.user) {
      this.$router.push({ name: 'booking' })
      return
    }
    this.checkToken()
    
    EventBus.$on('user-ready', x => this.readFromUser())
	}
}
</script>
