<template>
  <section class="flex-1 w-full max-w-5xl justify-start mx-auto flex flex-col bg-white">
    <home-page-it v-if="lang == 'it'" />
    <home-page-en v-if="lang == 'en'" />
  </section>
</template>

<script>
import HomePageIt from './HomePage_it'
import HomePageEn from './HomePage_en'

export default {
  name: 'HomePage',
  components: {
    HomePageIt,
    HomePageEn
  },
  computed: {
    lang() {
      return this.$parent.lang
    },
    extLibraries() {
      return this.$parent.extLibraries
    }
  },
  methods: {
    async initFlapper() {
      $('div.flapper').remove()

      var flightDisplay1 = $('#display1').flapper({ width: 23, align: 'left', timing: 250, chars_preset: "num", chars: [" ","9","8","7","6","5","4","3","2","1","0","9"] });
      var flightDisplay2 = $('#display2').flapper({ width: 12, align: 'left', timing: 250, chars_preset: "num", chars: [" ","9","8","7","6","5","4","3","2","1","0","9"] }).val('').change();
      flightDisplay1.val('').change();
      var nextFlightDateTime = '';
      var message = '';

      var secondsToNextFlight = 0;

      async function readFromServer() {
        let data = await $.getJSON(window.location.origin + '/api/next-flight')
        nextFlightDateTime = data.nextFlightDate + ' ' + data.nextFlightTime
        $('#nextFlightDate').html(data.nextFlightDate)
        message = data.displayMessage
        secondsToNextFlight = data.secondsToNextFlight
        flightDisplay1.val(data.displayMessage).change()

        // try {
        //   let { data } = await api.get('api.next-flight')
        //   console.log(data)
        // }
        // catch(error) {
        //   callErrors(error)
        // }
      }
      await readFromServer()

      function addZero(i) {
        if (i < 10) {
          i = "0" + i;
        }
        return i;
      }
      function addSpace(i) {
        if (i < 10) {
          i = " " + i;
        }
        return i;
      }
      function readDate(val) {
        if (val < 0) {
          readFromServer()
          return ''
        }
        var timeString = '';
        var diff = val * 1000;
        var duration = moment.duration(diff)
        if (diff < -10000) {
          width = 276;
          timeString = '     DELAYED';
        }
        else {
          var width = 250;
          var durDays = duration.days()
          var durHours = duration.hours()
          if (durDays >= 10) {
            width = 148
            timeString += addSpace(durDays) + 'g'
          }
          if (durDays >= 1 && durDays <= 9) {
            width = 172
            timeString += addSpace(durDays) + 'g'
          }
          if (durDays == 0 && durHours >= 10) {
            width = 224;
            timeString += '   ' + duration.hours() + 'h'
          }
          if (durDays == 0 && durHours <= 9) {
            width = 250;
            timeString += '    ' + duration.hours() + 'h';
          }
          if (durDays > 0 && durHours <= 9) {
            timeString += addZero(duration.hours()) + 'h';
          }

          timeString += addZero(duration.minutes()) + 'm' + addZero(duration.seconds()) + 's';
        }

        $('#dynamic-column').css({ width: width + 'px' })
        return timeString
      }

      setInterval(x => {
        flightDisplay2.val(readDate(secondsToNextFlight)).change()
        secondsToNextFlight--
      }, 1000)
    }
  },
  mounted() {
    EventBus.$on('extLibrariesLoaded', () => this.initFlapper())

    let { extLibraries } = this
    
    if (extLibraries) {
      this.initExtLibraries()
    }
  },
  beforeDestroy() {
    dbg(null, '► BEFORE DESTROY')
    $('div.flapper').remove()
  },
  destroyed() {
    dbg(null, '► DESTROYED')
  }
}
</script>
