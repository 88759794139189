<template>
  <section class="w-full max-w-5xl mx-auto bg-white min-h-screen">
    <div class="relative w-full flex flex-col">
      <div class="flex">
        <div class="w-full flex my-2 h-8">
          <span class="flex-1 self-center border-t border-gray-300"></span>
          <span class="text-xl font-semibold text-blue-900 text-center px-3">
            {{ locale.MyTickets }}
          </span>
          <span class="flex-1 self-center border-t border-gray-300"></span>
        </div>
      </div>
      <div class="relative flex flex-col mb-10 border rounded">
        <vue-loading :loading="loading" />
        <div class="flex flex-col p-3">
          <div v-if="searchVisible" class="mt-0 md:mt-6 text-gray-800 flex justify-between">
            <div class="w-full flex flex-col md:flex-row">
              <div class="md:hidden w-full flex justify-end">
                <button v-if="searchVisible" @click="resetFilters" type="button" class="px-2 py-1 bg-indigo-300 text-white text-xs">
                  svuota filtri
                </button>
              </div>
              <div class="w-full md:w-auto flex flex-row">
                <div class="w-1/2 md:w-auto flex flex-col items-start mr-6 mt-3 md:mt-0">
                  <label class="text-xs italic mr-3">Data volo</label>
                  <div class="w-32">
                    <vue-datepicker v-model="filters.flightDate" />
                  </div>
                </div>
              </div>
              <div class="w-full md:w-auto flex flex-row">
                <div class="w-1/2 md:w-auto flex flex-col items-start mr-6 mt-3 md:mt-0">
                  <label class="text-xs italic mr-3">Cod.prenotaz.</label>
                  <input type="text" v-model="filters.bookingCode" maxlength="8" class="block bg-white appearance-none w-full md:w-24 border border-gray-400 hover:border-gray-500 px-2 py-1 rounded">
                </div>
                <div class="w-1/2 md:w-auto flex flex-col items-start md:mr-6 mt-3 md:mt-0">
                  <label class="text-xs italic mr-3">Num.tkt</label>
                  <input type="text" v-model="filters.ticketNr" maxlength="7" class="block bg-white appearance-none w-full md:w-20 border border-gray-400 hover:border-gray-500 px-2 py-1 rounded">
                </div>
              </div>
              <div class="w-full md:w-auto flex flex-row">
                <div class="w-1/2 md:w-auto flex flex-col items-start mr-6 mt-3 md:mt-0">
                  <label class="text-xs italic mr-3">Cognome</label>
                  <input type="text" v-model="filters.lastname" class="block bg-white appearance-none w-full md:w-28 border border-gray-400 hover:border-gray-500 px-2 py-1 rounded">
                </div>
                <div class="w-1/2 md:w-auto flex flex-col items-start md:mr-6 mt-3 md:mt-0">
                  <label class="text-xs italic mr-3">Nome</label>
                  <input type="text" v-model="filters.firstname" class="block bg-white appearance-none w-full md:w-28 border border-gray-400 hover:border-gray-500 px-2 py-1 rounded">
                </div>
              </div>
              <div class="flex flex-col items-start mr-6 mt-3 md:mt-0">
                <label class="text-xs italic mr-3">includi annullati</label>
                <vue-checkbox v-model="filters.includeCancelled" color="red" />
              </div>
            </div>
          </div>
          <div v-if="! editMode" class="w-full flex justify-end mt-5 md:mt-0">
            <button @click.prevent="search(true)" type="button" class="w-32 bg-blue-500 hover:bg-blue-600 text-white text-sm font-semibold p-1 rounded">
              vedi lista
            </button>
          </div>
          <div class="text-blue-600 flex justify-end" v-if="editMode">
            <button v-if="! finalStep" @click="backToSearch" type="button" class="bg-blue-500 hover:bg-blue-600 text-white text-sm font-semibold py-1 px-2 rounded flex justify-between items-center">
              <fa class="s-caret-left mr-2" />
              torna a ricerca
            </button>
            <button v-if="finalStep" @click="bookingsFinalStep = []" type="button" class="w-40 bg-blue-500 hover:bg-blue-600 text-white text-sm font-semibold py-1 px-2 rounded flex justify-between items-center">
              <fa class="s-caret-left mr-2" />
              torna indietro
            </button>
          </div>
          <div class="mt-6 flex justify-center">
            <div style="font-size: 13px; text-align: center; font-style: italic; color: rgb(66, 139, 202); margin-top: 10px; background-color: lightgoldenrodyellow; padding: 4px; border: 1px solid orange;">
              In ottemperanza alle disposizioni degli enti governativi e delle strutture del trasporto aereo internazionale, si avvisa che:<br>
              <strong>
              • i voli con partenza da Foggia e destinazione Tremiti (e viceversa) possono essere acquistati fino a 6 ore prima del decollo
              <br>
              • i voli con partenza (o destinazione) dalle altre località sono acquistabili fino a 12 ore prima del decollo
              </strong>
            </div>
            <!-- <span class="text-red-600 font-semibold italic border border-red-500 rounded px-1 bg-red-100">
              I biglietti sono modificabili fino a:<br>
              • 6h dall'orario di volo solo per i FG-TR / TR-FG<br>
              • 12h dall'orario di volo per tutti gli altri voli
            </span> -->
          </div>
          <div class="mt-2 text-gray-700 flex flex-col justify-between">
            <div id="scrollable-container" class="w-full overflow-x-scroll md:overflow-x-hidden">
              <table class="w-full border">
                <thead>
                  <tr class="text-left border-b-2 bg-blue-200 text-blue-600 text-xs">
                    <th width="10%" class="border px-1 text-center bg-green-200" v-show="editMode && ! finalStep">Sposta su</th>
                    <th width="35%" class="border px-1">Passeggero</th>
                    <th width="2%" class="border px-1" v-show="finalStep">&nbsp;</th>
                    <th width="14%" class="border px-1 whitespace-no-wrap">Data volo</th>
                    <th width="4%" class="border px-1">Da</th>
                    <th width="4%" class="border px-1">A</th>
                    <th width="8%" class="border px-1 whitespace-no-wrap">Ticket Nr.</th>
                    <th width="8%" class="border px-1">Prenotazione</th>
                    <th width="4%" class="border px-1">Tariffa</th>
                    <th width="8%" v-show="! finalStep" class="border px-1 text-center">Costo</th>
                    <th v-show="finalStep" class="border px-1 text-right">Biglietto</th>
                    <th v-show="finalStep" class="border px-1 text-right">Tasse</th>
                    <th v-show="finalStep" class="border px-1 text-right">Sbarco</th>
                    <th class="border px-1" v-show="! finalStep">&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(booking, index) in bookingsCustomList" :key="booking.id" :style="bookingRowStyle(booking, index)">
                    <td v-show="editMode && ! finalStep" class="text-center bg-green-100 px-2">
                      <select v-if="editable(booking) && booking.idPax != 100" :disabled="isLocked" class="border w-16 bg-white" v-model="booking.movedOn" @change="updatedMovedOn(booking)">
                        <option :value="0"></option>
                        <option v-for="(travel, index) in visibleTravels(booking)" :key="travel.id" :value="travel.id" v-text="`volo ${index + 1}`" />
                      </select>
                      <div v-if="! editable(booking)" class="flex items-center justify-center">
                        <fa class="s-exclamation-triangle text-orange-500 text-base" v-tippy="{ arrow: false }" :content="editExpiryDate(booking)" />
                      </div>
                    </td>
                    <td v-show="cancelMode && ! finalStep" class="text-center bg-red-100">
                      <vue-checkbox v-model="booking.cancelled" color="crimson" @change="status => modifyCancelled(booking, status)" />
                    </td>
                    <td :rowspan="finalStep && booking.movedOn ? 2 : 1" v-if="booking.id > 0" class="border px-2 py-1" :style="{ borderBottom: finalStep && booking.movedOn ? '2px solid lightgray': '' }">
                      <div class="flex justify-between items-center whitespace-no-wrap">
                        <span>
                          {{ booking.lastname}} {{ booking.firstname }}
                          <small>- {{ booking.birthDate }}</small>
                        </span>
                        <fa v-if="! editMode" class="s-user-edit text-blue-500 cursor-pointer ml-1" @click.native="showModalPax(booking)" content="modifica anagrafica" v-tippy="{ arrow: false, animateFill: false, theme : 'translucent' }" />
                      </div>
                    </td>
                    <td class="border px-1 text-xs text-center" v-show="finalStep">
                      <span v-if="booking.id > 0 && booking.movedOn">DA</span>
                      <span v-if="booking.id < 0">A</span>
                    </td>
                    <td class="border px-2 py-1 whitespace-no-wrap">
                      {{ booking.takeOffDate }} <small>{{ booking.takeOffTime }}</small>
                      <fa class="r-clock text-xs text-blue-500" :content="`prenotato il ` + booking.bookingDate + ` alle ` + booking.bookingTime" v-tippy="{ arrow: false, animateFill: false, theme : 'translucent' }" />
                    </td>
                    <td class="border px-2 py-1">
                      <span v-if="! finalStep || booking.id > 0 || (index > 0 && booking.origin != bookingsCustomList[index - 1].origin)">
                        {{ booking.label_origin }}
                      </span>
                    </td>
                    <td class="border px-2 py-1">
                      <span v-if="! finalStep || booking.id > 0 || (index > 0 && booking.destination != bookingsCustomList[index - 1].destination)">
                        {{ booking.label_destination }}
                      </span>
                    </td>
                    <td class="border px-2 py-1">
                      <div v-if="! finalStep || booking.id > 0" class="flex justify-between items-center">
                        {{ booking.ticketNr }}
                        <fa v-if="booking.agency" class="s-info-circle text-xs text-blue-500 mx-2" content="prenotato da agenzia" v-tippy="{ arrow: false, animateFill: false, theme : 'translucent' }" />
                        <sup class="flex flex-col items-center mt-2 cursor-pointer" :content="supContent(booking)" v-tippy="{ arrow: false }">
                          <span v-if="booking.dw && booking.dw_flight == 'd'">A</span>
                          <span class="mt-2 text-sm" v-if="booking.dw && booking.dw_flight == 'd'">→</span>

                          <span v-if="booking.dw && booking.dw_flight == 'c'">R</span>
                          <span class="mt-2 text-sm" v-if="booking.dw && booking.dw_flight == 'c'">←</span>
                        </sup>
                      </div>
                    </td>
                    <td class="border px-2 py-1">
                      <div class="flex items-center">
                        <span v-if="booking.paymentType == 10" class="bg-yellow-300 px-1 rounded" content="id%50" v-tippy="{ arrow: false, animateFill: false, theme : 'translucent' }">
                          {{ booking.bookingCode}}
                        </span>
                        <span v-if="booking.paymentType != 10 && (! finalStep || booking.id > 0)">
                          {{ booking.bookingCode}}
                        </span>
                      </div>
                    </td>
                    <td class="border px-2 py-1">
                      <div v-if="! finalStep || booking.id > 0 || (index > 0 && booking.fare != bookingsCustomList[index - 1].fare)">
                        {{ booking.fare }} <span v-if="booking.discount">- <span class="rounded bg-yellow-300 px-1">{{ booking.discount}}</span></span>
                      </div>
                    </td>
                    <td v-if="! finalStep" class="border px-2 py-1 text-right whitespace-no-wrap">
                      {{ parseFloat(booking.ticketCost).toFixed(2) }}
                      <fa class="s-info-circle text-xs text-blue-500 ml-2" :content="tippyTotal(booking)" v-tippy="{ arrow: false, animateFill: false, theme : 'translucent' }" />
                    </td>
                    <td v-if="finalStep" class="border px-2 py-1 text-right">
                      <div v-if="! finalStep || booking.id > 0 || (index > 0 && booking.ticket != bookingsCustomList[index - 1].ticket)">
                        {{ parseFloat(booking.ticket).toFixed(2) }}
                      </div>
                    </td>
                    <td v-if="finalStep" class="border px-2 py-1 text-right">
                      <div v-if="! finalStep || booking.id > 0 || (index > 0 && booking.tax != bookingsCustomList[index - 1].tax)">
                        {{ parseFloat(booking.tax).toFixed(2) }}
                      </div>
                    </td>
                    <td v-if="finalStep" class="border px-2 py-1 text-right">
                      <div v-if="! finalStep || booking.id > 0 || (index > 0 && booking.landingFee != bookingsCustomList[index - 1].landingFee)">
                        {{ parseFloat(booking.landingFee).toFixed(2) }}
                      </div>
                    </td>
                    <td class="border px-2 py-1" v-show="! finalStep">
                      <div v-show="booking.paymentType != 12 && ! isPending(booking.bookingCode)" class="flex ___justify-end">
                        <button v-show="searchVisible && editable(booking)" @click="showEditMode(booking)" class="text-blue-500 rounded rounded-r-none bg-blue-100 hover:bg-blue-200 leading-none border border-blue-200 focus:outline-none w-6 h-6 mr-2 flex items-center justify-center" v-tippy="{ arrow: false, animateFill: false, theme : 'translucent' }" content="modifica biglietto" >
                          <fa class="r-edit" />
                        </button>
                        <button @click="showPdf(booking.serial_ticket)" class="text-blue-500 rounded bg-blue-100 hover:bg-blue-200 leading-none border border-blue-200 focus:outline-none w-6 h-6 mr-2 flex items-center justify-center" v-tippy="{ arrow: false, animateFill: false, theme : 'translucent' }" content="vedi pdf singolo biglietto" >
                          <fa class="r-file-alt" />
                        </button>
                        <button @click="showPdf(booking.serial_booking)" v-show="searchVisible" class="text-blue-500 rounded bg-blue-100 hover:bg-blue-200 leading-none border border-blue-200 focus:outline-none w-6 h-6 mr-2 flex items-center justify-center" v-tippy="{ arrow: false, animateFill: false, theme : 'translucent' }" content="vedi pdf intera prenotazione" >
                          <fa class="r-copy" />
                        </button>
                        <button v-show="searchVisible && editable(booking)" @click="showModalEmail(booking)" class="text-blue-500 rounded bg-blue-100 hover:bg-blue-200 leading-none border border-blue-200 focus:outline-none w-6 h-6 flex items-center justify-center" v-tippy="{ arrow: false, animateFill: false, theme : 'translucent' }" content="invia mail pdf" >
                          <fa class="r-envelope" />
                        </button>
                      </div>
                      <div v-show="booking.paymentType == 12" class="italic text-xs whitespace-no-wrap">
                        biglietti in corso di modifica
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <table v-if="bookingsCancelled.length" class="mt-10 w-3/5 border">
              <thead>
                <tr class="text-left border-b-2 bg-blue-200 text-blue-600 text-xs">
                  <th width="20%" class="border px-1">Passeggero</th>
                  <th width="10%" class="border px-1">Ticket Nr.</th>
                  <th width="10%" class="border px-1">Biglietto</th>
                  <th width="10%" class="border px-1">Tasse</th>
                  <th width="10%" class="border px-1">Sbarco</th>
                  <th width="10%" class="border px-1">Tot.Pagato</th>
                  <th width="10%" class="border px-1">Rimborso %</th>
                  <th width="10%" class="border px-1">Trattenuta</th>
                  <th width="10%" class="border px-1">Rimborso €</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="booking in bookingsCancelled" :key="booking.id">
                  <td class="border px-2 py-1">{{ booking.lastname}} {{ booking.firstname }}</td>
                  <td class="border px-2 py-1 text-right">{{ booking.ticketNr }}</td>
                  <td class="border px-2 py-1 text-right">
                    {{ parseFloat(booking.ticket).toFixed(2) }}
                  </td>
                  <td class="border px-2 py-1 text-right">
                    <div class="flex justify-between items-center">
                      <vue-checkbox v-model="booking.refunded_tax" />
                      {{ parseFloat(booking.tax).toFixed(2) }}
                    </div>
                  </td>
                  <td class="border px-2 py-1 text-right">
                    <div class="flex justify-between items-center">
                      <vue-checkbox v-model="booking.refunded_landingFee" />
                      {{ parseFloat(booking.landingFee).toFixed(2) }}
                    </div>
                  </td>
                  <td class="border px-2 py-1 font-semibold text-right" v-html="calculatePaid(booking)" />
                  <td class="border px-2 py-1 text-right">
                    <input v-model="booking.refunded_perc" type="text" class="w-full border px-2 text-right" />
                  </td>
                  <td class="border px-2 py-1 text-right">
                    <input v-model="booking.refunded_fee" type="text" class="w-full border px-2 text-right" />
                  </td>
                  <td class="border px-2 py-1 font-bold text-right" v-html="calculateRefund(booking)" />
                </tr>
              </tbody>
            </table>

            <div v-if="! finalStep" class="flex flex-col justify-between">
              <div class="flex justify-end mt-8">
                <button v-if="editMode" type="button" class="rounded px-3 py-1 text-sm focus:outline-none select-none" :class="addTravelButtonClass" @click="addTravel">
                  <fa class="s-plus mr-1" />
                  aggiungi volo
                </button>
              </div>

              <transition-group name="fade" v-if="editMode && bookings.length">
                <travel-slot
                  v-for="(travel, index) in travels"
                  :key="travel.id"
                  :label="index + 1"
                  :progressive="travel.id"
                  :bookings="travelBookings(travel.id)"
                  @updated="travelUpdated"
                  @removed="remTravel(travel)"
                  @lock="x => isLocked = x"
                />
              </transition-group>
            </div>

            <div v-if="editMode && ! finalStep" class="mt-5 w-full flex justify-end">
              <button type="button" @click="confirmBooking(0)" class="w-40 text-white text-sm font-semibold p-1 rounded" :class="{ 'bg-green-300 cursor-not-allowed': elaborateGrandTotalEnabled, 'bg-green-500 hover:bg-green-600': ! elaborateGrandTotalEnabled }">
                calcola importo
              </button>
            </div>

            <transition name="slide-fade">
              <div v-if="editMode && finalStep" class="md:p-4 mt-4 rounded border-0 border-green-600 flex justify-end">
                <div class="w-full flex flex-col items-end">
                  <div class="hidden md:block mb-10">
                    <table class="w-full border" style="width:600px">
                      <tr>
                        <th class="w-1/4 border p-1 text-xs bg-green-200 text-green-600">Nr. biglietti modificati</th>
                        <th class="w-1/4 border p-1 text-xs bg-green-200 text-green-600">Differenza</th>
                        <th class="w-1/4 border p-1 text-xs bg-green-200 text-green-600">Costi amministrativi</th>
                        <th class="w-1/4 border p-1 text-xs bg-green-200 text-green-600">Totale da versare</th>
                      </tr>
                      <tr>
                        <td class="border text-center font-semibold p-1">{{ bookingsFinalStep.filter(b => b.id < 0).length }}</td>
                        <td class="border text-center font-semibold p-1">{{ parseFloat(costs.total - costs.adminFeeTemp).toFixed(2) }}</td>
                        <td class="border text-center font-semibold p-1">{{ parseFloat(costs.adminFee).toFixed(2) }}</td>
                        <td class="border text-center font-bold p-1">{{ grandTotalLabel }}</td>
                      </tr>
                    </table>
                  </div>

                  <div class="block md:hidden mb-10 w-3/5">
                    <table class="w-full border">
                      <tr>
                        <td class="w-1/4 border p-1 text-xs bg-green-200 text-green-600 whitespace-no-wrap">Nr. biglietti modificati</td>
                        <td class="border text-right font-semibold p-1">{{ bookingsFinalStep.filter(b => b.id < 0).length }}</td>
                      </tr>
                      <tr>
                        <td class="w-1/4 border p-1 text-xs bg-green-200 text-green-600">Differenza</td>
                        <td class="border text-right font-semibold p-1">{{ parseFloat(costs.total - costs.adminFeeTemp).toFixed(2) }}</td>
                      </tr>
                      <tr>
                        <td class="w-1/4 border p-1 text-xs bg-green-200 text-green-600 whitespace-no-wrap">Costi amministrativi</td>
                        <td class="border text-right font-semibold p-1">{{ parseFloat(costs.adminFee).toFixed(2) }}</td>
                      </tr>
                      <tr>
                        <td class="w-1/4 border p-1 text-xs bg-green-200 text-green-600 whitespace-no-wrap">Totale da versare</td>
                        <td class="border text-right font-bold p-1">{{ grandTotalLabel }}</td>
                      </tr>
                    </table>
                  </div>

                  <button v-if="! showPayPal" @click.prevent="confirmBooking(1)" type="button" class="w-56 text-white text-sm font-semibold p-1 rounded bg-green-500 hover:bg-green-600 flex flex-col items-center">
                    <div class="flex">
                      <fa class="r-credit-card mr-1" />
                      pagamento
                    </div>
                    <!-- <span class="italic text-xs font-normal">trasferimento sul sito bancasella</span> -->
                  </button>

                  <my-tickets-pay-pal v-if="showPayPal" />
                </div>
              </div>
            </transition>

            <div v-if="searchVisible" class="w-full mt-4 flex justify-between border-t-2 border-blue-200 pt-2">
              <div class="flex" v-if="pagination && ! editMode">
                <div class="italic text-black mr-2">
                  pagine
                </div>
                <div v-if="pagination.rangeFirst" class="ml-1">
                  <button @click="changePage(1)" class="ml-1 px-1 h-6 leading-none border border-blue-600 rounded text-base" v-text="`1`" />
                  ...
                </div>
                <button @click="changePage(p)" v-for="p in pagination.range" :key="p" :class="{ 'bg-blue-500 text-white': pagination.current_page == p, 'bg-white text-blue-500': pagination.current_page != p }" class="ml-1 px-1 h-6 leading-none border border-blue-600 rounded text-base" v-text="p" />
                <div v-if="pagination.rangeLast" class="ml-1">
                  ...
                  <button @click="changePage(pagination.last_page)" class="ml-1 px-1 h-6 leading-none border border-blue-600 rounded text-base" v-text="pagination.last_page" />
                </div>
              </div>
              <div class="italic text-black" v-if="pagination && ! editMode">
                visualizzati da {{ pagination.from }} a {{ pagination.to }} ~ risultati trovati {{ pagination.total }}
              </div>
            </div>
          </div>
          <div class="mt-10 w-full border border-gray-300 rounded">
            <div class="px-1 bg-gray-300 text-black italic">GUIDA ALL'USO</div>
            <div v-if="! editMode && ! cancelMode">
              <ul class="pl-5 list-disc p-1 text-xs">
                <li>per visualizzare l'elenco dei biglietti acquistati, cliccare "vedi lista";</li>
                <li>l'elenco può essere filtrato compilando i campi di ricerca e cliccando successivamente "vedi lista";</li>
                <li>per includere i biglietti annullati, spuntare la casella apposita;</li>
                <li>per resettare i campi di ricerca, cliccare "svuota filtri".</li>
              </ul>
              <div class="px-1 italic">lista biglietti</div>
              <ul class="pl-5 list-disc p-1 text-xs">
                <li>è possibile scorrere la lista biglietti a destra e sinistra;</li>
                <li>
                  icone:<br>
                  <fa class="s-user-edit text-blue-500" /> per accedere all'anagrafica del passeggero<br>
                  <fa class="r-clock text-blue-500" /> mostra data/ora prenotazione biglietto<br>
                  <fa class="s-info-circle text-blue-500" /> mostra il dettaglio costi del biglietto<br>
                  <fa class="r-edit text-blue-500" /> per accedere alla modifica del biglietto<br>
                  <fa class="r-file-alt text-blue-500" /> apre pdf del singolo biglietto<br>
                  <fa class="r-copy text-blue-500" /> apre pdf dell'intera prenotazione<br>
                  <fa class="r-envelope text-blue-500" /> per reinviare i biglietti via email<br>
                </li>
              </ul>
              <div v-if="editMode && ! finalStep">
                <ul class="pl-5 list-disc p-1 text-xs">
                  <li>per spostare il passeggero su un nuovo volo, selezionare dalla colonna "sposta" il volo desiderato</li>
                  <li>se viene visualizzata l'icona <fa class="s-exclamation-triangle text-orange-500"/>, il biglietto non può essere modificato (leggere il messaggio che compare cliccando sull'icona stessa)</li>
                  <li>inizialmente è attivo solo il "volo 1". in caso di spostamento su voli diversi, cliccare il tasto "aggiungi volo" per predispore la ricerca di ulteriori voli</li>
                  <li>un volo aggiunto, ma non più necessario, dev'essere rimosso (cliccando la X rossa) per poter procedere alla fase successiva</li>
                  <li>nel riquadro numerato di ciascun volo, verificare che ci siano i passeggeri selezionati nella colonna "sposta"</li>
                  <li>selezionare Partenza, Destinazione, Nuova data volo e cliccare "cerca volo"</li>
                  <li>una volta comparso l'elenco dei voli disponibili, selezionare quello desiderato</li>
                  <li>cliccare "calcola importo" per procedere alla fase finale di riepilogo e pagamento</li>
                </ul>
              </div>
              <div v-if="editMode && finalStep">
                <ul class="pl-5 list-disc p-1 text-xs">
                  <li>nell'elenco dei biglietti, sono evidenziate nelle righe verdi le variazioni che saranno applicate</li>
                  <li>in grigio sono riportati i biglietti per cui non è stata richiesta modifica</li>
                  <li>cliccare "pagamento" per essere reindirizzati sul sito di BancaSella e completare l'operazione</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal-pax ref="modal-pax" />
    <modal-email ref="modal-email" />
  </section>
</template>

<script>
import Locales from '../../locales.json'

// Import component
import Loading from 'vue-loading-overlay'
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css'

import TravelSlot from './MyTickets_TravelSlot'

import ModalPax from '../../common/components/Bookings_ModalPax'
import ModalEmail from '../../common/components/Bookings_ModalEmail'

import MyTicketsPayPal from './MyTickets_PayPal.vue'

export default {
  name: 'my-tickets',
  components: {
    Loading,
    ModalPax,
    ModalEmail,
    TravelSlot,
    MyTicketsPayPal,
  },
  data:() => ({
    locales: null,
    loading: false,
    isLocked: false,
    editMode: false,
    cancelMode: false,
    editBooking: {
      code: null,
      /*
      departure: {
        flightDate: null,
        origin: null,
        destination: null,
        heliports: [
        {
          id: 1348,
          txt: 'Foggia'
        },
        {
          id: 1511,
          txt: 'Tremiti'
        },
        {
          id: 1514,
          txt: 'Vieste'
        },
        ],
        flight: null,
        flights: [],
        loading: false
      },
      comeback: {
        flightDate: null,
        origin: null,
        destination: null,
        heliports: [
        {
          id: 1349,
          txt: 'Foggia'
        },
        {
          id: 1511,
          txt: 'Tremiti'
        },
        {
          id: 1514,
          txt: 'Vieste'
        },
        ],
        flight: null,
        flights: [],
        loading: false
      }
      */
    },
    filters: {
      flightDate: null,
      bookingCode: null,
      ticketNr: null,
      lastname: null,
      firstname: null,
      includeCancelled: false
    },
    form: {
      email_payment_link: null
    },
    pagination: null,
    bookings: [],
    bookingsPending: [],
    bookingsFinalStep: [],
    bookingsCancelled: [],
    travels: [],
    grandTotal: null,
    costs: {
      ticket: null,
      tax: null,
      landingFee: null,
      adminFee: 3,
      adminFeeTemp: 3,
      adminFeeCheck: true,
      total: 20
    },
    cashLocked: true,
    alidauniaLocked: true,
    bankLink: null,
    bookingRequestUuid: null,
    showPayPal: false,
  }),
  computed: {
    locale() {
      return this.locales[this.$parent.lang]
    },
    grandTotalLabel() {
      return parseFloat(this.costs.total).toFixed(2)
    },
    searchVisible() {
      return ! this.editMode && ! this.cancelMode
    },
    finalStep() {
      return !! this.bookingsFinalStep.length
    },
    bookingsCustomList() {
      if (! this.bookingsFinalStep.length) {
        return this.bookings
      }
      else {
        return this.bookingsFinalStep
      }
    },
    elaborateGrandTotalEnabled() {
      return false
    },
    confirmButtonDisabled() {
      return true
    },
    addTravelButtonClass() {
      if (this.travels.length == this.bookings.length) {
        return ['pointer-events-none bg-gray-200']
      }
      else {
        return ['bg-green-700 text-white']
      }
    },
    userId() {
      return this.$parent.auth.user.id
    }
  },
  watch: {
    'modalEmail.email': {
      handler(val) {
        if (val.recipientType != 2) {
          this.modalEmail.email.emailCustom = null
          if (this.$refs.emailCustom) {
            this.$refs.emailCustom.blur()
          }
        }

        if (val.recipientType == 2) { this.$refs.emailCustom.focus() }
      },
      deep: true
    },
    'costs.adminFeeCheck'(val) {
      if (val) {
        this.costs.adminFee = clone(this.costs.adminFeeTemp)
        this.costs.adminFeeTemp = 0
      }
      else {
        this.costs.adminFeeTemp = clone(this.costs.adminFee)
        this.costs.adminFee = 0
      }
    }
  },
  methods: {
    editable(booking) {
      return moment().format('YYYY-MM-DD hh:mm:ss') <= booking.editable_until
    },
    editExpiryDate(booking) {
      return 'termine ultimo di modifica<br>' + (moment(booking.editable_until, 'YYYY-MM-DD hh:mm:ss').format('DD/MM/YYYY hh:mm'))
    },
    showModalPax(booking) {
      // console.log(booking)

      let paxObj = {
        id: booking.idPax,
        lastname: booking.lastname,
        firstname: booking.firstname,
        birthdate: booking?.birthdate || booking?.birthDate,
        doc_type: booking.doc_type,
        doc_num: booking.doc_num,
        doc_expdate: booking.doc_expdate,
        address: booking.address,
        emailPax: booking.emailPax,
        telPax: booking.telPax,
      }

      // console.log(this.$refs['modal-pax'].form)
      // console.log(paxObj)

      this.$refs['modal-pax'].form = Object.assign(this.$refs['modal-pax'].form, paxObj)

      this.$nextTick(x => this.$refs['modal-pax'].show = true)
    },
    showModalEmail(booking) {
      this.$refs['modal-email'].form = {
        pdfType: 0,
        recipientType: 0,
        ticketNr: booking.ticketNr,
        bookingCode: booking.bookingCode,
        emailPax: booking.emailPax,
        emailBooking: booking.emailBooking,
        emailCustom: null,
        pdfForms: {
          minor: false,
          pregnant: false,
          weapons: false
        }
      }

      if (booking.email_pdf_forms) {
        this.$refs['modal-email'].form.pdfForms = clone(booking.email_pdf_forms)
        this.$refs['modal-email'].labels = clone(booking.email_pdf_forms)
      }

      this.$nextTick(x => this.$refs['modal-email'].show = true)
      console.log(1231312)
    },
    tippyTotal(booking) {
      return '<div class="text-right">biglietto: ' + parseFloat(booking.ticket).toFixed(2) + '<br>tasse: ' + parseFloat(booking.tax).toFixed(2) + '<br>contr.sbarco: ' + parseFloat(booking.landingFee).toFixed(2) + '</div>'
    },
    showPdf(serial) {
      window.open(serial)
    },
    calculatePaid(booking) {
      return parseFloat(booking.ticket + booking.tax + booking.landingFee).toFixed(2)
    },
    calculateRefund(booking) {
      // var re = /^\d+$/
      var re = /^([0-9]+(\.[0-9]+)?)$/
      if (! re.test(booking.refunded_perc) || ! re.test(booking.refunded_fee)) {
        return `<span class="italic text-red-500">solo numeri e punto per i decimali</span>`
      }

      let refunded = booking.ticket - ((booking.ticket * booking.refunded_perc) / 100)
      dbg(refunded)

      if (booking.refunded_tax) {
        refunded += booking.tax
      }
      if (booking.refunded_landingFee) {
        refunded += booking.landingFee
      }
      if (booking.refunded_fee) {
        refunded -= booking.refunded_fee
      }

      return parseFloat(refunded).toFixed(2)
    },
    modifyCancelled(booking, status) {
      if (status) {
        this.bookingsCancelled.push(booking)
      }
      else {
        this.bookingsCancelled = this.bookingsCancelled.filter(b => b.id != booking.id)
      }
    },
    travelUpdated({ travel }) {
      dbg(travel, 'TRAVEL')
      let { id } = travel
      let travelSelected = this.travels.filter(t => t.id == id)[0]
      dbg(travelSelected, 'TRAVEL-SEL')
      travelSelected.description = travel.description
      travelSelected.flightCode = travel.flightCode
      travelSelected.flightDate = travel.flightDate
      travelSelected.origin = travel.origin
      travelSelected.destination = travel.destination
      travelSelected.flightId = travel.flightId
      travelSelected.flightId = travel.flightId
      travelSelected.takeOffDate = travel.takeOffDate
      travelSelected.takeOffTime = travel.takeOffTime
      travelSelected.originLabel = travel.originLabel
      travelSelected.destinationLabel = travel.destinationLabel
      travelSelected.seasonFare = travel.seasonFare
      travelSelected.paxes = travel.paxes
    },
    supContent(booking) {
      if (booking.dw && booking.dw_flight == 'd') {
        return `R: ${booking.ticketNrInv}`
      }
      else if (booking.dw && booking.dw_flight == 'c') {
        return `A: ${booking.ticketNrInv}`
      }
    },
    visibleTravels(booking) {
      return this.travels
    },
    travelVisible(travel, booking) {
      return ! this.bookings.filter(b => b.id != booking.id && b.idPax == booking.idPax && b.movedOn == travel.id).length
    },
    travelBookings(id) {
      return this.bookings.filter(b => b.movedOn == id)
    },
    addTravel() {
      if (this.travels.length == this.bookings.length) {
        return
      }
      var maxVal = 0
      if (this.travels.length) {
        maxVal = Math.max(...this.travels.map(t => t.id))
      }

      this.travels.push({
        id: maxVal + 1,
        flightDate: null,
        origin: null,
        destination: null,
        flightId: null,
        paxes: []
      })
    },
    remTravel(travel) {
      if (this.travels.length == 1) { return }
      this.travels = this.travels.filter(f => f.id != travel.id)
    },
    showEditMode(booking) {
      window.scrollTo(0, 0)
      this.editMode = true
      this.cancelMode = false
      this.editBooking.code = booking.bookingCode.substr(0, 6)
      this.bookings = []
      this.loadBookingList()
    },
    showCancelMode(booking) {
      return true
      window.scrollTo(0, 0)
      this.editMode = false
      this.cancelMode = true
      this.editBooking.code = booking.bookingCode.substr(0, 6)
      this.bookings = []
      this.loadBookingList()
    },
    backToSearch() {
      this.editMode = false
      this.cancelMode = false
      this.bookingsFinalStep = []
      this.bookingsCancelled = []
      this.resetTravels()
      this.search(true)
    },
    changePage(n) {
      this.pagination.current_page = n
      this.search()
    },
    bookingRowStyle(booking, index) {
      let { finalStep } = this
      var objStyle = {}

      if (booking.cancelled) {
        objStyle.backgroundColor = '#f7e4e8 !important'
        return objStyle
      }

      if (finalStep) {
        var stop = 0
        if (booking.id < 0) {
          stop = 1
          objStyle.backgroundColor = '#f0fff4'
          objStyle.borderBottom = '2px solid lightgray'
        }
        else if (booking.movedOn > 0) {
          stop = 1
          objStyle.backgroundColor = '#fff'
        }
        else if (booking.id > 0 && ! booking.movedOn) {
          stop = 1
          objStyle.color = '#9e9e9e'
          objStyle.backgroundColor = '#f7fafc'
          objStyle.fontSize = '0.7rem'
        }

        if (stop) {
          return objStyle
        }
      }

      if (booking.cancelled) {
        objStyle.color = 'red'
      }

      if (index % 2 == 0) {
        objStyle.background = '#ebf8ff59'
      }
      else if (index % 2 != 0) {
        objStyle.background = '#fff'
      }
      return objStyle
    },
    validate() {
      let { filters } = this
      if (
        ! filters.flightDate &&
        ! filters.bookingCode &&
        ! filters.ticketNr &&
        ! filters.lastname &&
        ! filters.firstname
      ) {
        notifyW('Inserire almeno un filtro di ricerca!')
        return false
      }
      else {
        return true
      }
    },
    async search(resetPaginationPage) {
      // if (! this.validate()) return

      this.loading = true
      let { flightDate , bookingCode, ticketNr, lastname, firstname, includeCancelled } = this.filters
      let page = 1
      if (this.pagination) {
        if (resetPaginationPage) {
          this.pagination.current_page = 1
        }
        page = this.pagination.current_page
      }

      let params = { flightDate, bookingCode, ticketNr, lastname, firstname, includeCancelled, paginate: { current_page: page, per_page: 25 } }
      // console.log(params)

      let { data, pagination } = await api.get('api.my-tickets', { params })
      this.bookings = data.bookings
      this.bookingsPending = data.bookingsPending || []
      this.pagination = data.pagination
      this.loading = false
    },
    async loadBookingList() {
      this.loading = true
      let { code: bookingCode } = this.editBooking
      let { data } = await api.get(route('api.manage-bookings.show', { bookingCode }))
      this.bookings = data.bookings.map(b => {
        b.movedOn = 0
        b.cancelled = false
        b.refunded_tax = true
        b.refunded_landingFee = true
        b.refunded_perc = 100
        b.refunded_fee = 0
        return b
      })
      this.loading = false
      window.scrollTo(0,0)
      document.getElementById('scrollable-container').scrollTo(0,0)
    },
    async confirmBooking(type) {

      if (type == 0 && this.travels.filter(t => ! t.flightId).length) {
        notifyW('Completare la selezione di tutti i voli richiesti (o rimuovere i vuoti)')
        return
      }

      try {

        this.loading = true

        if (type == -1) {
          function validateEmail(email) {
            var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(email).toLowerCase());
          }
          if (! validateEmail(this.form.email_payment_link)) {
            notifyW('email destinatario non valida')
            this.loading = false
            return
          }
        }

        let { code: bookingCode } = this.editBooking

        let checkPax = []
        let stopExec = false

        let { travels } = this
        travels = travels.map(t => {
          t.paxes.forEach(p => {
            var checkPaxSingle = checkPax.filter(cp => cp.idT == t.flightId && cp.idP == p.id)
            if (checkPaxSingle.length == 0) {
              checkPax.push({ idT: t.flightId, idP: p.id })
            }
            else {
              stopExec = true
            }
          })
          return {
            origin: t.origin,
            destination: t.destination,
            flightId: t.flightId,
            paxes: t.paxes
          }
        })
        if (stopExec) {
          notifyW('passeggero inserito più volte sullo stesso volo')
          this.loading = false
          return
        }

        let { adminFeeCheck } = this.costs

        if (travels.filter(t => ! t.flightId).length) {
          this.loading = false
          notifyW('Voli non selezionati')
          throw 'Missing flightId selection'
          return
        }

        // var form = {
        //   departure: {
        //     originId: departure.origin,
        //     destinationId: departure.destination,
        //     flightId: departure.flight,
        //     paxes: departurePaxes
        //   },
        //   comeback: {
        //     originId: comeback.origin,
        //     destinationId: comeback.destination,
        //     flightId: comeback.flight,
        //     paxes: comebackPaxes
        //   },
        // }
        let contacts = {
          email_payment_link: this.form.email_payment_link
        }

        let { data } = await api.put(route('api.bookings.update', { booking: bookingCode }), { type, travels, contacts, adminFeeCheck })
        if (data?.status == 'error') {
          this.loading = false
          notifyE(data.messages.it + '<br>' + data.messages.en)
          return
        }

        // costs elaboration only
        if (type == 0) {
          this.bookingsFinalStep = []
            this.bookings.forEach(b => {
            if (! b.movedOn) {
              return false
            }

            this.bookingsFinalStep.push(b)

            let row = data.rows.filter(r => r.id == b.id)[0]

            let newRow = clone(b)
            let flightSlot = this.travels.filter(t => t.id == b.movedOn)[0]
            newRow.id = newRow.id * -1
            newRow.takeOffDate = flightSlot.takeOffDate
            newRow.takeOffTime = flightSlot.takeOffTime
            newRow.origin = flightSlot.originLabel
            newRow.destination = flightSlot.destinationLabel
            newRow.flightCode = flightSlot.flightCode
            newRow.description = flightSlot.description
            newRow.flightId = flightSlot.flightId
            newRow.ticket = row.ticket
            newRow.tax = row.tax
            newRow.landingFee = row.landingFee
            newRow.movedOn = 0
            this.bookingsFinalStep.push(newRow)
          })

          this.bookings.forEach(b => {
            if (b.movedOn) {
              return false
            }
            this.bookingsFinalStep.push(b)
          })

          this.costs = { ...this.costs, ...data.costs }
          window.scrollTo(0, 0)
          this.loading = false
        }

        // show bancasella link
        // else if (type == -1) {
        //     this.bankLink = data.bank_link
        //     this.loading = false
        //     return
        // }

        // redirect to paypal
        else if (type == -1 || type == 1) {
          console.log(data)
          // location.href = data.paypal_link
          if (data?.paypal) {
          this.loading = false
          this.bookingCode = data.bookingCode
          this.amount = data.amount
          this.bookingRequestUuid = data.bookingRequestUuid
          this.showPayPal = true
          }
          return
        }

        // // redirect to final-page (contanti / alidaunia)
        // else if (type == 2 || type == 3) {
        //     location.href = location.origin + '/booking-status/' + btoa(data.bookingCode)
        //     return
        // }
      }
      catch(error) {
        callErrors(error)
        this.loading = false
      }
    },
    resetTravels() {
      this.travels = []
      this.travels.push({
        id: 1,
        description: null,
        flightCode: null,
        flightDate: null,
        origin: null,
        destination: null,
        flightId: null,
        takeOffDate: null,
        takeOffTime: null,
        originLabel: null,
        destinationLabel: null,
        seasonFare: null,
        paxes: []
      })
    },
    resetFilters() {
      this.filters = {
        flightDate: null,
        bookingCode: null,
        ticketNr: null,
        lastname: null,
        firstname: null,
        includeCancelled: false
      }
      this.form.email_payment_link = null
      localStorage.removeItem('pageParams')
    },
    isPending(bookingCode) {
      return this.bookingsPending.includes(bookingCode.substr(0, 6))
    },
    updatedMovedOn(booking) {
      this.bookingsCustomList.filter(b => b.ticketNr == booking.ticketNr).forEach(b => b.movedOn = booking.movedOn)
    }
  },
  created() {
    let pageParams = localStorage.getItem('pageParams')
    if (pageParams) {
      pageParams = JSON.parse(pageParams)
      this.filters.flightDate = pageParams.flightDate
      this.filters.bookingCode = pageParams.bookingCode
      this.filters.ticketNr = pageParams.ticketNr
      this.filters.lastname = pageParams.lastname
      this.filters.firstname = pageParams.firstname

      this.pagination = {
        current_page: pageParams.page
      }

      this.search()

      localStorage.removeItem('pageParams')
    }

    this.locales = Locales
    this.resetTravels()

    this.search()
  }
}
</script>
