<template>
	<section class="w-full max-w-5xl mx-auto bg-white min-h-screen">
		<div class="w-full flex flex-col">
			<div class="flex flex-col">
        <div class="flex">
          <div class="w-full flex my-2 h-8">
            <span class="flex-1 self-center border-t border-gray-300"></span>
            <span class="text-xl font-semibold text-blue-900 text-center px-3">
              {{ locale.BookingStatus }}
            </span>
            <span class="flex-1 self-center border-t border-gray-300"></span>
          </div>
        </div>

				<div class="relative flex flex-col m-3 border rounded">
					<vue-loading :loading="loading" />

					<div class="flex flex-col p-3">
						<!-- STEPS -->
						<div v-if="response.status && ! bookingModified" class="flex flex-wrap my-3 md:mt-2">
							<div class="step-block flex-1 w-full h-10 text-white mr-1 flex justify-center md:justify-start md:px-2 items-center" :class="{ 'bg-blue-500': step == 1, 'bg-green-500': step > 1 }">
								<span class="text-5xl font-semibold" style="text-shadow:0 -1px 0 #408c1f; position:relative; top:-5px; margin-right:3px">1</span>
								<span class="hidden md:block" v-text="locale.step1"></span>
							</div>
							<div class="step-block flex-1 w-full h-10 text-white mr-1 flex justify-center md:justify-start md:px-2 items-center" :class="{ 'bg-blue-200': step < 2, 'bg-blue-500': step == 2, 'bg-green-500': step > 2 }">
								<span class="text-5xl font-semibold" style="text-shadow:0 -1px 0 #408c1f; position:relative; top:-5px; margin-right:3px">2</span>
								<span class="hidden md:block" v-text="locale.step2"></span>
							</div>
							<div class="step-block flex-1 w-full h-10 text-white mr-1 flex justify-center md:justify-start md:px-2 items-center" :class="{ 'bg-blue-200': step < 3, 'bg-blue-500': step == 3, 'bg-green-500': step > 3 }">
								<span class="text-5xl font-semibold" style="text-shadow:0 -1px 0 #408c1f; position:relative; top:-5px; margin-right:3px">3</span>
								<span class="hidden md:block" v-text="locale.step3"></span>
							</div>
							<div class="step-block flex-1 w-full h-10 text-white mr-1 flex justify-center md:justify-start md:px-2 items-center" :class="{ 'bg-blue-200': step < 4, 'bg-blue-500': step == 4, 'bg-green-500': step > 4 }">
								<span class="text-5xl font-semibold" style="text-shadow:0 -1px 0 #408c1f; position:relative; top:-5px; margin-right:3px">4</span>
								<span class="hidden md:block" v-text="locale.step4"></span>
							</div>
							<div class="step-block flex-1 w-full h-10 text-white flex justify-center md:justify-start md:px-2 items-center" :class="{ 'bg-blue-200': step < 5, 'bg-blue-500': step == 5, 'bg-green-500': step > 5 }">
								<span class="text-5xl font-semibold" style="text-shadow:0 -1px 0 #408c1f; position:relative; top:-5px; margin-right:3px">5</span>
								<span class="hidden md:block" v-text="locale.step5"></span>
							</div>
						</div>

						<!-- STEP 5 / begin -->
						<div v-if="step == 5" :class="{ 'h-20': response.status == null }">

							<!-- booking ok -->
							<div v-if="response.status == true" class="flex flex-col">
								<div class="w-full text-base text-center py-5 bg-green-100 text-green-700 border border-green-500">
									<fa class="s-check text-5xl" />
									<p class="mt-5">La transazione ha avuto esito positivo.</p>
									<p>Il codice di prenotazione è <span class="font-bold" v-html="booking.code" />.</p>
									<p>I biglietti sono stati spediti all'indirizzo email <span class="font-bold" v-html="booking.email" />.</p>
									<p class="text-sm">
										In base alle tue impostazioni o al tuo gestore di posta elettronica, il messaggio potrebbe essere riconosciuto come SPAM,
										<br>
										pertanto ti consigliamo di controllare anche le cartelle "spam" o "junk".
									</p>
								</div>

								<div v-if="lang == 'it'" class="flex flex-col text-blue-600 leading-normal border border-blue-500 bg-blue-100 p-4 mb-4 mt-3">
									<div class="flex flex-col mb-4">
										<div class="flex font-bold text-base">
											Si ricorda che...
										</div>
										<div class="flex">
											<ul class="w-full pl-4">
												<li>• è possibile presentarsi al check-in con la sola copia elettronica del biglietto.</li>
												<li>• è possibile portare un solo bagaglio per persona, di dimesioni max cm. 60x40x20.</li>
												<li>• animali di piccola taglia possono essere portati in gabbiette di dimensioni max cm. 43x33x34.</li>
												<li class="mt-3">
													• in caso di minore non accompagnato, <span class="underline">è obbligatorio compilare e consegnare</span> al check-in la
													<div class="mt-1 w-full flex justify-center">
														<a href="/pdf/forms/minor.pdf" target="_blank" class="block rounded bg-white border border-orange-500 text-xs text-orange-500 p-1">DICHIARAZIONE PER IL TRASPORTO DI MINORI NON ACCOMPAGNATI</a>
													</div>
												</li>
												<li class="mt-3">
													• le gestanti entro l'ottavo mese di gravidanza <span class="underline">sono tenute a compilare e consegnare</span> al check-in la
													<div class="mt-1 w-full flex justify-center">
														<a href="/pdf/forms/pregnant.pdf" target="_blank" class="block rounded bg-white border border-pink-500 text-xs text-pink-500 p-1">DICHIARAZIONE LIBERATORIA PER TRASPORTO GESTANTI</a>
													</div>
												</li>
												<li class="mt-3">
													• in caso di traporto armi/munizioni, <span class="underline">è obbligatorio compilare e consegnare</span> al check-in la
													<div class="mt-1 w-full flex justify-center">
														<a href="/pdf/forms/weapons.pdf" target="_blank" class="block rounded bg-white border border-blue-500 text-xs text-blue-500 p-1">DICHIARAZIONE PER IL TRASPORTO DI ARMI E MUNIZIONI</a>
													</div>
												</li>
												<li class="mt-2">• l'auto può restare all'interno dell'eliporto Alidaunia, sulle aree di sosta antistanti il terminal, appositamente riservate ai passeggeri ed individuate dalle strisce di colore blu. Il parcheggio è tariffato 0,20 €/ora e la disponibilità di posti è limitata.</li>
											</ul>
										</div>
									</div>
									<div class="flex font-bold text-xl">
										ATTENZIONE
									</div>
									<div class="flex">
										<ul class="pl-4">
											<li class="mt-1">Il check-in ed il cancello d'ingresso aprono <span class="bg-yellow-500 font-bold rounded px-1">30 minuti prima</span> dell'orario di partenza.</li>
											<li class="mt-1">Il check-in ed il cancello d'ingresso chiudono tassativamente <span class="bg-yellow-500 font-bold rounded px-1">10 minuti prima</span> dell'orario di partenza.</li>
										</ul>
									</div>
								</div>

								<div v-if="true || ! bookingModified" class="w-full mt-5">
									<div class="px-2 py-2 bg-green-200 text-green-800 rounded-t">
										Puoi inviare copia singola del biglietto anche a indirizzi email differenti.
									</div>
									<div class="my-2 w-full overflow-x-scroll md:overflow-x-hidden">
										<table class="w-full border">
											<thead>
												<tr class="text-left">
													<th class="pl-2">passeggero</th>
													<th class="whitespace-no-wrap">data nascita</th>
													<th>biglietto</th>
													<th>email</th>
													<th class="px-2">e-ticket</th>
												</tr>
											</thead>
											<tbody>
												<tr class="text-left" v-for="(pax, index) in booking.paxes" :key="pax.id" :class="{ 'bg-gray-200': index % 2 == 0, 'bg-gray-100': index % 2 != 0 }">
													<td class="px-2 whitespace-no-wrap" v-html="pax.fullname" />
													<td class="px-2 whitespace-no-wrap" v-html="pax.birthdate" />
													<td class="px-2 whitespace-no-wrap" v-html="pax.ticket" />
													<td class="pr-5 py-1">
														<input type="text" class="w-64 border px-2 py-1" v-model="pax.email" />
													</td>
													<td class="whitespace-no-wrap px-2">
														<button @click="submitTicket(pax)" type="button" class="bg-green-500 text-white px-2 rounded flex justify-between">
															<fa class="r-envelope mr-2" />
															invia
														</button>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
									<div class="w-full md:hidden italic text-xs flex items-center">
										<fa class="s-info-circle text-blue-700 mr-1" />
										la lista passeggeri scorre in orizzontale
									</div>
								</div>

								<div v-if="bookingModified" class="w-full mt-10">
									<div class="flex justify-end">
										<a :href="backToList()" class="bg-blue-500 text-white p-2 rounded">
											torna all'elenco biglietti
										</a>									
									</div>
								</div>
							</div>

							<!-- booking ko -->
							<div v-if="response.status == false" class="flex flex-col">
								<div class="w-full text-base text-center py-5 bg-red-100 text-red-700 border border-red-500">
									<fa class="s-times text-5xl" />
									<p class="mt-5" v-text="message" />
									<p class="mt-5" v-text="`error code # ${response.error_code}`" />
								</div>
								<div class="mt-8 w-full flex flex-col items-center">
									<div>
										{{ locale.BookingResponseFinished }}
										<router-link class="text-blue-700 font-semibold underline" :to="{ name: 'booking' }">
											{{ locale.BookingResponseRestart }}
										</router-link>
									</div>
									<div class="mt-5">
										{{ locale.BookingResponseContactSupport }}
									</div>
								</div>
							</div>
						</div>
						<!-- STEP 5 / end -->
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import Locales from '../../locales.json'

export default {
	name: 'BookingStatus',
	data:() => ({
		loading: true,
		step: 5,
		response: {
			status: null,
			message: null,
			error_code: null
		},
		booking: {
			code: null,
			email: null,
			paxes: []
		}
	}),
	computed: {
		code() {
			return this.$route.params.code
		},
		status() {
			return this.$route.params.status
		},
		lang() {
			return this.$root.$children[0].lang
		},
		locale() {
			return this.locales[this.lang]
		},
		message() {
			let { lang, response: { message } } = this
			if (lang == 'it') {
				return 'Siamo spiacenti, la banca ha risposto: "' + message + '". Non è stato emesso alcun biglietto.'
			}
			else if (lang == 'en') {
				return 'We apologize, bank response is: "' + message + '". No ticket has been issued.'
			}
		},
		bookingModified() {
			return this.booking.code.length > 6
		}
	},
	methods: {
		checkRoles(roles) {
      return this.$parent.roles.filter(x => roles.includes(x)).length
		},		
		backToList() {
			if (this.checkRoles(['Admin', 'Prenotazioni', 'PrenotaFull'])) {
				return '/admin/gestione-prenotazioni'
			}
			else {
				return '/my-tickets'
			}
		},
		vueTippyPaxCell(uuid)	{
			return {
				allowHTML: true,
				content: '#pax-costs-' + uuid,
				arrow: true
			}
		},			
		async fetchBooking() {
			try {
				this.loading = true
				let { lang, code } = this

				code = atob(code)
				
				let { data } = await api.get('api.booking-status', { code })
				dbg(data, '► DATA')

				let { booking, status, bank } = data
				
				this.booking = booking
				this.response.status = status

				this.response.message = bank.message
				this.response.error_code = bank.error_code

				this.loading = false
			}
			catch (error) {
				this.loading = false
				callErrors(error)
			}
		},
		async submitTicket(pax) {
			function validateEmail(email) {
				var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
				return re.test(String(email).toLowerCase());
			}

			try {
				if (! validateEmail(pax.email)) {
					notifyE('email non valida')
					return
				}

				this.loading = true
				
				let { email, tickets } = pax
				let { data } = await api.post('api.submit-ticket', { email, tickets })
				let message = (this.lang == 'it' ? 'biglietto inviato' : 'ticket sent')
				if (data && ! data.hasOwnProperty('errors')) {
					notifyS(message)
				}
				this.loading = false
			}
			catch (error) {
				callErrors(error)
				let message = (this.lang == 'it' ? 'errore server. riprovare' : 'error while sending ticket. please retry')
				notifyE(message)
				this.loading = false
			}
		}
	},
	created() {
		this.locales = Locales
		
		this.fetchBooking()

		if (this.bloccate) {
			this.prenBloccate = JSON.parse(this.bloccate)
		}

		if (window.location.href.includes('admin/posti-bloccati')) {
			this.step = 0
		}
	}
}
</script>

<style>
