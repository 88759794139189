<template>
	<section class="w-full max-w-5xl mx-auto">
		<div class="relative w-full flex flex-col bg-white">
			<div class="flex flex-col">
        <div class="flex">
          <div class="w-full flex my-2 h-8">
            <span class="flex-1 self-center border-t border-gray-300"></span>
            <span class="text-xl font-semibold text-blue-900 text-center px-3">
              {{ locale.Fares }}
            </span>
            <span class="flex-1 self-center border-t border-gray-300"></span>
          </div>
        </div>
        <div class="flex flex-col px-6">

          <!-- Nav tabs -->
          <ul class="flex border-b border-transparent" style="z-index:1">
            <li @click="showTab('low')" class="cursor-pointer mr-1" :class="{ '-mb-px': activeTab == 'low' }">
              <span class="text-xs md:text-lg inline-block py-2 px-4 font-semibold" :class="{ 'text-gray-400 hover:text-blue-800': activeTab != 'low', 'text-blue-800 rounded-t border-l border-t border-r border-gray-300 bg-white': activeTab == 'low' }">
                {{ locale.LowFare }}
              </span>
            </li>
            <li @click="showTab('high')" class="cursor-pointer mr-1" :class="{ '-mb-px': activeTab == 'high' }">
              <span class="text-xs md:text-lg inline-block py-2 px-4 font-semibold" :class="{ 'text-gray-400 hover:text-blue-800': activeTab != 'high', 'text-blue-800 rounded-t border-l border-t border-r border-gray-300 bg-white': activeTab == 'high' }">
                {{ locale.HighFare }}
              </span>
            </li>
            <li @click="showTab('res')" class="cursor-pointer mr-1" :class="{ '-mb-px': activeTab == 'res' }">
              <span class="text-xs md:text-lg inline-block py-2 px-4 font-semibold" :class="{ 'text-gray-400 hover:text-blue-800': activeTab != 'res', 'text-blue-800 rounded-t border-l border-t border-r border-gray-300 bg-white': activeTab == 'res' }">
                {{ locale.ResFare }}
              </span>
            </li>
          </ul>

          <div style="height: 10px; box-shadow: 0 -5px 3px #e2e8ee">
          </div>

          <div class="tabs-container">

            <!-- BASSA STAGIONE -->
            <div v-if="activeTab == 'low'" class="flex flex-col">

              <!-- PERIODO -->
              <div class="w-full p-2 bg-blue-100 mt-2 border rounded">
                <span class="px-2 text-blue-700" style="background-color:yellow">
                  <i class="fa fa-calendar mr-1" />
                  {{ locale.LowFarePeriod }}
                </span>
              </div>

              <!-- CATEGORIE -->
              <div class="w-full flex flex-col md:flex-row justify-between mt-2">

                <!-- ADULTO -->
                <div class="w-full md:w-1/3 flex flex-col mb-4 md:mb-0 md:mr-2 border shadow-lg md:border md:border-gray-300 rounded">
                  <div class="p-1">
                    <img src="/img/fares/adult.jpg" alt="Adulto">
                  </div>
                  <div class="flex border-t border-b">
                    <div class="w-2/3 pl-2 py-1 flex justify-start items-center font-semibold leading-none text-lg md:text-sm">
                      {{ locale.Adult }}
                    </div>
                    <div class="w-1/3 py-2 border-l flex flex-col justify-center items-center text-base bg-blue-100 text-blue-500">
                      <span class="font-semibold leading-none">€ 31,75 *</span>
                      <span style="font-size:10px" class="text-gray-500 leading-none">{{ locale.oneWay }}</span>
                    </div>
                  </div>
                  <div class="flex">
                    <div class="w-full pl-2 py-1 flex flex-col justify-start items-start">
                      <p class="text-xs flex flex-col p-1 text-blue-500 leading-tight">
                        <span class="font-bold mb-1">{{ locale.additionalFees }}</span>
                        <span>€ 13,91 {{ locale.departuresFrom }}</span>
                        <span>€ 7,41 {{ locale.departuresFromOtherHeliports }}</span>
                        <span>€ 2,50 {{ locale.landingFee }}</span>
                      </p>
                      <div class="row">
                        <div class="col-xs-12" style="font-size:11px">
                          <ul class="list-unstyled">
                            <li><strong>{{ locale.CostDetail }}</strong></li>
                            <li><i class="text-blue-500 fa fa-caret-square-o-up" /> € 3,59 {{ locale.boarding }}</li>
                            <li><i class="text-blue-500 fa fa-star" /> € 6,50 {{ locale.additionalServices }} **</li>
                            <li><i class="text-blue-500 fa fa-rss" /> € 1,81 {{ locale.security }}</li>
                            <li><i class="text-blue-500 fa fa-suitcase" /> € 1,30 {{ locale.luggageCheckFix }} ***</li>
                            <li><i class="text-blue-500 fa fa-suitcase" /> € 0,37 {{ locale.luggageCheckVar }} ***</li>
                            <li><i class="text-blue-500 fa fa-wheelchair" /> € 0,34 {{ locale.paxReducedMobility }}</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- BAMBINO -->
                <div class="w-full md:w-1/3 flex flex-col mb-4 md:mb-0 md:mx-2 border shadow-lg md:border md:border-gray-300 rounded">
                  <div class="p-1">
                    <img src="/img/fares/child.jpg" alt="Bambino">
                  </div>
                  <div class="flex border-t border-b">
                    <div class="w-2/3 pl-2 py-1 flex justify-start items-center font-semibold leading-none text-lg md:text-sm">
                      {{ locale.Child }}
                      <span class="ml-1 text-gray-500 font-normal" style="font-size:10px">(2-11 {{ locale.years }})</span>
                    </div>
                    <div class="w-1/3 py-2 border-l flex flex-col justify-center items-center text-base bg-blue-100 text-blue-500">
                      <span class="font-semibold leading-none">€ 15,87 *</span>
                      <span style="font-size:10px" class="text-gray-500 leading-none">{{ locale.oneWay }}</span>
                    </div>
                  </div>
                  <div class="flex">
                    <div class="w-full pl-2 py-1 flex flex-col justify-start items-start">
                      <p class="text-xs flex flex-col p-1 text-blue-500 leading-tight">
                        <span class="font-bold mb-1">{{ locale.additionalFees }}</span>
                        <span>€ 12,12 {{ locale.departuresFrom }}</span>
                        <span>€ 5,62 {{ locale.departuresFromOtherHeliports }}</span>
                        <span>€ 2,50 {{ locale.landingFee }} <span style="font-size:9px">({{ locale.landingFeeOverTen }})</span></span>
                      </p>
                      <div class="row">
                        <div class="col-xs-12" style="font-size:11px">
                          <ul class="list-unstyled">
                            <li><strong>{{ locale.CostDetail }}</strong></li>
                            <li><i class="text-blue-500 fa fa-caret-square-o-up" /> € 1,80 {{ locale.boarding }}</li>
                            <li><i class="text-blue-500 fa fa-star" /> € 6,50 {{ locale.additionalServices }} **</li>
                            <li><i class="text-blue-500 fa fa-rss" /> € 1,81 {{ locale.security }}</li>
                            <li><i class="text-blue-500 fa fa-suitcase" /> € 1,30 {{ locale.luggageCheckFix }} ***</li>
                            <li><i class="text-blue-500 fa fa-suitcase" /> € 0,37 {{ locale.luggageCheckVar }} ***</li>
                            <li><i class="text-blue-500 fa fa-wheelchair" /> € 0,34 {{ locale.paxReducedMobility }}</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- NEONATO + ANIMALE -->
                <div class="w-full md:w-1/3 flex flex-col justify-between mb-2 md:mb-0 md:ml-2">
                  <div class="w-full flex flex-col border shadow-lg md:border md:border-gray-300 rounded">
                    <div class="p-1">
                      <img src="/img/fares/infant.jpg" alt="Neonato">
                    </div>
                    <div class="flex border-t border-b">
                      <div class="w-2/3 pl-2 py-1 flex justify-start items-center font-semibold leading-none text-lg md:text-sm">
                        {{ locale.Infant }}
                        <span class="ml-1 text-gray-500 font-normal" style="font-size:10px">(0-23 {{ locale.months }})</span>
                      </div>
                      <div class="w-1/3 py-2 border-l flex flex-col justify-center items-center text-base bg-blue-100 text-blue-500">
                        <span class="font-semibold leading-none">€ 3,17</span>
                        <span style="font-size:10px" class="text-gray-500 leading-none">{{ locale.oneWay }}</span>
                      </div>
                    </div>
                    <div class="flex">
                      <div class="w-full pl-2 py-1 flex flex-col justify-start items-start">
                        <p class="text-xs flex flex-col p-1 text-blue-500 leading-tight">
                          <span class="font-bold">{{ locale.noAdditionalFees }}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="w-full flex flex-col border shadow-lg md:border md:border-gray-300 rounded">
                    <div class="flex border-t border-b">
                      <div class="w-2/3 pl-2 py-1 flex justify-start items-center font-semibold leading-none text-lg md:text-sm">
                        Animale domestico
                      </div>
                      <div class="w-1/3 py-2 border-l flex flex-col justify-center items-center text-base bg-blue-100 text-blue-500">
                        <span class="font-semibold leading-none">€ 15,87</span>
                        <span style="font-size:10px" class="text-gray-500 leading-none">{{ locale.oneWay }}</span>
                      </div>
                    </div>
                    <div class="flex">
                      <div class="w-full pl-2 py-1 flex flex-col justify-start items-start">
                        <p class="text-xs flex flex-col p-1 text-blue-500 leading-tight">
                          <span class="font-bold">{{ locale.noAdditionalFees }}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>

            <!-- ALTA STAGIONE -->
            <div v-if="activeTab == 'high'" class="flex flex-col">

              <!-- PERIODO -->
              <div class="w-full p-2 bg-blue-100 mt-2 border rounded">
                <span class="px-2 text-blue-700" style="background-color:yellow">
                  <i class="fa fa-calendar mr-1" />
                  {{ locale.HighFarePeriod }}
                </span>
              </div>

              <!-- CATEGORIE -->
              <div class="w-full flex flex-col md:flex-row justify-between mt-2">

                <!-- ADULTO -->
                <div class="w-full md:w-1/3 flex flex-col mb-4 md:mb-0 md:mr-2 border shadow-lg md:border md:border-gray-300 rounded">
                  <div class="p-1">
                    <img src="/img/fares/adult.jpg" alt="Adulto">
                  </div>
                  <div class="flex border-t border-b">
                    <div class="w-2/3 pl-2 py-1 flex justify-start items-center font-semibold leading-none text-lg md:text-sm">
                      Adulto
                    </div>
                    <div class="w-1/3 py-2 border-l flex flex-col justify-center items-center text-base bg-blue-100 text-blue-500">
                      <span class="font-semibold leading-none">€ 63,50 *</span>
                      <span style="font-size:10px" class="text-gray-500 leading-none">{{ locale.oneWay }}</span>
                    </div>
                  </div>
                  <div class="flex">
                    <div class="w-full pl-2 py-1 flex flex-col justify-start items-start">
                      <p class="text-xs flex flex-col p-1 text-blue-500 leading-tight">
                        <span class="font-bold mb-1">{{ locale.additionalFees }}</span>
                        <span>€ 13,91 {{ locale.departuresFrom }}</span>
                        <span>€ 7,41 {{ locale.departuresFromOtherHeliports }}</span>
                        <span>€ 2,50 {{ locale.landingFee }}</span>
                      </p>
                      <div class="row">
                        <div class="col-xs-12" style="font-size:11px">
                          <ul class="list-unstyled">
                            <li><strong>{{ locale.CostDetail }}</strong></li>
                            <li><i class="text-blue-500 fa fa-caret-square-o-up" /> € 3,59 {{ locale.boarding }}</li>
                            <li><i class="text-blue-500 fa fa-star" /> € 6,50 {{ locale.additionalServices }} **</li>
                            <li><i class="text-blue-500 fa fa-rss" /> € 1,81 {{ locale.security }}</li>
                            <li><i class="text-blue-500 fa fa-suitcase" /> € 1,30 {{ locale.luggageCheckFix }} ***</li>
                            <li><i class="text-blue-500 fa fa-suitcase" /> € 0,37 {{ locale.luggageCheckVar }} ***</li>
                            <li><i class="text-blue-500 fa fa-wheelchair" /> € 0,34 {{ locale.paxReducedMobility }}</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- BAMBINO -->
                <div class="w-full md:w-1/3 flex flex-col mb-4 md:mb-0 md:mx-2 border shadow-lg md:border md:border-gray-300 rounded">
                  <div class="p-1">
                    <img src="/img/fares/child.jpg" alt="Bambino">
                  </div>
                  <div class="flex border-t border-b">
                    <div class="w-2/3 pl-2 py-1 flex justify-start items-center font-semibold leading-none text-lg md:text-sm">
                      Bambino
                      <span class="ml-1 text-gray-500 font-normal" style="font-size:10px">(2-11 anni)</span>
                    </div>
                    <div class="w-1/3 py-2 border-l flex flex-col justify-center items-center text-base bg-blue-100 text-blue-500">
                      <span class="font-semibold leading-none">€ 31,75 *</span>
                      <span style="font-size:10px" class="text-gray-500 leading-none">{{ locale.oneWay }}</span>
                    </div>
                  </div>
                  <div class="flex">
                    <div class="w-full pl-2 py-1 flex flex-col justify-start items-start">
                      <p class="text-xs flex flex-col p-1 text-blue-500 leading-tight">
                        <span class="font-bold mb-1">{{ locale.additionalFees }}</span>
                        <span>€ 12,12 {{ locale.departuresFrom }}</span>
                        <span>€ 5,62 {{ locale.departuresFromOtherHeliports }}</span>
                        <span>€ 2,50 {{ locale.landingFee }} <span style="font-size:9px">({{ locale.landingFeeOverTen }})</span></span>
                      </p>
                      <div class="row">
                        <div class="col-xs-12" style="font-size:11px">
                          <ul class="list-unstyled">
                            <li><strong>{{ locale.CostDetail }}</strong></li>
                            <li><i class="text-blue-500 fa fa-caret-square-o-up" /> € 1,80 {{ locale.boarding }}</li>
                            <li><i class="text-blue-500 fa fa-star" /> € 6,50 {{ locale.additionalServices }} **</li>
                            <li><i class="text-blue-500 fa fa-rss" /> € 1,81 {{ locale.security }}</li>
                            <li><i class="text-blue-500 fa fa-suitcase" /> € 1,30 {{ locale.luggageCheckFix }} ***</li>
                            <li><i class="text-blue-500 fa fa-suitcase" /> € 0,37 {{ locale.luggageCheckVar }} ***</li>
                            <li><i class="text-blue-500 fa fa-wheelchair" /> € 0,34 {{ locale.paxReducedMobility }}</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- NEONATO + ANIMALE -->
                <div class="w-full md:w-1/3 flex flex-col justify-between mb-2 md:mb-0 md:ml-2">
                  <div class="w-full flex flex-col border shadow-lg md:border md:border-gray-300 rounded">
                    <div class="p-1">
                      <img src="/img/fares/infant.jpg" alt="Neonato">
                    </div>
                    <div class="flex border-t border-b">
                      <div class="w-2/3 pl-2 py-1 flex justify-start items-center font-semibold leading-none text-lg md:text-sm">
                        {{ locale.Infant }}
                        <span class="ml-1 text-gray-500 font-normal" style="font-size:10px">(0-23 {{ locale.months }})</span>
                      </div>
                      <div class="w-1/3 py-2 border-l flex flex-col justify-center items-center text-base bg-blue-100 text-blue-500">
                        <span class="font-semibold leading-none">€ 6,35</span>
                        <span style="font-size:10px" class="text-gray-500 leading-none">{{ locale.oneWay }}</span>
                      </div>
                    </div>
                    <div class="flex">
                      <div class="w-full pl-2 py-1 flex flex-col justify-start items-start">
                        <p class="text-xs flex flex-col p-1 text-blue-500 leading-tight">
                          <span class="font-bold">{{ locale.noAdditionalFees }}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="w-full flex flex-col border shadow-lg md:border md:border-gray-300 rounded">
                    <div class="flex border-t border-b">
                      <div class="w-2/3 pl-2 py-1 flex justify-start items-center font-semibold leading-none text-lg md:text-sm">
                        Animale domestico
                      </div>
                      <div class="w-1/3 py-2 border-l flex flex-col justify-center items-center text-base bg-blue-100 text-blue-500">
                        <span class="font-semibold leading-none">€ 31,75</span>
                        <span style="font-size:10px" class="text-gray-500 leading-none">{{ locale.oneWay }}</span>
                      </div>
                    </div>
                    <div class="flex">
                      <div class="w-full pl-2 py-1 flex flex-col justify-start items-start">
                        <p class="text-xs flex flex-col p-1 text-blue-500 leading-tight">
                          <span class="font-bold">{{ locale.noAdditionalFees }}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>

            <!-- RESIDENTI -->
            <div v-if="activeTab == 'res'" class="flex flex-col">

              <!-- PERIODO -->
              <div class="w-full p-2 bg-blue-100 mt-2 border rounded">
                <span class="px-2 text-blue-700" style="background-color:yellow">
                  <i class="fa fa-calendar mr-1" />
                  {{ locale.ResFarePeriod }}
                </span>
              </div>

              <!-- CATEGORIE -->
              <div class="w-full flex flex-col md:flex-row justify-between mt-2">

                <!-- ADULTO -->
                <div class="w-full md:w-1/3 flex flex-col mb-4 md:mb-0 md:mr-2 border shadow-lg md:border md:border-gray-300 rounded">
                  <div class="p-1">
                    <img src="/img/fares/adult.jpg" alt="Adulto">
                  </div>
                  <div class="flex border-t border-b">
                    <div class="w-2/3 pl-2 py-1 flex justify-start items-center font-semibold leading-none text-lg md:text-sm">
                      {{ locale.Adult }}
                    </div>
                    <div class="w-1/3 py-2 border-l flex flex-col justify-center items-center text-base bg-blue-100 text-blue-500">
                      <span class="font-semibold leading-none">€ 31,75 *</span>
                      <span style="font-size:10px" class="text-gray-500 leading-none">{{ locale.oneWay }}</span>
                    </div>
                  </div>
                  <div class="flex">
                    <div class="w-full pl-2 py-1 flex flex-col justify-start items-start">
                      <p class="text-xs flex flex-col p-1 text-blue-500 leading-tight">
                        <span class="font-bold mb-1">{{ locale.additionalFees }}</span>
                        <span>€ 13,91 {{ locale.departuresFrom }}</span>
                        <span>€ 7,41 {{ locale.departuresFromOtherHeliports }}</span>
                      </p>
                      <div class="row">
                        <div class="col-xs-12" style="font-size:11px">
                          <ul class="list-unstyled">
                            <li><strong>{{ locale.CostDetail }}</strong></li>
                            <li><i class="text-blue-500 fa fa-caret-square-o-up" /> € 3,59 {{ locale.boarding }}</li>
                            <li><i class="text-blue-500 fa fa-star" /> € 6,50 {{ locale.additionalServices }} **</li>
                            <li><i class="text-blue-500 fa fa-rss" /> € 1,81 {{ locale.security }}</li>
                            <li><i class="text-blue-500 fa fa-suitcase" /> € 1,30 {{ locale.luggageCheckFix }} ***</li>
                            <li><i class="text-blue-500 fa fa-suitcase" /> € 0,37 {{ locale.luggageCheckVar }} ***</li>
                            <li><i class="text-blue-500 fa fa-wheelchair" /> € 0,34 {{ locale.paxReducedMobility }}</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- BAMBINO -->
                <div class="w-full md:w-1/3 flex flex-col mb-4 md:mb-0 md:mx-2 border shadow-lg md:border md:border-gray-300 rounded">
                  <div class="p-1">
                    <img src="/img/fares/child.jpg" alt="Bambino">
                  </div>
                  <div class="flex border-t border-b">
                    <div class="w-2/3 pl-2 py-1 flex justify-start items-center font-semibold leading-none text-lg md:text-sm">
                      Bambino
                      <span class="ml-1 text-gray-500 font-normal" style="font-size:10px">(2-11 anni)</span>
                    </div>
                    <div class="w-1/3 py-2 border-l flex flex-col justify-center items-center text-base bg-blue-100 text-blue-500">
                      <span class="font-semibold leading-none">€ 15,87 *</span>
                      <span style="font-size:10px" class="text-gray-500 leading-none">{{ locale.oneWay }}</span>
                    </div>
                  </div>
                  <div class="flex">
                    <div class="w-full pl-2 py-1 flex flex-col justify-start items-start">
                      <p class="text-xs flex flex-col p-1 text-blue-500 leading-tight">
                        <span class="font-bold mb-1">{{ locale.additionalFees }}</span>
                        <span>€ 12,12 {{ locale.departuresFrom }}</span>
                        <span>€ 5,62 {{ locale.departuresFromOtherHeliports }}</span>
                      </p>
                      <div class="row">
                        <div class="col-xs-12" style="font-size:11px">
                          <ul class="list-unstyled">
                            <li><strong>{{ locale.CostDetail }}</strong></li>
                            <li><i class="text-blue-500 fa fa-caret-square-o-up" /> € 1,80 {{ locale.boarding }}</li>
                            <li><i class="text-blue-500 fa fa-star" /> € 6,50 {{ locale.additionalServices }} **</li>
                            <li><i class="text-blue-500 fa fa-rss" /> € 1,81 {{ locale.security }}</li>
                            <li><i class="text-blue-500 fa fa-suitcase" /> € 1,30 {{ locale.luggageCheckFix }} ***</li>
                            <li><i class="text-blue-500 fa fa-suitcase" /> € 0,37 {{ locale.luggageCheckVar }} ***</li>
                            <li><i class="text-blue-500 fa fa-wheelchair" /> € 0,34 {{ locale.paxReducedMobility }}</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- NEONATO + ANIMALE -->
                <div class="w-full md:w-1/3 flex flex-col justify-between mb-2 md:mb-0 md:ml-2">
                  <div class="w-full flex flex-col border shadow-lg md:border md:border-gray-300 rounded">
                    <div class="p-1">
                      <img src="/img/fares/infant.jpg" alt="Neonato">
                    </div>
                    <div class="flex border-t border-b">
                      <div class="w-2/3 pl-2 py-1 flex justify-start items-center font-semibold leading-none text-lg md:text-sm">
                        {{ locale.Infant }}
                        <span class="ml-1 text-gray-500 font-normal" style="font-size:10px">(0-23 {{ locale.months }})</span>
                      </div>
                      <div class="w-1/3 py-2 border-l flex flex-col justify-center items-center text-base bg-blue-100 text-blue-500">
                        <span class="font-semibold leading-none">€ 3,17</span>
                        <span style="font-size:10px" class="text-gray-500 leading-none">{{ locale.oneWay }}</span>
                      </div>
                    </div>
                    <div class="flex">
                      <div class="w-full pl-2 py-1 flex flex-col justify-start items-start">
                        <p class="text-xs flex flex-col p-1 text-blue-500 leading-tight">
                          <span class="font-bold">{{ locale.noAdditionalFees }}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="w-full flex flex-col border shadow-lg md:border md:border-gray-300 rounded">
                    <div class="flex border-t border-b">
                      <div class="w-2/3 pl-2 py-1 flex justify-start items-center font-semibold leading-none text-lg md:text-sm">
                        Animale domestico
                      </div>
                      <div class="w-1/3 py-2 border-l flex flex-col justify-center items-center text-base bg-blue-100 text-blue-500">
                        <span class="font-semibold leading-none">€ 15,87</span>
                        <span style="font-size:10px" class="text-gray-500 leading-none">{{ locale.oneWay }}</span>
                      </div>
                    </div>
                    <div class="flex">
                      <div class="w-full pl-2 py-1 flex flex-col justify-start items-start">
                        <p class="text-xs flex flex-col p-1 text-blue-500 leading-tight">
                          <span class="font-bold">{{ locale.noAdditionalFees }}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>

          </div>

          <div id="info-container" class="flex flex-col">
            <div class="text-xs" style="color:#9d9c9c;margin-top:15px;display:block">
              <p>* {{ locale.note1 }}</p>
              <p>** {{ locale.note2 }}</p>
              <p>*** {{ locale.note3 }}</p>
            </div>
            <div v-if="lang == 'it'" class="mt-3 text-xs text-blue-500 border p-1 rounded">
              L'auto può restare all'interno dell'eliporto Alidaunia, sulle aree di sosta antistanti il terminal, appositamente riservate ai passeggeri ed individuate dalle strisce di colore blu.<br>
              Il parcheggio è tariffato 0,20 €/ora e la disponibilità di posti è limitata.
              <div class="flex items-center space-x-1">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"></path>
                </svg>
                <a href="/pdf/docs/it/regolamento-parcheggio-alidaunia.pdf" target="_blank">leggi il regolamento del parcheggio Alidaunia</a>
              </div>
            </div>
            <div v-if="lang == 'en'" class="mt-3 text-xs text-blue-500 border p-1 rounded">
              The car can remain inside the Alidaunia heliport, on the parking areas in front of the terminal, specially reserved for passengers and identified by the blue stripes.<br>
              Parking is charged 0,20 €/hour and the availability of spaces is limited.
              <div class="flex items-center space-x-1">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"></path>
                </svg>
                <a href="/pdf/docs/it/regolamento-parcheggio-alidaunia.pdf" target="_blank">read Alidaunia parking regulations </a>
              </div>
            </div>
            <div class="mt-5 flex flex-col md:flex-row">
              <div class="mr-3">
                <img class="w-40" src="/img/loghi/regione-puglia.png">
              </div>
              <div v-if="lang == 'it'" class="text-xs">
                Tariffe in vigore dal 01/10/2014 come da Deliberazione della Giunta Regionale - Regione Puglia - N.1912 del 23/09/2014. Riferite al servizio di linea Trasporto Pubblico Passeggeri.<br>
                Adeguamento della base tariffaria dal 16/10/2018 come da Deliberazione della Giunta Regionale - N.1646 del 20/09/2018.<br>
                Adeguamento della base tariffaria dal 04/12/2019 come da Deliberazione della Giunta Regionale - N.1993 del 04/11/2019.<br>
                Adeguamento della base tariffaria dal 13/01/2021 come da Deliberazione della Giunta Regionale - N.2138 del 22/12/2020.<br>
                Adeguamento della base tariffaria dal 23/05/2022 come da Deliberazione della Giunta Regionale - N.553 del 20/04/2022.<br>
                Adeguamento della base tariffaria dal 15/06/2023 come da Deliberazione della Giunta Regionale - N.842 del 14/06/2023.<br>
                Adeguamento della base tariffaria dal 08/07/2024 come da Deliberazione della Giunta Regionale - N.767 del 11/06/2024.
              </div>
              <div v-if="lang == 'en'" class="text-xs">
                Rates in force from 01/10/2014 as per Resolution of the Regional Council - Apulia Region - N.1912 of 09/23/2014. Related to the Public Passenger Transport.<br>
                Adjustment from 16/10/2018 as per Resolution of the Regional Council - N.1646 del 20/09/2018.<br>
                Adjustment from 04/12/2019 as per Resolution of the Regional Council - N.1993 del 04/11/2019.<br>
                Adjustment from 13/01/2021 as per Resolution of the Regional Council - N.2138 del 22/12/2020.<br>
                Adjustment from 23/05/2022 as per Resolution of the Regional Council - N.553 del 20/04/2022.<br>
                Adjustment from 15/06/2023 as per Resolution of the Regional Council - N.842 del 14/06/2023.<br>
                Adjustment from 08/07/2024 as per Resolution of the Regional Council - N.767 del 11/06/2024.
              </div>
            </div>
            <div class="my-5 flex flex-col md:flex-row">
              <div class="mr-3">
                <img class="w-40" src="/img/loghi/comune-tremiti.png">
              </div>
              <div v-if="lang == 'it'" class="text-xs">
                <span class="font-bold">Contributo di sbarco</span> introdotto dal Consiglio Comunale delle Isole Tremiti con Deliberazione N.16 del 30/04/2016 e successivamente modificato con Deliberazione N.9 del 25/03/2017.
              </div>
              <div v-if="lang == 'en'" class="text-xs">
                <span class="font-bold">Disembarkation fee</span> in force from 01/06/2016 as per Resolution of the Town Counsil - Town of Tremiti Islands - N.16 of 30/04/2016, rev.9 of 25/03/2017.
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Locales from '../../locales.json'

export default {
  name: 'Fares',
  data:() => ({
    activeTab: 'low',
    locales: null
  }),
  computed: {
    lang() {
      return this.$parent.lang
    },
		locale() {
			return this.locales[this.lang]
		}
  },
  methods: {
    showTab(tab) {
      this.activeTab = tab
    }
  },
  created() {
    this.locales = Locales

    let year = moment().format('YYYY')
    let today = moment().format('YYYY-MM-DD')

    if ( (today >= `${year}-01-01` && today <= `${year}-06-14`) || (today >= `${year}-09-16` && today <= `${year}-12-31`) ) {
      // console.log('low')
      this.activeTab = 'low'
    }
    else {
      // console.log('high')
      this.activeTab = 'high'
    }
  }
}
</script>

<style scoped>
ul.nav-tabs li a {
  color:silver;
}
ul.nav-tabs li.active a {
  color:#114D88 !important;
}
</style>
