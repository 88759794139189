<template>
  <section id="main-content" class="flex-1 w-full max-w-5xl justify-start items-start mx-auto mt-0 md:mt-20 px-2 flex flex-col bg-white min-h-screen">
    <div class="md:mx-auto w-full md:w-2/3">
      <div class="my-4 md:my-0 rounded-lg shadow-xl border w-full h-full relative bg-white">
        <vue-loading :loading="loading" />
        <div class="w-full p-4">
          <div class="hidden md:flex justify-begin items-center h-12 px-2">
						<svg class="h-10" viewBox="0 0 1640 404">
							<g>
								<path style="fill:#0B4584" d="M197 19l154 0c17,0 30,15 30,34l0 292 -14 0 0 -289c0,-13 -9,-23 -21,-23l-149 0 0 -14zm-15 385l-151 0c-17,0 -31,-16 -31,-34l0 -307 14 0 0 303c0,13 10,23 21,23l147 0 0 15z"/>
								<path style="fill:#E31E24" d="M197 38l145 0c11,0 20,10 20,23l0 284 -14 0 0 -277c0,-8 -7,-15 -14,-15l-137 0 0 -15zm-15 346l-142 0c-11,0 -20,-10 -20,-23l0 -298 14 0 0 291c0,9 6,16 14,16l134 0 0 14z"/>
								<path style="fill:#0B4584" d="M1141 298l0 -75c0,-11 0,-45 -42,-45l-48 0c15,0 32,13 32,32l0 88 58 0z"/>
								<path style="fill:#0B4584" d="M1073 298l0 -64 -20 0c-51,0 -51,64 -1,64l21 0z"/>
								<path style="fill:#0B4584" d="M931 298l0 -120 -17 0c-70,0 -68,120 0,120l17 0z"/>
								<circle style="fill:#0B4584" cx="1049" cy="205" r="24"/>
								<path style="fill:#0B4584" d="M1640 298l0 -75c0,-11 0,-45 -41,-45l-49 0c15,0 32,13 32,32l0 88 58 0z"/>
								<path style="fill:#0B4584" d="M1572 298l0 -64 -20 0c-51,0 -51,64 -1,64l21 0z"/>
								<circle style="fill:#0B4584" cx="1549" cy="205" r="24"/>
								<path style="fill:#0B4584" d="M1156 178l59 0 0 120c-20,0 -59,5 -59,-44l0 -76z"/>
								<path style="fill:#0B4584" d="M1298 298l59 0 0 -120c-20,0 -59,-4 -59,45l0 75z"/>
								<path style="fill:#0B4584" d="M1283 178l-59 0 0 120c20,0 59,5 59,-44l0 -76z"/>
								<path style="fill:#0B4584" d="M1426 298l-59 0 0 -120c19,0 59,-4 59,45l0 75z"/>
								<rect style="fill:#0B4584" x="1440" y="178" width="58.981" height="120.078"/>
								<rect style="fill:#0B4584" x="789" y="178" width="58.981" height="120.078"/>
								<rect style="fill:#0B4584" x="642" y="172" width="58.981" height="126.767"/>
								<rect style="fill:#0B4584" x="258" y="172" width="58.981" height="126.767"/>
								<polygon style="fill:#0B4584" points="447,298 607,114 689,114 530,298 "/>
								<polygon style="fill:#0B4584" points="63,298 223,114 304,114 145,298 "/>
								<rect style="fill:#0B4584" x="941" y="114" width="58.981" height="184.307"/>
								<rect style="fill:#0B4584" x="715" y="114" width="58.981" height="184.307"/>
								<circle style="fill:#0B4584" cx="818" cy="146" r="28"/>
								<circle style="fill:#0B4584" cx="1470" cy="146" r="28"/>
								<path style="fill:#E31E24" d="M156 37l-24 0 -27 4 -2 -4 0 0 -41 0 0 8 -9 0 -1 -8 -21 0 -7 -4c-5,1 -22,4 -24,8 -3,6 18,6 21,6 6,1 18,0 26,0 6,0 15,0 19,1 2,0 4,1 8,2l5 -10 15 0 18 8c8,-1 17,-3 25,-5 1,0 2,0 2,1 2,0 2,-2 3,-2 2,-1 13,-5 14,-5zm-95 0l-8 0 1 7 7 0 0 -7zm64 0l-21 0 0 0 2 2 19 -2zm-49 13c3,0 7,1 13,1 1,0 9,-1 10,-1 4,0 7,-1 11,-1l-17 -7 -13 0 -4 8z"/>
								<path style="fill:#0B4584" d="M27 32l3 3 22 0 2 -9 8 0 0 9 41 0 0 -9 1 0 0 9 26 0 1 -8 2 0 -1 8 32 0c2,-1 6,-2 8,-3 2,-1 2,-3 0,-4 0,-2 17,-24 20,-28 -19,0 -22,0 -28,5l-27 20c0,1 -5,0 -6,0 -8,-2 -19,-3 -27,-2 0,0 -1,0 -1,0 0,0 -1,0 -1,1 -16,-1 -32,-1 -48,-1 -4,0 -11,0 -15,1 -2,1 -6,5 -9,6 0,1 -3,2 -3,2zm26 3l8 0 0 -9 -6 0 -2 9zm-13 -10l3 0 -1 1 5 0 -1 5 -7 0 -1 2 -8 -1 10 -7zm0 1l1 0 0 0 -3 4 -1 2 -4 -1 7 -5zm2 0l4 0 -1 4 -6 0 3 -4zm141 -24l3 2 -2 -2 -8 0 -7 2 3 1 -1 -1 5 -2 7 0zm-4 5l5 -1 -5 0 -21 21 12 0 0 0 -10 0 19 -20zm-103 25c1,-2 1,-4 0,-5 -1,0 -2,0 -2,1 -1,0 -2,4 0,4 1,0 2,0 2,0zm9 -4c-1,-1 -2,-1 -2,-1 -2,1 -2,5 -1,5 0,1 2,0 2,0 1,0 1,0 1,-1 1,-1 0,-2 0,-3zm-29 0c0,0 0,0 0,0 0,0 0,0 0,0 -1,1 -1,2 0,3 0,1 0,1 1,1 0,0 2,0 2,0 1,0 1,-4 0,-5 -1,0 -2,0 -3,1zm9 4c0,0 1,0 2,0 1,0 1,0 1,-1 0,-1 0,-3 0,-3 -1,-1 -2,-1 -2,-1 -1,1 -1,1 -2,2 0,1 0,2 1,3z"/>
								<path style="fill:#0B4584" d="M212 380c0,0 -3,1 -3,1 0,1 0,1 0,2 2,3 54,10 58,10 1,0 9,2 9,3 1,1 9,4 10,4 2,0 4,0 5,1 4,1 16,0 16,1 1,1 0,2 3,2 11,0 21,0 32,0 1,0 3,-1 3,-2 5,0 28,1 29,-5 0,-4 -14,-7 -16,-7 -1,-1 -1,-1 -1,-2l-4 0 1 1c0,2 -1,3 -2,4 -3,2 -4,1 -5,1l-2 -5 -1 0 1 3c1,1 0,3 -1,3l-1 0 -5 0c-2,0 -3,-1 -3,-2l0 -4 -5 0 0 3c0,1 -1,2 -2,2l-7 0c0,0 0,0 -1,-1l0 -4 -1 0 0 4c0,1 0,1 -1,1l-7 0c-1,0 -1,-1 -1,-2l-1 -3 -19 0 -78 -9z"/>
								<path style="fill:#E31E24" d="M285 372c-1,1 -1,0 -1,1 -4,1 -8,2 -12,3 -2,1 -1,1 -3,1 -11,0 -23,1 -35,1 -1,0 -2,-1 -3,-1 -3,-1 -5,-4 -7,-6 -2,-2 -5,-5 -7,-8 -1,0 -1,0 -1,-1 -1,-1 -1,-1 -1,-2 0,0 0,0 0,0 0,-1 0,-2 -1,-2 0,0 0,-1 1,-1 1,0 1,1 1,-1 1,-2 4,-7 4,-9 -1,0 -2,-1 -3,0 -1,2 -3,10 -4,10 0,1 -1,2 -1,2l-5 2c0,0 -1,-1 -1,-1 -2,0 -10,1 -11,1 0,1 0,1 1,2 1,1 1,0 3,0 2,0 5,0 7,-1 0,0 9,13 9,16 0,0 0,0 0,1 0,0 68,7 74,8l20 0 0 -2c0,-1 1,-2 2,-2l6 0c1,0 1,1 1,2l1 2 1 0 0 -2c0,-1 0,-2 2,-2l5 0c1,0 2,1 2,2l0 2 6 0 -1 -4c0,0 1,-1 2,-1l3 0c1,0 2,0 2,1l2 4 1 0 -2 -4c0,-1 0,-1 1,-1l2 -1 7 6 4 0c-1,-1 -2,-2 -3,-2 0,0 0,0 -1,0 0,0 -2,-2 -2,-2l-1 0c0,-1 -2,-2 -3,-3 -3,-1 -4,-2 -7,-3 -1,-1 0,0 -2,-1 1,0 1,0 1,0 2,0 6,-5 6,-6 0,0 -5,4 -6,5 -1,0 -2,0 -3,1 -1,0 -2,0 -3,-1 0,0 0,0 0,-1l-47 -2zm55 7l-5 -1 -1 2 1 1 5 0 3 -1 -3 -1z"/>
								<path style="fill:#E31E24" d="M291 361c-16,-1 -31,-3 -47,-4 -1,-1 -7,-1 -8,-1 0,0 -1,0 -1,0 -1,0 -2,0 -3,0 0,0 -1,1 -1,1 0,0 0,0 0,0 1,0 2,-1 4,-1 0,0 1,0 1,0 15,2 30,4 44,6l6 0c0,0 -1,0 -1,1 0,0 1,0 1,0 1,0 1,0 1,0l0 -1 1 1c1,0 1,0 1,0 0,0 2,0 2,0l1 -1 14 2 0 1c1,0 2,0 2,0 1,0 1,0 2,0 0,1 0,2 -1,2 -3,0 -8,0 -11,1 -3,0 -6,1 -9,1 0,0 -1,2 -1,3 0,0 29,1 40,1 -1,-2 -3,-3 -4,-4 -2,-1 -4,-1 -7,-1 0,0 1,-2 1,-2 0,0 2,0 2,0 0,0 1,0 0,0 0,-1 -1,0 0,-1 1,0 6,1 7,1 3,1 2,1 6,1 13,2 26,3 39,4 2,0 3,0 5,1 1,0 1,1 2,1 1,0 1,0 2,0l1 0c0,0 -3,-2 -4,-2 0,0 -1,0 -2,0l-54 -7c-1,0 -1,0 -1,0 0,0 0,0 0,-1 -1,0 -2,0 -2,0 0,1 0,1 0,0l-4 0c0,0 0,0 1,0 0,0 1,0 2,0 0,0 0,-1 -1,-1 -1,0 -1,-2 -3,-2 -2,0 -2,1 -3,1 -1,1 -1,1 -1,1 0,0 2,0 2,0 0,0 0,0 0,1 0,0 -5,-1 -6,-1l0 0 1 1c-6,-1 -11,-1 -16,-2z"/>
							</g>
						</svg>            
            <div class="top-login w-full">&nbsp;</div>
          </div>
          <div class="w-full md:pt-2 text-blue-800 flex flex-wrap md:flex-no-wrap">
            <span class="w-full h-8 border rounded flex justify-center items-center" :class="messageStatus" v-text="message.content" />
          </div>
          <div class="md:pt-2 flex flex-wrap md:flex-no-wrap">
            <form class="w-full md:w-2/3 flex flex-col">
              <div method="post" action="" class="font-bold text-sm flex text-blue-800">
                <div class="w-1/4"></div>
                <div class="w-3/4">
                  <span v-if="lang == 'it'">Accedi con credenziali Alidaunia</span>
                  <span v-if="lang == 'en'">Login with Alidaunia credentials</span>
                </div>
              </div>
              <div class="mt-4">
                <div class="flex items-stretch mb-3">
                  <label for="email" class="text-right text-blue-800 text-xs pt-2 pr-3 align-middle w-1/4">E-Mail</label>
                  <div class="flex flex-col w-3/4">
                    <input v-model="form.email" type="email" data-cy="email" class="flex-grow h-8 px-2 border rounded" name="email" required autofocus>
                  </div>
                </div>

                <div class="flex items-stretch mb-4">
                  <label for="password" class="text-right text-blue-800 text-xs pt-2 pr-3 align-middle w-1/4">Password</label>
                  <div class="flex flex-col w-3/4">
                    <input v-model="form.password" type="password" data-cy="password" class="flex-grow h-8 px-2 rounded border" name="password" required>
                  </div>
                </div>

                <div class="flex mb-4">
                  <div class="w-3/4 ml-auto flex flex-col">
                    <button type="button" @click="login" data-cy="submit-login" class="w-32 bg-blue-500 hover:bg-blue-600 text-white text-sm font-semibold py-2 px-4 rounded mr-3">
                      Login
                    </button>
                    <router-link :to="{ name: 'password-request' }" class="my-4 no-underline hover:underline text-blue-800 text-sm">
                      <span class="text-xs">
                        <span v-if="lang == 'it'">Password dimenticata?</span>
                        <span v-if="lang == 'en'">Forgot password?</span>
                      </span>
                    </router-link>
                  </div>
                </div>
              </div>
            </form>
            <div class="w-full md:w-1/3 flex flex-col md:pl-8">
              <div class="text-sm">
                <span v-if="lang == 'it'">oppure con:</span>
                <span v-if="lang == 'en'">or login through social:</span>
              </div>
              <div class="mt-4 cursor-not-allowed">
                <a href="/login/google" class="no-underline h-8 w-full hover:bg-gray-200 mb-3 px-2 flex items-center text-left border text-black rounded mr-3"> <!-- href="{{ route('login.google', ['lang' => $lang]) }}"  -->
                  <img src="/img/social-logins/google.png" class="w-6">
                  <span class="ml-2" style="font-size:13px; font-family:monospace">Google</span>
                </a>
                <a href="/login/facebook" class="no-underline h-8 w-full hover:bg-gray-200 mb-3 px-2 flex items-center text-left border text-black rounded mr-3"> <!-- href="{{ route('login.facebook', ['lang' => $lang]) }}"  -->
                  <img src="/img/social-logins/facebook.png" class="w-6">
                  <span class="ml-2" style="font-size:13px; font-family:monospace">Facebook</span>
                </a>
                <a href="/login/twitter" class="no-underline h-8 w-full hover:bg-gray-200 mb-3 px-2 flex items-center text-left border text-black rounded mr-3"> <!-- href="{{ route('login.twitter', ['lang' => $lang]) }}"  -->
                  <img src="/img/social-logins/twitter.png" class="w-6">
                  <span class="ml-2" style="font-size:13px; font-family:monospace">Twitter</span>
                </a>
              </div>
            </div>
          </div>
          <div class="border-t pt-4 text-xs flex flex-wrap md:flex-no-wrap items-center">
            <div class="w-full md:w-2/3 leading-tight text-blue-800">
              <p v-if="lang == 'it'">
                Nuovo su Alidaunia.it?<br>
                Crea un account per effettuare prenotazioni e check-in online.<br>
                La registrazione è semplice e gratuita. 
              </p>
              <p v-if="lang == 'en'">
                New on Alidaunia.it?<br>
                Create an account for bookings and check-in online.<br>
                Registration is easy and free.
              </p>
            </div>
            <div class="w-full md:w-1/3 md:pl-8 mt-4 md:mt-0 flex justify-center">
              <router-link :to="{ name: 'sign-up' }" class="no-underline text-center w-1/2 md:w-full bg-green-500 hover:bg-green-700 text-white text-sm font-semibold py-2 px-4 rounded">
                <span v-if="lang == 'it'">Nuovo utente</span>
                <span v-if="lang == 'en'">New user</span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'LoginForm',
  data:() => ({
    loading: false,
    message: {
      status: null,
      content: null
    },
    form: {
      email: null,
      password: null
    }
  }),
  computed: {
    lang() {
      return this.$parent.lang
    },
    messageStatus() {
      let { status } = this.message
      if (status == 'success') {
        return ['border-green-500', 'bg-green-600', 'text-white']
      }
      else if (status == 'error') {
        return ['border-red-500', 'bg-red-600', 'text-white']
      }
      else {
        return ['border-white']
      }
    }
  },
  methods: {
    async login() {
      try {
        this.loading = true
        
        let { form } = this

        await api.get('/sanctum/csrf-cookie')

        let { data } = await api.post('api.auth.login', form)
        dbg(data, 'data')
        let { message, user } = data
        dbg(user, 'user')
        let { token } = user
        dbg(token, 'token')
        this.message = message
        
        // localStorage.setItem('token', btoa(token))
        
        let userObj = clone(user)
        delete userObj.token

        localStorage.setItem('user', btoa(JSON.stringify(userObj)))

        this.$parent.isLogged = true

        setTimeout(x => {
          
          // this.$parent.auth.token = token
          this.$parent.auth.user = user

          this.$parent.$refs['user-drop'].getUserData()

          // this.$router.push({ name: 'booking' })
          location.href = '/booking'
        }, 2000)
      }
      catch (error) {
        if (error.hasOwnProperty('response')) {}
        console.log(error)
        // callErrors(error)
        this.loading = false
      }
    },
		checkToken() {
			// let token = localStorage.getItem('token')
			// if (token) {
			// 	// location.href = '/it/prenotazioni'
			// }
		}
  },
	created() {
    if (this.$parent.auth.token && this.$parent.auth.user) {
      this.$router.push({ name: 'booking' })
      return
    }
		this.checkToken()
	}
}
</script>
