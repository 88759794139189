<template>
	<section class="w-full max-w-5xl mx-auto">
		<div class="relative w-full flex flex-col bg-white">
      <vue-loading :loading="loading" />
			<div class="flex flex-col">
        <div class="flex">
          <div class="w-full flex my-5 h-10">
            <span class="flex-1 self-center border-t border-gray-300"></span>
            <span class="text-2xl font-semibold text-blue-900 text-center px-3">
              <span v-if="lang == 'it'">INAUGURAZIONE ELISUPERFICIE</span>
              <span v-if="lang == 'en'">HELIPAD INAUGURATION</span>              
            </span>
            <span class="flex-1 self-center border-t border-gray-300"></span>
          </div>
        </div>        
        <div v-if="post" class="flex flex-col md:flex-row mb-10">
          
          <!-- BEGIN CONTENT -->
          <div class="w-full md:w-2/3 pl-0 md:pl-10 mt-5 md:mt-0">

            <div class="text-sm" v-text="post.post_date" />
            <div class="font-semibold text-blue-800 text-2xl" v-text="post.title" />
            <img class="my-3 w-64" :src="`/img/helipad-posts/${post.progr}/00.jpg`" />
            <div class="my-5 font-semibold text-blue-800 text-lg">Rassegna Stampa dell'evento</div>
            <div class="text-blue-800" v-html="post.content"></div>

          </div>
          <!-- END CONTENT -->
          
          <!-- BEGIN SIDEBAR -->
          <div class="w-full md:w-1/3 pl-0 md:pl-10 mt-5 md:mt-0">
            <agile v-if="post.photos > 0" :autoplay-speed="3000" :speed="1500" pause-on-hover="pause-on-hover" pause-on-dots-hover="pause-on-dots-hover" autoplay="autoplay">
              <div class="slide" v-for="index in post.photos">
                <img :src="`/img/helipad-posts/${post.progr}/${index < 10 ? '0'+index : index }.jpg`" />
              </div>
              <template slot="prevButton"><i class="fa fa-chevron-left"></i></template>
              <template slot="nextButton"><i class="fa fa-chevron-right"></i></template>
            </agile>

            <div class="mt-6">
              <router-link v-for="post in posts" :key="post.id" class="w-full flex pl-3 py-3 border-b hover:bg-gray-200" :class="sidebarItemActive(post.slug)" :to="{ name: 'helipad-post', params: { slug: post.slug } }">
                <img class="w-20" :src="`/img/helipad-posts/${post.progr}/00.jpg`" />
                <div class="ml-3 flex-1">
                  <div class="text-sm" v-text="post.post_date" />
                  <div class="font-semibold text-blue-800 text-base" v-text="post.title" />
                </div>
              </router-link>
            </div>
          </div>
          <!-- END SIDEBAR -->
        </div>
			</div>
		</div>
	</section>
</template>

<script>
export default {
  name: 'HelipadPost',
  data:() => ({
    loading: true,
    post: null,
    posts: []
  }),
  computed: {
    lang() {
      return this.$parent.lang
    },
    slug() {
      return this.$route.params.slug
    }
  },
  watch: {
    slug(val) {
      this.fetchPost()
    }
  },
  methods: {
    sidebarItemActive(route) {
      if (route == this.$route.params.slug) {
        return ['font-semibold text-blue-800 bg-blue-100']
      }
    },
    async fetchPost() {
      try {
        this.loading = true
        var { data } = await api.get(route('api.helipad-posts.show', { post: this.slug }))
        this.post = data.post
        this.loading = false
      }
      catch(error) {
        callErrors(error)
        this.loading = false
      }
    },
    async fetchPosts() {
      try {
        this.loading = true
        var { data } = await api.get('api.helipad-posts.index')
        this.posts = data.posts
        this.loading = false
      }
      catch(error) {
        callErrors(error)
        this.loading = false
      }      
    },
    async fetchData() {
      this.fetchPost()
      this.fetchPosts()
    }
  },
  created() {
    this.fetchData()
  }
}
</script>
