<template>
	<div class="input-group" style="width:130px;">
          <span class="input-group-btn">
              <button type="button" class="btn btn-default btn-number btn-sm" @click.prevent="rem">
                  <span class="fa fa-minus"></span>
              </button>
          </span>
          <input v-model="model" type="text" class="form-control input-number" @keyup="checkIfNum" style="text-align:right; font-weight: bold; font-size: 16px; padding-bottom: 1px">
          <span class="input-group-btn">
              <button type="button" class="btn btn-default btn-number btn-sm" @click.prevent="add">
                  <span class="fa fa-plus"></span>
              </button>
          </span>
      </div>
</template>

<script>
export default {
	name: 'InputPlusMin',
	model: {
		prop: 'model',
		event: 'update'
	},
	props: {
		model: {
			type: Number
		},
		max: {
			type: Number,
			default: null
		}
	},
	// watch: {
	// 	start(val, oldVal) {
	// 		if (val != this.model) {
	// 			this.model = val
	// 		}
	// 	},
	// 	max(val, oldVal) {
	// 		if (parseFloat(this.model) > parseFloat(val) && val != null) {
	// 			this.model = val
	// 		}
	// 	},
	// 	model(val) {
	// 		if (parseFloat(val) > parseFloat(this.max)) {
	// 			this.model = this.max
	// 			val = this.model
	// 		}
	// 		if (parseFloat(val) < 0) {
	// 			this.model = 0
	// 			val = this.model
	// 		}
	// 		alert(val)
	// 		this.$emit('change', val)
	// 	}
	// },
	methods: {
		checkIfNum() {
			if (isNaN(this.model)) {
				this.$emit('update', 0)
			}
		},
		add() {
			var model = this.model
			var max = this.max
			if (max && model == max) { return }
			model = model + 1
			this.$emit('update', model)
		},
		rem() {
			var model = this.model
			if (model == 0) { return }
			model = model - 1
			this.$emit('update', model)
		}
	}
}
</script>