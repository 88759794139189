<template>
    <div class="bg-white p-3 rounded-b">
        <form class="form-horizontal" method="POST" action="#">

            <div class="flex items-stretch mb-3">
                <label for="email" class="text-right font-semibold text-grey-dark text-sm pt-2 pr-3 align-middle w-1/4">E-Mail Address</label>
                <div class="flex flex-col w-3/4">
                    <input v-model="email" id="email" type="email" class="flex-grow h-8 px-2 border rounded" name="email" required autofocus>
                </div>
            </div>

            <div class="flex items-stretch mb-4">
                <label for="password" class="text-right font-semibold text-grey-dark text-sm pt-2 pr-3 align-middle w-1/4">Password</label>
                <div class="flex flex-col w-3/4">
                    <input v-model="password" id="password" type="password" class="flex-grow h-8 px-2 rounded border" name="password" required>
                </div>
            </div>

            <div class="flex flex-col bg-grey-lighter">
                <div class="text-grey-darker text-center bg-grey-light px-4 py-2 m-2">
                    <button @click="login('api')" :disabled="logged" :class="{ 'opacity-50 cursor-not-allowed': logged }" type="button" class="bg-brand hover:bg-brand-dark text-white text-sm font-semibold py-2 px-4 rounded mr-3">
                        Login API
                    </button>
                    <button @click="login('web')" :class="{ 'opacity-50 cursor-not-allowed': logged }" type="button" class="bg-brand hover:bg-brand-dark text-white text-sm font-semibold py-2 px-4 rounded mr-3">
                        Login Web
                    </button>
                </div>
                <div class="text-grey-darker text-center bg-grey-light px-4 py-2 m-2">
                    <button @click="userDetails" :disabled="! logged" :class="{ 'opacity-50 cursor-not-allowed': ! logged }" type="button" class="bg-brand hover:bg-brand-dark text-white text-sm font-semibold py-2 px-4 rounded mr-3">
                        User-details
                    </button>
                </div>
                <div class="text-grey-darker text-center bg-grey-light px-4 py-2 m-2">
                    <button @click="userBookings" :disabled="! logged" :class="{ 'opacity-50 cursor-not-allowed': ! logged }" type="button" class="bg-brand hover:bg-brand-dark text-white text-sm font-semibold py-2 px-4 rounded mr-3">
                        User-bookings
                    </button>
                </div>
                <div class="text-grey-darker text-center bg-grey-light px-4 py-2 m-2">
                    <button @click="logout('api')" :disabled="! logged" :class="{ 'opacity-50 cursor-not-allowed': ! logged }" type="button" class="bg-brand hover:bg-brand-dark text-white text-sm font-semibold py-2 px-4 rounded mr-3">
                        Logout API
                    </button>
                    <button @click="logout('web')" :class="{ 'opacity-50 cursor-not-allowed': ! logged }" type="button" class="bg-brand hover:bg-brand-dark text-white text-sm font-semibold py-2 px-4 rounded mr-3">
                        Logout Web
                    </button>
                </div>
            </div>
        </form>
        <div class="mt-3 p-4 border-red-light border-t-2">
            <span v-if="logged" class="bg-green-light text-white p-2 rounded">LOGGED-IN USER</span>
            <span v-else class="bg-purple-light text-white p-2 rounded">GUEST</span>
            <pre class="mt-2" v-html="user"></pre>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            email: '',
            password: '',
            token: '',
            logged: false,
            user: null
        }
    },
    methods: {
        login(type) {
            if (type == 'api') {
                var url = '/api/v1/auth/login'
            }
            else if (type == 'web') {
                var url = '/login'
            }
            axios.post(url, {
                email: this.email,
                password: this.password,
                _token: document.head.querySelector('meta[name="csrf-token"]')
            })
            .then(r => {
                if (type == 'api') {
                    this.token = r.data.success.token
                    this.$emit('logged', { token: r.data.success.token, user: r.data.user })
                    this.logged = true
                    axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.token;
                }
                else if (type == 'web') {
                    window.location = '/it/prenotazioni'
                }
                console.log('Logged in!')
            })
            .catch(r => {
                alert('autenticazione fallita!')
                console.info(JSON.stringify(r))
            })
        },
        userDetails() {
            axios.post('/api/v1/user/details')
            .then(r => {
                this.user = r.data.user
                console.log(r.data)
            })
        },
        userBookings() {
            axios.post('/api/v1/user/bookings')
            .then(r => {
                this.user = r.data.user
                console.log(r.data)
            })
        },
        logout(type) {
            if (type == 'api') {
                var url = '/api/v1/auth/logout'
            }
            else if (type == 'web') {
                var url = '/logout'
            }
            axios.post(url)
            .then(r => {
                if (type == 'api') {
                    this.user = null
                    this.logged = false
                }
                else if (type == 'web') {
                    window.location = '/it/prenotazioni'
                }
            });
        }
    }
}
</script>