<template>
  <section id="main-content" class="flex-1 w-full max-w-5xl justify-start items-start mx-auto px-2 flex flex-col bg-white min-h-screen mt-3 mb-5">
    <div class="mt-5 md:mx-auto w-full md:w-2/3">
      <div class="my-4 md:my-0 rounded-lg shadow-xl border w-full h-full relative bg-white">
        <vue-loading :loading="loading" />
        <div class="w-full">
          <div class="flex flex-col">
            <div class="w-full flex justify-center h-6 my-5">
              <span class="text-xl font-semibold text-blue-900 text-center px-3">
                <span v-if="! user.verified" v-text="locale.user_forms.pending_1" />
                <span v-if="user.verified && user.is_agency && ! user.is_agency_auth" v-text="locale.user_forms.pending_2" />
              </span>
            </div>
          </div> 
          <div class="w-full md:pt-2 text-blue-800 flex flex-col flex-wrap md:flex-no-wrap p-4">
            <div v-if="! user.verified" class="w-full text-justify mb-5" v-text="locale.user_forms.verify_email_label_1" />

            <div v-if="user.is_agency" class="mb-5 border border-red-600 rounded p-2 text-red-600 flex justify-center items-center">
              <fa class="s-exclamation-triangle mr-1" />
              L'account "AGENZIA" sarà sbloccato solo dopo conferma dell'amministratore di sistema.
            </div>
            
            <div v-if="! user.verified" class="mb-5 border border-blue-600 rounded p-2 flex flex-col justify-center items-center text-blue-600">
              <span v-text="locale.user_forms.verify_email_label_2a" />
              <div>
                <span v-text="locale.user_forms.verify_email_label_2b" />
                <span class="font-semibold" v-text="user.email" />
              </div>
              <button @click="resendEmail" class="mt-2 rounded bg-blue-500 hover:bg-blue-600 text-white px-2 py-1 flex justify-center items-center">
                <fa class="r-envelope mr-1" />
                <span v-text="locale.user_forms.verify_email_label_3" />
              </button>
            </div>

            <router-link :to="{ name: 'logout' }" class="border border-red-600 bg-red-500 rounded p-2 flex flex-col justify-center items-center text-white">
              Logout
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Locales from '../../locales.json'

export default {
  name: 'verify-email',
  data:() => ({
    loading: false,
    locales: null
  }),
  computed: {
    lang() {
      return this.$parent.lang
    },
		locale() {
			return this.locales[this.lang]
    },
    user() {
      return this.$parent.auth.user
    }
  },
  methods: {
    async resendEmail() {
      try {
        this.loading = true
        
        let { email } = this

        let { data } = await api.post('api.auth.resend-verification-email', { email })

        notifyS('Email inviata!')

        this.loading = false
      }
      catch(error) {
        callErrors(error)
        this.loading = false
      }
    }
  },
	created() {
    this.locales = Locales
	}
}
</script>
