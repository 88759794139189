<template>
	<div id="bookings-wizard">
		<loading :active.sync="isLoading"></loading>

    <!-- STEPS -->
    <div class="flex flex-wrap my-3 md:mt-8">
      <div class="step-block flex-1 w-full h-10 text-white mx-1 flex justify-center md:justify-start md:px-2 items-center bg-green-500">
        <span class="text-5xl" style="text-shadow:0 -1px 0 #408c1f; position:relative; top:-5px; margin-right:3px">1</span>
        <span class="hidden md:block" v-text="locale.step1"></span>
      </div>
      <div class="step-block flex-1 w-full h-10 text-white mx-1 flex justify-center md:justify-start md:px-2 items-center bg-green-500">
        <span class="text-5xl" style="text-shadow:0 -1px 0 #408c1f; position:relative; top:-5px; margin-right:3px">2</span>
        <span class="hidden md:block" v-text="locale.step2"></span>
      </div>
      <div class="step-block flex-1 w-full h-10 text-white mx-1 flex justify-center md:justify-start md:px-2 items-center bg-green-500">
        <span class="text-5xl" style="text-shadow:0 -1px 0 #408c1f; position:relative; top:-5px; margin-right:3px">3</span>
        <span class="hidden md:block" v-text="locale.step3"></span>
      </div>
      <div class="step-block flex-1 w-full h-10 text-white mx-1 flex justify-center md:justify-start md:px-2 items-center bg-green-500">
        <span class="text-5xl" style="text-shadow:0 -1px 0 #408c1f; position:relative; top:-5px; margin-right:3px">4</span>
        <span class="hidden md:block" v-text="locale.step4"></span>
      </div>
      <div class="step-block flex-1 w-full h-10 text-white mx-1 flex justify-center md:justify-start md:px-2 items-center bg-green-500">
        <span class="text-5xl" style="text-shadow:0 -1px 0 #408c1f; position:relative; top:-5px; margin-right:3px">5</span>
        <span class="hidden md:block" v-text="locale.step5"></span>
      </div>
    </div>


		<!-- STEP FINAL -->
		<div class="container">
			<div class="md:mt-8">
				<div class="flex md:mt-4">
					<div class="w-full text-gray-600 bg-green-100 border border-green-300 text-center text-xl flex flex-col">
						<i class="mt-3 fa fa-check fa-2x text-green-500"></i>
						<p class="mt-10">La transazione ha avuto esito positivo.</p>
						<p class="">Il codice di prenotazione è: <span style="background-color:yellow;font-weight:bold">BCB6U6</span>.</p>
						<p class="">I biglietti sono stati spediti all'indirizzo email <span class="font-bold">camiant@gmail.com</span></p>
						<p class="text-lg">(In base alle tue impostazioni o al tuo gestore di posta elettronica, il messaggio potrebbe essere riconosciuto come SPAM, pertanto ti consigliamo di controllare anche le cartelle "spam" o "junk")</p>
						<p class="mt-4 text-lg">Ti ricordiamo che è possibile presentarsi al check-in con la sola copia elettronica del biglietto.</p>
						<p class="text-lg mb-3">Il check-in apre 30 minuti prima dell'imbarco.</p>
					</div>
				</div>
			</div> 

			<div class="md:mt-8">
				<div class="flex flex-col md:mt-4">
					<div class="mt-10 p-2 bg-green-200 border border-green-200 text-gray-700 rounded-tl-sm rounded-tr-sm">Puoi inviare copia singola del biglietto anche a indirizzi email differenti.</div>
					<table class="w-full text-left table-collapse">
						<thead>
							<tr>
								<th class="text-sm font-semibold text-gray-700 p-2 bg-gray-100">passeggero</th>
								<th class="text-sm font-semibold text-gray-700 p-2 bg-gray-100">data nascita</th>
								<th class="text-sm font-semibold text-gray-700 p-2 bg-gray-100">biglietto</th>
								<th class="text-sm font-semibold text-gray-700 p-2 bg-gray-100">email</th>
								<th class="text-sm font-semibold text-gray-700 p-2 bg-gray-100">e-ticket</th>
							</tr>
						</thead>
						<tbody class="align-baseline">
						 	<tr v-for="pax in paxes" :key="pax.ticketNr">
								<td class="p-2 border-t border-gray-300 font-mono text-sm text-purple-700 whitespace-no-wrap" v-text="pax.fullName" />
								<td class="p-2 border-t border-gray-300 font-mono text-sm text-purple-700 whitespace-no-wrap" v-text="pax.birthDate" />
								<td class="p-2 border-t border-gray-300 font-mono text-sm text-purple-700 whitespace-no-wrap" v-text="pax.ticketNr" />
								<td class="p-2 border-t border-gray-300 font-mono text-sm text-purple-700 whitespace-no-wrap">
									<input type="text" class="border border-gray-400" v-model="pax.email">
								</td>
								<td class="p-2 border-t border-gray-300 font-mono text-sm text-purple-700 whitespace-no-wrap">
									<button :disabled="pax.loading" :class="{ 'cursor-not-allowed': pax.loading }" class="flex flex-row justify-between items-center cursor-pointer bg-green-400 rounded p-1 text-white text-sm" type="button" @click="sendSingleTicket(pax)">
										<i v-if="! pax.loading" class="fa fa-envelope-o mr-2" />
										<i v-if="pax.loading" class="fa fa-spinner fa-spin mr-2" />
										invia
									</button>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div> 
		</div>


		<!-- modal condizioni -->
		<modal name="modal-conditions-it" ref="modal-conditions-it" height="auto" class="w-full" :pivot-y="0.3">
			<div class="bg-white rounded m-4 max-h-full text-center">
				<div class="text-left mb-4" style="background: url(https://alidaunia.it/img/linea.svg) center bottom / contain no-repeat;">
					<img src="https://alidaunia.it/img/logo150px.jpg" style="width:130px">
				</div>
				<div class="mb-8">
					<iframe src="https://booking.alidaunia.it/pdf/docs/conditions-it.htm" style="width:100%; height:400px"/>
				</div>
			</div>
		</modal>


		<!-- modal conditions -->
		<modal name="modal-conditions-en" ref="modal-conditions-en" height="auto" class="w-full" :pivot-y="0.3">
			<div class="bg-white rounded m-4 max-h-full text-center">
				<div class="text-left mb-4" style="background: url(https://alidaunia.it/img/linea.svg) center bottom / contain no-repeat;">
					<img src="https://alidaunia.it/img/logo150px.jpg" style="width:130px">
				</div>
				<div class="mb-8">
					<iframe src="https://booking.alidaunia.it/pdf/docs/conditions-en.htm" style="width:100%; height:400px"/>
				</div>
			</div>
		</modal>



		<!-- modal informativa -->
		<modal name="modal-privacy-it" ref="modal-privacy-it" height="auto" class="w-full" :pivot-y="0.3">
			<div class="bg-white rounded m-4 max-h-full text-center">
				<div class="text-left mb-4" style="background: url(https://alidaunia.it/img/linea.svg) center bottom / contain no-repeat;">
					<img src="https://alidaunia.it/img/logo150px.jpg" style="width:130px">
				</div>
				<div class="mb-8">
					<iframe src="https://booking.alidaunia.it/pdf/docs/privacy-it.htm" style="width:100%; height:400px"/>
				</div>
			</div>
		</modal>


		<!-- modal informativa -->
		<modal name="modal-privacy-en" ref="modal-privacy-en" height="auto" class="w-full" :pivot-y="0.3">
			<div class="bg-white rounded m-4 max-h-full text-center">
				<div class="text-left mb-4" style="background: url(https://alidaunia.it/img/linea.svg) center bottom / contain no-repeat;">
					<img src="https://alidaunia.it/img/logo150px.jpg" style="width:130px">
				</div>
				<div class="mb-8">
					<iframe src="https://booking.alidaunia.it/pdf/docs/privacy-en.htm" style="width:100%; height:400px"/>
				</div>
			</div>
		</modal>

		<!-- modal di redirect manuale verso BancaSella -->
		<!--
		<modal name="modal-redirect" ref="modal-redirect" height="auto" :pivot-y="0.3" class="w-full">
			<div class="bg-white rounded m-4 max-h-full text-center">
				<div class="text-left mb-4" style="background: url(https://alidaunia.it/img/linea.svg) center bottom / contain no-repeat;">
					<img src="https://alidaunia.it/img/logo150px.jpg" style="width:130px">
				</div>
				<div class="mt-8 flex flex-col">
					<p class="border border-gray-400 p-4 mb-4 text-justify leading-loose rounded">
						<span v-if="lang == 'it'">
							Se il browser non ti reindirizza in automatico verso il server di Banca Sella, per favore clicca il pulsante di seguito.
						</span>
						<span v-if="lang == 'en'">
							If the browser doesn't redirect you automatically in a few seconds, please click the button below.
						</span>
					</p>
					<a :href="redirectUrl" class="text-lg text-white bg-blue-800 p-3 rounded no-underline">
						<span v-if="lang == 'it'">Prosegui verso Banca Sella</span>
						<span v-if="lang == 'en'">Continue to Banca Sella</span>
					</a>
				</div>
			</div>
		</modal>
		-->

	</div>
</template>

<script>
// Import component
import Loading from 'vue-loading-overlay'
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css'

export default {
	components: {
		Loading
	},
	props: {
		booking: {
			type: Object
		},
		lang: {
			type: String
		}
	},
	data() {
		return {
			isLoading: false,
			step: 1,
			stepMax: 1,
			user: {
				basic: true,
				userId: 0,
				paxId: 0,
				logged: false,
				gender: '',
				paxCategory: '',
				lastname: '',
				firstname: '',
				birthDate: '',
				document_type: 0,
				document_code: '',
				residence: '',
				email: '',
				telephone: ''
			},
			childAccompanied: false,
			departureFlights: [],
			departureFlightsCheckDateTime: null,
			comebackFlights: [],
			comebackFlightsCheckDateTime: null,
			departureFlightsLoading: true,
			comebackFlightsLoading: true,
			originPlaces: [
				{ id: 1348, txt: 'Foggia' },
				{ id: 1511, txt: 'Tremiti' },
				{ id: 1514, txt: 'Vieste' }
			],
			destinationPlaces: [
				{ id: 1349, txt: 'Foggia' },
				{ id: 1511, txt: 'Tremiti' },
				{ id: 1514, txt: 'Vieste' }
			],
			paxes: [
				{
					fullName: 'AsdoMar',
					birthDate: '01-01-1900',
					ticketNr: 132456,
					email: 'asd@example.com',
					loading: false
				}
			]
		}
	},
	computed: {
		locale() {
			return this.locales[this.lang]
		},
		locales() {
			return {
				it: {
					step1 : 'SELEZIONE TRATTA',
					step2 : 'SELEZIONE VOLI',
					step3 : 'DATI PASSEGGERI',
					step4 : 'ACQUISTO',
					step5 : 'RICEVUTA',

					goStep2: 'Cerca voli',
					backStep1: 'Selezione tratta',
					goStep3: 'Dati passeggeri',
					backStep2: 'Selezione voli',
					goStep4: 'Acquista',
					backStep3: 'Dati passeggeri',
					
					errorOrigDestMissing: 'partenza o destinazione mancanti!',
					errorOrigDestIdentical: 'partenza o destinazione non possono coincidere!',
					errorDepartureDateMissing: 'data di andata mancante!',
					errorComebackDateMissingForDW: 'data di ritorno richiesta per volo andata/ritorno!',
					errorComebackSmallerThanDeparture: 'la data di ritorno dev\'essere uguale/successiva alla data di partenza!',
					errorUnselectableDates: 'date di andata e/o ritorno non selezionabili!',
					errorEmptyPaxes: 'selezionare almeno 1 passeggero!',
					errorUnderageAlone: 'i minori possono viaggiare solo se accompagnati!',
					errorInfantsNum: 'numero neonati non valido!',
					errorDepartureFlightMissing: 'volo di andata non selezionato!',
					errorComebackFlightMissing: 'volo di ritorno non selezionato!',
					errorComebackFlightNotFollowingDeparture: 'selezionare un volo di ritorno successivo a quello di partenza!',
					errorPaxesIncomplete: `
						campi obbligatori:
						<ul>
							<li>cognome</li>
							<li>nome</li>
							<li>sesso</li>
							<li>data di nascita</li>
						</ul>
						le seguenti anagrafiche passeggeri sono incomplete:<br>`,
					errorConditions: 'è obbligatorio accettare le condizioni contrattuali!',
										
					roundTrip: 'Andata e ritorno',
					oneWay: 'Sola andata',
					origin: 'Partenza',
					destination: 'Arrivo',
					takeoffAbbr: 'Part.',
					landingAbbr: 'Arr.',
					morning: 'Mattina',
					afternoon: 'Pomeriggio',
					departure: 'Andata',
					comeback: 'Ritorno',
					adults: 'Adulti',
					adult: 'Adulto',
					men: 'Uomini',
					man: 'Uomo',
					women: 'Donne',
					woman: 'Donna',
					children: 'Bambini',
					child: 'Bambino',
					childrenInfo: '2-11 anni',
					infants: 'Neonati',
					infant: 'Neonato',
					infantsInfo1: 'in braccio',
					infantsInfo2: 'sotto i 2 anni',
					flightType: 'Volo',
					flightNum: 'Cod.',
					date: 'Data',
					time: 'Ore',
					departureFlight: 'Volo di andata',
					comebackFlight: 'Volo di ritorno',
					flight: 'Volo',
					aircraft: 'A/M',
					route: 'Tratta',
					flightAvailabilityDateTime: 'disponibilità al',
					noFlightsFound: 'Nessun volo trovato per il numero di passeggeri richiesto',

					passenger: 'Passeggero',
					fare: 'Tariffa',
					paxCategory: 'Fascia',
					ticket: 'Biglietto',
					taxes: 'Tasse',
					landingTax: 'Contributo<br>sbarco',
					total: 'Totale',
					lastname: 'Cognome',
					firstname: 'Nome',
					gender: 'Sesso',
					birthDate: 'Data di nascita',
					document: 'Documento',
					residence: 'Indirizzo',
					telephone: 'Telefono',
					contactEmail: 'Email per eventuali contatti',
					contactTelephone: 'Telefono per eventuali contatti',
					contactsInfo: 'I seguenti recapiti saranno usati per eventuali comunicazioni',
					conditions: 'Condizioni di contratto',
					privacy: 'Informativa privacy',
					accept: 'Accetto'
				},
				en: {
					step1 : 'ROUTE SELECTION',
					step2 : 'FLIGHT SELECTION',
					step3 : 'PASSENGERS INFO',
					step4 : 'PURCHASE',
					step5 : 'CONFIRMATION',

					goStep2: 'Find flight',
					backStep1: 'Route selection',
					goStep3: 'Passengers info',
					backStep2: 'Flight selection',
					goStep4: 'Purchase',
					backStep3: 'Passengers info',
					goStep5: 'Complete Checkout',

					errorOrigDestMissing: 'origin or destination missing!',
					errorOrigDestIdentical: 'origin and destination cannot be identical!',
					errorDepartureDateMissing: 'departure date missing!',
					errorComebackDateMissingForDW: 'comeback date is mandatory for round trip!',
					errorComebackSmallerThanDeparture: 'comeback date must be identical or follow the departure one!',
					errorUnselectableDates: 'departure/comeback dates are not selectable',
					errorEmptyPaxes: 'at least one pax is mandatory!',
					errorUnderageAlone: 'underage can fly only with (at least) an adult!',
					errorInfantsNum: 'infants num is not valid!',
					errorDepartureFlightMissing: 'departure flight is missing!',
					errorComebackFlightMissing: 'comeback flight is missing!',
					errorComebackFlightNotFollowingDeparture: 'select a comeback flight following departure one!',
					errorPaxesIncomplete: `
						mandatory fields:
						<ul>
							<li>lastname</li>
							<li>firstname</li>
							<li>gender</li>
							<li>birthdate</li>
						</ul>
						following paxes info are incomplete:<br>`,
					errorConditions: 'you must accept conditions!',

					roundTrip: 'Round trip',
					oneWay: 'One way',
					origin: 'Origin',
					destination: 'Destination',
					takeoffAbbr: 'TakeOff',
					landingAbbr: 'Landing',
					morning: 'Morning',
					afternoon: 'Afternoon',
					departure: 'Departure',
					comeback: 'Comeback',
					adults: 'Adults',
					adult: 'Adult',
					men: 'Men',
					man: 'Man',
					women: 'Women',
					woman: 'Woman',
					children: 'Children',
					child: 'Child',
					childrenInfo: '2-11 years',
					infants: 'Infants',
					infant: 'Infant',
					infantsInfo1: 'held in arms',
					infantsInfo2: 'under 2 years',
					flightType: 'Flight Type',
					flightNum: 'Flight',
					date: 'Date',
					time: 'Time',
					departureFlight: 'Departure flight',
					comebackFlight: 'Comeback flight',
					flight: 'Flight',
					aircraft: 'Aircraft',
					route: 'Route',
					flightAvailabilityDateTime: 'data updated to',
					noFlightsFound: 'No flights found for selected number of passengers',

					passenger: 'Passenger',
					fare: 'Fare',
					paxCategory: 'Pax Cat.',
					ticket: 'Ticket',
					taxes: 'Additional fees',
					landingTax: 'Landing Tax',
					total: 'Total',
					lastname: 'Lastname',
					firstname: 'Firstname',
					gender: 'Gender',
					birthDate: 'Birthdate',
					document: 'Document',
					residence: 'Address',
					telephone: 'Telephone',
					// contactsInfo: 'Following Email/Telephone will be used to contact you in case of need:',
					contactEmail: 'Email for contacts',
					contactTelephone: 'Telephone for contacts',
					conditions: 'Conditions',
					privacy: 'Info Privacy',
					accept: 'Accept'
				}
			}
		}
	},
	methods: {
		async sendSingleTicket(pax) {
			pax.loading = true
			let { ticketNr, email } = pax
			let { data } = await axios.get('/api/send-single-ticket/' + btoa(JSON.stringify({ ticketNr, email })))
			if (data) {
				if (data && data.status == 'success') {
					Notify.success(`Ticket ${ticketNr} sent!`)
				}
				else {
					Notify.error(data.error)
				}
			}
			else {
				Notify.error('Sending failed...')
			}
			pax.loading = false
		}
	},
	created() {
		if (this.tokenLoaded) {
			this.token = this.tokenLoaded
			this.user = JSON.parse(this.userLoaded)
		}
	}
}
</script>

<style>
div.iziToast-color-red {
	background: orangered !important;
}
div.iziToast-color-red strong.iziToast-title,
div.iziToast-color-red p.iziToast-message {
	color: #fff !important;
}
</style>
