<template>
	<section class="w-full max-w-5xl mx-auto bg-white min-h-screen">
		<div style="font-size: 13px; text-align: center; font-style: italic; color: rgb(66, 139, 202); margin-top: 10px; background-color: lightgoldenrodyellow; padding: 4px; border: 1px solid orange;">
			In ottemperanza alle disposizioni delle autorità competenti, in materia di Coronavirus, si avvisa che <strong>non è possibile acquistare biglietti nelle 12 ore precedenti il volo</strong>.
    </div>
		<div class="relative w-full flex flex-col">
			<vue-loading :loading="loading" />
			<div class="flex flex-col">
        <div class="flex">
          <div class="w-full flex my-2 h-8">
            <span class="flex-1 self-center border-t border-gray-300"></span>
            <span class="text-xl font-semibold text-blue-900 text-center px-3">
              {{ locale.BookAFlight }}
            </span>
            <span class="flex-1 self-center border-t border-gray-300"></span>
          </div>
        </div>

				<div v-if="failedBookings" class="flex">
          <div class="w-full flex justify-center my-2 py-3 rounded border border-orange-500 bg-orange-200">
            <div class="w-full text-xs md:text-base font-semibold text-gray-800 px-3 flex justify-between items-center">             
							<span v-if="failedBookings == 1">{{ failedBookings }} {{ locale.failedBookingAvailable }}</span>
							<span v-if="failedBookings >= 2">{{ failedBookings }} {{ locale.failedBookingsAvailable }}</span>
							<router-link :to="{ name: 'bookings-failed' }" class="bg-blue-600 text-center text-white py-1 px-2 rounded">
								{{ locale.failedBookingsAvailableLink }}
							</router-link>
            </div>
          </div>
        </div>

				<div class="relative flex flex-col pt-0 md:pt-3 p-3 border rounded">

					<loading :active.sync="isLoading" />

					<div v-if="bloccaPostiPannello" class="mt-10 p-5 rounded bg-green-200">
						<div class="text-lg">{{ bloccaPostiRisposta }}</div>
						<div class="text-right">
							<button class="mt-5 rounded bg-blue-500 text-white px-3 py-1" type="button" @click="bloccaPostiPannello = false">
								Chiudi
							</button>
						</div>
					</div>

					<table v-if="step == 0" class="w-full mt-10">
						<tr class="border">
							<th class="text-left">Cod.Pren.</th>
							<th width="20%" class="text-left">Date voli</th>
							<th class="text-left">Da</th>
							<th class="text-left">A</th>
							<th class="text-left">TotPax</th>
							<th width="20%" class="text-left">Scadenza</th>
							<th width="20%" class="text-left">Motivazione</th>
							<th class="text-left">Azioni</th>
							<th class="text-left">&nbsp;</th>
						</tr>
						<tr class="border" v-for="prenBloccata in prenBloccate" :key="prenBloccata.CodPrenotazone">
							<td class="p-1">
								<button type="button" class="text-white rounded py-1 px-2 w-full" :class="{ 'bg-green-500' : prenBloccataSel == prenBloccata.CodPrenotazione, 'bg-blue-500': prenBloccataSel != prenBloccata.CodPrenotazione }" @click="selPrenBloccata(prenBloccata)">
									{{ prenBloccata.CodPrenotazione }}
								</button>
							</td>
							<td class="p-1" v-text="prenBloccata.Date"></td>
							<td class="p-1" v-text="prenBloccata.Provenienza"></td>
							<td class="p-1" v-text="prenBloccata.Destinazione"></td>
							<td class="p-1" v-text="prenBloccata.TotPax"></td>
							<td class="p-1" v-text="prenBloccata.OraScadenza"></td>
							<td class="p-1" v-text="prenBloccata.BloccaPostiNota"></td>
							<td class="p-1 flex">
								<button type="button" class="text-white rounded py-1 px-2 w-full bg-red-300" @click="cancellaPrenBloccata(prenBloccata)">
									cancella
								</button>
							</td>
							<td class="p-1">
								<fa class="s-info-circle" :title="prenBloccata.NomeUtente" />
							</td>
						</tr>
						<tr v-if="! prenBloccate.length">
							<td colspan="9" class="p-1 text-center">
								Nessun posto bloccato
							</td>
						</tr>
					</table>		

					<!-- STEPS -->
					<div v-if="step != 401 && step > 0" class="flex flex-wrap my-3 md:mt-2">
						<div class="step-block flex-1 w-full h-10 text-white mr-1 flex justify-center md:justify-start md:px-2 items-center" :class="{ 'bg-blue-500': step == 1, 'bg-green-500': step > 1 }">
							<span class="text-5xl font-semibold" style="text-shadow:0 -1px 0 #408c1f; position:relative; top:-5px; margin-right:3px">1</span>
							<span class="hidden md:block" v-text="locale.step1"></span>
						</div>
						<div class="step-block flex-1 w-full h-10 text-white mr-1 flex justify-center md:justify-start md:px-2 items-center" :class="{ 'bg-blue-200': step < 2, 'bg-blue-500': step == 2, 'bg-green-500': step > 2 }" :disabled="stepMax < 2">
							<span class="text-5xl font-semibold" style="text-shadow:0 -1px 0 #408c1f; position:relative; top:-5px; margin-right:3px">2</span>
							<span class="hidden md:block" v-text="locale.step2"></span>
						</div>
						<div class="step-block flex-1 w-full h-10 text-white mr-1 flex justify-center md:justify-start md:px-2 items-center" :class="{ 'bg-blue-200': step < 3, 'bg-blue-500': step == 3, 'bg-green-500': step > 3 }" :disabled="stepMax < 3">
							<span class="text-5xl font-semibold" style="text-shadow:0 -1px 0 #408c1f; position:relative; top:-5px; margin-right:3px">3</span>
							<span class="hidden md:block" v-text="locale.step3"></span>
						</div>
						<div class="step-block flex-1 w-full h-10 text-white mr-1 flex justify-center md:justify-start md:px-2 items-center" :class="{ 'bg-blue-200': step < 4, 'bg-blue-500': step == 4, 'bg-green-500': step > 4 }" :disabled="stepMax < 4">
							<span class="text-5xl font-semibold" style="text-shadow:0 -1px 0 #408c1f; position:relative; top:-5px; margin-right:3px">4</span>
							<span class="hidden md:block" v-text="locale.step4"></span>
						</div>
						<div class="step-block flex-1 w-full h-10 text-white flex justify-center md:justify-start md:px-2 items-center bg-blue-200">
							<span class="text-5xl font-semibold" style="text-shadow:0 -1px 0 #408c1f; position:relative; top:-5px; margin-right:3px">5</span>
							<span class="hidden md:block" v-text="locale.step5"></span>
						</div>
					</div>

					<!-- FLIGHT BOX / begin -->
					<div v-if="step >= 2 && step != 401" class="flex mb-6 md:mt-8">
						<table class="w-full border border-gray">
							<thead>
								<tr>
									<th class="text-left py-1 px-3 bg-gray-200 font-sans font-medium text-xs md:text-sm text-gray-dark border-b border-gray-400">
										&nbsp;
									</th>
									<th class="text-left py-1 px-3 bg-gray-200 font-sans font-medium text-xs md:text-sm text-gray-dark border-b border-gray-400">
										{{ locale.bookingFlightNum }}
									</th>
									<th class="text-left py-1 px-3 bg-gray-200 font-sans font-medium text-xs md:text-sm text-gray-dark border-b border-gray-400">
										{{ locale.bookingDate }}
									</th>
									<th class="text-left py-1 px-3 bg-gray-200 font-sans font-medium text-xs md:text-sm text-gray-dark border-b border-gray-400">
										{{ locale.bookingTime }} 
									</th>
									<th class="text-left py-1 px-3 bg-gray-200 font-sans font-medium text-xs md:text-sm text-gray-dark border-b border-gray-400">
										<span class="hidden md:block">{{ locale.bookingOrigin }}</span>
										<span class="block md:hidden">{{ locale.bookingOrigin.substr(0,3) }}.</span>
									</th>
									<th class="text-left py-1 px-3 bg-gray-200 font-sans font-medium text-xs md:text-sm text-gray-dark border-b border-gray-400">
										<span class="hidden md:block">{{ locale.bookingDestination }}</span>
										<span class="block md:hidden">{{ locale.bookingDestination.substr(0,3) }}.</span>
									</th>
									<th class="text-left py-1 px-3 bg-gray-200 font-sans font-medium text-xs text-gray-dark border-b border-gray-400">
										Pax
									</th>
								</tr>
							</thead>
							<tbody>
								<tr class="border-gray font-semibold text-blue-800">
									<td class="py-1 px-2 text-xs md:text-sm border">
										<span class="hidden md:block">{{ locale.bookingDeparture }}</span>
										<span class="block md:hidden">{{ locale.bookingDeparture.substr(0, 1) }} </span>
									</td>
									<td class="py-1 px-2 text-xs md:text-sm border">
										<span class="hidden md:block">{{ departureFlight ? departureFlight.flightCode : '-' }}</span>
										<span class="block md:hidden">{{ departureFlight ? departureFlight.flightCode.replace('LID', '') : '-' }} </span>
									</td>
									<td class="py-1 px-2 text-xs md:text-sm border">
										{{ departureFlight ? departureFlight.takeOffDate : '-' }}
									</td>
									<td class="py-1 px-2 text-xs md:text-sm border">
										{{ departureFlight ? departureFlight.takeOffTime : '-' }}
									</td>
									<td class="py-1 px-2 text-xs md:text-sm text-center md:text-left border">
										<span class="hidden md:block">{{ origin }}</span>
										<span class="block md:hidden">{{ originAbbr }}</span>
									</td>
									<td class="py-1 px-2 text-xs md:text-sm text-center md:text-left border">
										<span class="hidden md:block">{{ destination }}</span>
										<span class="block md:hidden">{{ destinationAbbr }}</span>
									</td>
									<td rowspan="2" class="align-middle text-center py-1 px-2 text-xs md:text-sm text-blue-800 border">
										{{ totPax }}
									</td>
								</tr>
								<tr v-if="form.flightType == 'DW'" class="border-gray font-semibold text-blue-800">
									<td class="py-1 px-2 text-xs md:text-sm border">
										<span class="hidden md:block">{{ locale.bookingComeback }}</span>
										<span class="block md:hidden">{{ locale.bookingComeback.substr(0, 1) }} </span>
									</td>
									<td class="py-1 px-2 text-xs md:text-sm border">
										<span class="hidden md:block">{{ comebackFlight ? comebackFlight.flightCode : '-' }}</span>
										<span class="block md:hidden">{{ comebackFlight ? comebackFlight.flightCode.replace('LID', '') : '-' }} </span>
									</td>
									<td class="py-1 px-2 text-xs md:text-sm border">
										{{ comebackFlight ? comebackFlight.takeOffDate : '-' }}
									</td>
									<td class="py-1 px-2 text-xs md:text-sm border">
										{{ comebackFlight ? comebackFlight.takeOffTime : '-' }}
									</td>
									<td class="py-1 px-2 text-xs md:text-sm text-center md:text-left border">
										<span class="hidden md:block">{{ destination }}</span>
										<span class="block md:hidden">{{ destinationAbbr }}</span>
									</td>
									<td class="py-1 px-2 text-xs md:text-sm text-center md:text-left border">
										<span class="hidden md:block">{{ origin }}</span>
										<span class="block md:hidden">{{ originAbbr }}</span>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<!-- FLIGHT BOX / end -->

					<!-- PRICES BOX / begin -->
					<div v-if="step >= 3 && step != 401" class="flex flex-col mb-4">
						<!--
						<div :id="'pax-costs-' + pax._uuid" class="hidden" v-for="pax in form.paxes">
						-->
						<tippy :to="`pax-costs-${pax._uuid}`" v-for="pax in form.paxes" :key="pax._uuid" class="hidden">
							<table class="pax-costs">
								<thead>
									<tr>
										<th width="33%">{{ locale.ticket }}</th>
										<th width="33%">{{ locale.taxes }}</th>
										<th width="33%" v-html="locale.landingTax"></th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>€ {{ pax.ticket }}</td>
										<td>€ {{ pax.tax }}</td>
										<td>€ {{ pax.landingTax }}</td>
									</tr>
								</tbody>
							</table>
						</tippy>

						<table class="text-left w-full border border-gray">
							<thead>
								<tr>
									<th class="text-left py-1 px-3 bg-gray-200 font-sans font-medium text-xs text-gray-dark border-b border-gray-400" v-text="locale.bookingPassenger"></th>
									<th class="text-left py-1 px-3 bg-gray-200 font-sans font-medium text-xs text-gray-dark border-b border-gray-400" v-text="locale.bookingFare"></th>
									<th class="text-left py-1 px-3 bg-gray-200 font-sans font-medium text-xs text-gray-dark border-b border-gray-400" v-text="locale.bookingPaxCategory"></th>
									<th class="hidden md:table-cell text-left py-1 px-3 bg-gray-200 font-sans font-medium text-xs text-gray-dark border-b border-gray-400" v-text="locale.bookingTicket"></th>
									<th class="hidden md:table-cell text-left py-1 px-3 bg-gray-200 font-sans font-medium text-xs text-gray-dark border-b border-gray-400" v-text="locale.bookingTaxes"></th>
									<th class="hidden md:table-cell text-left py-1 px-3 bg-gray-200 font-sans font-medium text-xs text-gray-dark border-b border-gray-400"><span v-html="locale.bookingLandingTax"></span> *</th>
									<th class="text-left py-1 px-3 bg-gray-200 font-sans font-medium text-xs text-gray-dark border-b border-gray-400">{{ locale.bookingTotal }} <span class="md:hidden">*</span></th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="pax in form.paxes">
									<td class="py-1 px-2 text-sm text-blue-800 border">
										<button v-if="! pax.lastname && prenBloccataSel" type="button" class="bg-red-600 text-white text-xs px-2 py-0 rounded" @click="rempax(pax)">rimuovi</button>
										{{ pax.lastname }}
										{{ pax.firstname }}
									</td>
									<td class="py-1 px-2 text-sm text-blue-800 border" v-text="pax.fare"></td>
									<td class="py-1 px-2 text-sm text-blue-800 border" v-text="pax.category != 'an' ? pax.tktCategory : 'Animale domestico'"></td>
									<td class="hidden md:table-cell py-1 px-2 text-sm text-blue-800 border" style="text-align:right">
										<span :style="{
												'text-decoration': pax.dsc ? 'line-through' : '',
												'color': pax.dsc ? 'dimgrey' : '' 
											}">
											€ <span v-text="formattedPrice(pax.ticket_nodsc)"></span>
										</span>
										<span v-if="pax.dsc">
											<span class="float-left" style="background:yellow">-{{ pax.dsc }}%</span>
											<br>
											€ <span v-text="formattedPrice(pax.ticket)"></span>
										</span>							
									</td>
									<!-- <td class="hidden md:table-cell py-1 px-2 text-sm text-blue-800 border" v-if="! user.logged" v-text="pax.dsc" style="text-align:right"></td> -->
									<td class="hidden md:table-cell py-1 px-2 text-sm text-blue-800 border" style="text-align:right">
										€ <span v-text="formattedPrice(pax.tax)"></span>
									</td>
									<td class="hidden md:table-cell py-1 px-2 text-sm text-blue-800 border" style="text-align:right">
										€ <span v-text="formattedPrice(pax.landingTax)"></span>
									</td>
									<td class="py-1 px-2 text-sm text-blue-800 border text-right font-bold" :name="`pax-costs-${pax._uuid}`">
										<span class="flex justify-end" :style="{
												'text-decoration': pax.dsc ? 'line-through' : '',
												'color': pax.dsc ? 'dimgrey' : '' 
											}">
											€&nbsp;<span v-text="formattedPrice(pax.total_nodsc)"></span>
											&nbsp;
											<fa class="s-info-circle" />
										</span>
										<span v-if="pax.dsc" class="text-right">
											<!--
											<span style="background:yellow">-{{ pax.dsc }}%</span>
											<br>
											-->
											€ <span v-text="formattedPrice(pax.total)"></span>
										</span>
									</td>
								</tr>
								<tr>
									<td style="background-color:rgba(162, 216, 97, 0.38);" colspan="3">
										&nbsp;
									</td>
									<td class="hidden md:table-cell py-1 px-2 text-sm text-blue-800 border" style="text-align:right;background-color:rgba(162, 216, 97, 0.38);font-weight:bold;color:darkgreen">
										<span :style="{
												'text-decoration': form.coupon.perc ? 'line-through' : '',
												'color': form.coupon.perc ? 'dimgrey' : '' 
											}">
											€ <span v-text="formattedPrice(form.totals.tickets)"></span>
										</span>
										<span v-if="form.coupon.perc">
											<br>
											€ <span v-text="formattedPrice(form.totals.ticketsDiscounted)"></span>
										</span>
									</td>
									<!-- <td class="hidden md:table-cell py-1 px-2 text-sm text-blue-800 border" style="text-align:right;background-color:rgba(162, 216, 97, 0.38);font-weight:bold;color:darkgreen" v-if="! user.logged">&nbsp;</td> -->
									<td class="hidden md:table-cell py-1 px-2 text-sm text-blue-800 border" style="text-align:right;background-color:rgba(162, 216, 97, 0.38);font-weight:bold;color:darkgreen">
										€ <span v-text="formattedPrice(form.totals.taxes)"></span>
									</td>
									<td class="hidden md:table-cell py-1 px-2 text-sm text-blue-800 border" style="text-align:right;background-color:rgba(162, 216, 97, 0.38);font-weight:bold;color:darkgreen">
										€ <span v-text="formattedPrice(form.totals.landingTaxes)"></span>
									</td>
									<td class="py-1 px-2 text-sm font-bold text-blue-800 border" style="text-align:right;background-color:darkgreen;color:#fff">
										<span :style="{
												'text-decoration': form.coupon.perc ? 'line-through' : '',
												'color': form.coupon.perc ? 'grey' : '' 
											}">
											€ <span v-text="formattedPrice(form.totals.grandTotal)"></span>
										</span>
										<span v-if="form.coupon.perc">
											<br>
											€ <span v-text="formattedPrice(form.totals.grandTotalDiscounted)"></span>
										</span>
									</td>
								</tr>
							</tbody>
						</table>

						<div class="w-full text-sm text-gray-dark">
							<small v-if="lang == 'it'">
								* Contributo di sbarco introdotto dal Consiglio Comunale delle Isole Tremiti con deliberazione N.16 del 30/04/16 e successivamente modificato con deliberazione N.9 del 25/03/17.
								<span class="md:hidden">premere i per il dettaglio.</span>
							</small>
							<small v-if="lang == 'en'">
								* Contribution of Landing introduced by the Municipal Council of the Tremiti Islands with resolution Nr.16 of 30/04/16 and subsequently amended with resolution Nr.9 of 25/03/17.
								<span class="md:hidden">press i for details.</span>
							</small>
						</div>
					</div>
					<!-- PRICES BOX / end -->

					<!-- STEP 1 / begin -->
					<div v-if="step == 1" id="step-1" class="md:mt-8">
						<div class="flex">
							<div data-cy="flight-type-DW-container" class="w-1/2 md:w-1/6 flex justify-begin text-gray-700 py-2 pr-2">
								<vue-radio label="DW" v-model="form.flightType" color="green" value="DW" :label="locale.bookingRoundTrip"></vue-radio>
							</div>
							<div data-cy="flight-type-OW-container" class="w-1/2 md:w-1/6 flex justify-begin text-gray-700 py-2 pl-2">
								<vue-radio label="OW" v-model="form.flightType" color="green" value="OW" :label="locale.bookingOneWay"></vue-radio>
							</div>
						</div>
						<div class="flex md:mt-4">
							<div class="w-1/2 md:flex-1 md:pr-8 text-gray-700 py-2 pr-2">
								<label class="block text-gray-700 text-sm font-bold">{{ locale.bookingOrigin }}</label>
								<div class="inline-block relative w-full">
									<select v-model="form.originId" data-cy="origin" class="block bg-white appearance-none w-full border border-gray-400 hover:border-gray-500 px-2 py-1 pr-8 rounded">
										<option :value="0">...</option>
										<option v-for="place in originPlaces" :key="place.id" :value="place.id" v-text="place.txt"></option>
									</select>
									<div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
										<svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
									</div>
								</div>
							</div>
							<div class="w-1/2 md:flex-1 md:pr-8 text-gray-700 py-2 pl-2">
								<label class="block text-gray-700 text-sm font-bold">{{ locale.bookingDestination }}</label>
								<div class="inline-block relative w-full">
									<select v-model="form.destinationId" data-cy="destination" class="block bg-white appearance-none w-full border border-gray-400 hover:border-gray-500 px-2 py-1 pr-8 rounded" required="required">
										<option :value="0">...</option>
										<option v-for="place in destinationPlaces" :key="place.id" :value="place.id" v-text="place.txt"></option>
									</select>
									<div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
										<svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
									</div>
								</div>
							</div>
							<div class="hidden md:flex relative flex-1 justify-begin text-gray-700 bg-white pt-2" style="width:320px; min-width:320px">
								<div class="relative" style="width:160px">
									<img src="/img/tratte/mappa-capitanata.png" class="absolute">
									<img src="/img/tratte/fg-vi.png" class="absolute" v-if="maps.origin['1348_1514']">
									<img src="/img/tratte/fg-pe.png" class="absolute" v-if="maps.origin['1348_1529']">
									<img src="/img/tratte/fg-tr.png" class="absolute" v-if="maps.origin['1348_1511']">
									<img src="/img/tratte/fg-fg.png" class="absolute" v-if="maps.origin['1348_1349']">
									<img src="/img/tratte/tr-pe.png" class="absolute" v-if="maps.origin['1511_1529']">
									<img src="/img/tratte/tr-vi.png" class="absolute" v-if="maps.origin['1511_1514']">
									<img src="/img/tratte/tr-fg.png" class="absolute" v-if="maps.origin['1511_1349']">
									<img src="/img/tratte/vi-pe.png" class="absolute" v-if="maps.origin['1514_1529']">
									<img src="/img/tratte/vi-tr.png" class="absolute" v-if="maps.origin['1514_1511']">
									<img src="/img/tratte/vi-fg.png" class="absolute" v-if="maps.origin['1514_1349']">
									<img src="/img/tratte/pe-tr.png" class="absolute" v-if="maps.origin['1529_1511']">
									<img src="/img/tratte/pe-vi.png" class="absolute" v-if="maps.origin['1529_1514']">
									<img src="/img/tratte/pe-fg.png" class="absolute" v-if="maps.origin['1529_1349']">
									<p class="absolute text-xs" style="top:145px">
										<span style="color:#FFFF00;font-size:36px;vertical-align:-20%">&bull;</span> {{ locale.bookingMorning }}
										<span style="color:#F60;font-size:36px;vertical-align:-20%">&bull;</span> {{ locale.bookingAfternoon }}
									</p>
								</div>
								<div v-if="form.flightType == 'DW'" class="relative" style="width:160px">
									<img src="/img/tratte/mappa-capitanata.png" class="absolute">
									<img src="/img/tratte/fg-vi.png" class="absolute" v-if="maps.destination['1348_1514']">
									<img src="/img/tratte/fg-pe.png" class="absolute" v-if="maps.destination['1348_1529']">
									<img src="/img/tratte/fg-tr.png" class="absolute" v-if="maps.destination['1348_1511']">
									<img src="/img/tratte/fg-fg.png" class="absolute" v-if="maps.destination['1348_1349']">
									<img src="/img/tratte/tr-pe.png" class="absolute" v-if="maps.destination['1511_1529']">
									<img src="/img/tratte/tr-vi.png" class="absolute" v-if="maps.destination['1511_1514']">
									<img src="/img/tratte/tr-fg.png" class="absolute" v-if="maps.destination['1511_1349']">
									<img src="/img/tratte/vi-pe.png" class="absolute" v-if="maps.destination['1514_1529']">
									<img src="/img/tratte/vi-tr.png" class="absolute" v-if="maps.destination['1514_1511']">
									<img src="/img/tratte/vi-fg.png" class="absolute" v-if="maps.destination['1514_1349']">
									<img src="/img/tratte/pe-tr.png" class="absolute" v-if="maps.destination['1529_1511']">
									<img src="/img/tratte/pe-vi.png" class="absolute" v-if="maps.destination['1529_1514']">
									<img src="/img/tratte/pe-fg.png" class="absolute" v-if="maps.destination['1529_1349']">
								</div>
							</div>
						</div>
						<div class="flex md:mt-4">
							<div data-cy="departure-date-container" class="w-1/2 md:flex-1 text-gray-700 py-2 pr-2 md:pr-8">
								<label class="block text-gray-700 text-sm font-bold mb-2">{{ locale.bookingDeparture }}</label>
								<vue-datepicker v-model="form.departureDate" />
							</div>
							<div data-cy="comeback-date-container" class="w-1/2 md:flex-1 text-gray-700 py-2 pl-2 md:pr-8">
								<div v-if="form.flightType == 'DW'"> 
									<label class="block text-gray-700 text-sm font-bold mb-2">{{ locale.bookingComeback }}</label>
									<vue-datepicker v-model="form.comebackDate" />
								</div>
							</div>
							<div class="hidden md:flex flex-1" style="width:320px; min-width:320px">
								&nbsp;
							</div>
						</div>
						<div class="flex md:mt-4">
							<div class="w-1/2 md:w-1/6 flex flex-col items-start justify-begin text-gray-700 py-2 pr-2">
								<label class="block text-gray-700 text-sm font-bold mb-2 select-none">{{ locale.bookingAdults }} <small>{{ locale.bookingMen }}</small></label>
								<div class="flex w-full h-8">
									<span @click="modifyPaxNum('m', '-')" class="w-1/3 cursor-pointer flex justify-center items-center bg-blue-400 rounded rounded-r-none border border-r-0 border-gray-400 whitespace-no-wrap text-white text-sm">
										<fa class="s-minus" />
									</span>
									<span v-text="men" class="select-none w-2/3 text-center flex-shrink flex-grow flex-auto w-px border h-auto border-gray-400 relative flex items-center justify-center"></span>
									<span @click="modifyPaxNum('m', '+')" class="w-1/3 cursor-pointer flex justify-center items-center bg-blue-400 rounded rounded-l-none border border-l-0 border-gray-400 whitespace-no-wrap text-white text-sm">
										<fa class="s-plus" />
									</span>
								</div>
							</div>
							<div class="w-1/2 md:w-1/6 flex flex-col items-end justify-start text-gray-700 py-2 pl-2">
								<div class="w-full">
									<label class="block text-gray-700 text-sm font-bold mb-2 select-none">{{ locale.bookingAdults }} <small>{{ locale.bookingWomen }}</small></label>
									<div class="flex w-full h-8">
										<span @click="modifyPaxNum('w', '-')" class="w-1/3 cursor-pointer flex justify-center items-center bg-blue-400 rounded rounded-r-none border border-r-0 border-gray-400 whitespace-no-wrap text-white text-sm">
											<fa class="s-minus" />
										</span>
										<span v-text="women" class="select-none w-2/3 text-center flex-shrink flex-grow flex-auto w-px border h-auto border-gray-400 relative flex items-center justify-center"></span>
										<span @click="modifyPaxNum('w', '+')" class="w-1/3 cursor-pointer flex justify-center items-center bg-blue-400 rounded rounded-l-none border border-l-0 border-gray-400 whitespace-no-wrap text-white text-sm">
											<fa class="s-plus" />
										</span>
									</div>
								</div>
							</div>
						</div>
						<div class="flex flex-wrap md:mt-4">
							<div class="w-1/2 md:w-1/6 flex flex-col items-start justify-start text-gray-700 py-2 pr-2">
								<label class="block text-gray-700 text-sm font-bold mb-2 select-none">{{ locale.bookingChildren }} <br> <small>{{ locale.bookingChildrenInfo }}</small></label>
								<div class="flex w-full h-8">
									<span @click="modifyPaxNum('c', '-')" class="w-1/3 cursor-pointer flex justify-center items-center bg-blue-400 rounded rounded-r-none border border-r-0 border-gray-400 whitespace-no-wrap text-white text-sm">
										<fa class="s-minus" />
									</span>
									<span v-text="children" class="select-none w-2/3 text-center flex-shrink flex-grow flex-auto w-px border h-auto border-gray-400 relative flex items-center justify-center"></span>
									<span @click="modifyPaxNum('c', '+')" class="w-1/3 cursor-pointer flex justify-center items-center bg-blue-400 rounded rounded-l-none border border-l-0 border-gray-400 whitespace-no-wrap text-white text-sm">
										<fa class="s-plus" />
									</span>
								</div>
							</div>
							<div class="w-1/2 md:w-1/6 flex flex-col items-end justify-between text-gray-700 py-2 pl-2">
								<div class="w-full">
									<label class="block text-gray-700 text-sm font-bold mb-2 select-none">{{ locale.bookingInfants }} <small>( {{ locale.bookingInfantsInfo1 }} ) <br> {{ locale.bookingInfantsInfo2 }}</small></label>
									<div class="flex w-full h-8">
										<span @click="modifyPaxNum('i', '-')" class="w-1/3 cursor-pointer flex justify-center items-center bg-blue-400 rounded rounded-r-none border border-r-0 border-gray-400 whitespace-no-wrap text-white text-sm">
											<fa class="s-minus" />
										</span>
										<span v-text="infants" class="select-none w-2/3 text-center flex-shrink flex-grow flex-auto w-px border h-auto border-gray-400 relative flex items-center justify-center"></span>
										<span @click="modifyPaxNum('i', '+')" class="w-1/3 cursor-pointer flex justify-center items-center bg-blue-400 rounded rounded-l-none border border-l-0 border-gray-400 whitespace-no-wrap text-white text-sm">
											<fa class="s-plus" />
										</span>
									</div>
								</div>
							</div>
							<div class="hidden md:block p-2 m-2 select-none">
								&nbsp;
							</div>
							<div class="hidden md:block md:w-1/8 text-gray-700 p-2 m-2 md:m-0 md:ml-8">
								<label class="block text-gray-700 text-sm font-bold mb-2 select-none">Tot Pax<br><br></label>
								<span v-text="totPax" type="text" class="select-none block text-center bg-white border h-8 border-gray-400 flex items-center justify-center"></span>
							</div>
							<div class="block md:hidden md:mt-4 mb-3">
								<div v-if="checkRoles(['Admin', 'Prenotazioni', 'PrenotaFull'])" class="w-full select-none">
									<vue-checkbox v-model="flagUnattendedChild" />
									<label class="ml-1 italic select-none">prenotazione per "minore non accompagnato"</label>
								</div>
								<div v-if="checkRoles(['Admin', 'Prenotazioni', 'PrenotaFull'])" class="w-full select-none">
									<vue-checkbox v-model="animal" />
									<label class="ml-1 italic select-none">animale domestico <fa class="s-info-circle" v-tippy content="occupa un posto. vedi Tariffe" /></label>
								</div>
							</div>
							<div v-if="flagUnattendedChild" class="border flex-1 md:ml-5 flex flex-col">
								<div class="py-1 bg-orange-600 text-white text-center relative">
									<span class="font-bold">ATTENZIONE:</span> minore non accompagnato
								</div>
								<div class="flex justify-center rounded rounded-t-none border-2 border-orange-300 text-xs text-white p-2">							
									<div class="flex-1 text-gray-700 text-center">
										<div class="relative">
											Per l'imbarco del minore non accompagnato, è obbligatorio compilare e consegnare al check-in la
											<button type="button" class="rounded border border-orange-500 text-xs text-orange-500 p-1">
												DICHIARAZIONE PER IL TRASPORTO DI MINORI NON ACCOMPAGNATI
												<fa class="s-edit ml-1" />
											</button>
											<br>
											<span class="ml-1 italic">( il modulo da compilare sarà inviato via mail al termine della procedura di acquisto )</span>

											<div class="mt-6">
												<vue-checkbox v-model="flagUnattendedChildDisclaimer" color="red" />
												<label>sono consapevole di dover consegnare il modulo compilato e firmato al check-in</label>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="hidden md:flex md:flex-col md:mt-4">
							<div v-if="checkRoles(['Admin', 'Prenotazioni', 'PrenotaFull'])" class="w-full select-none">
								<vue-checkbox v-model="flagUnattendedChild" />
								<label class="ml-1 italic select-none">prenotazione per "minore non accompagnato"</label>
							</div>
							<div v-if="checkRoles(['Admin', 'Prenotazioni', 'PrenotaFull'])" class="w-full mt-2 select-none">
								<vue-checkbox v-model="animal" />
								<label class="ml-1 italic select-none">
									animale domestico
									<fa class="s-info-circle" v-tippy content="max uno per volo. occupa un posto. vedi Tariffe" />
								</label>
							</div>
						</div>
						<div class="flex md:hidden">
							<div class="w-1/2">
								&nbsp;
							</div>
							<div class="w-1/2 md:w-1/6 flex flex-col items-start justify-end text-gray-700 py-2 pl-2">
								<label class="block text-gray-700 text-sm font-bold mb-3">Tot Pax</label>
								<span v-text="totPax" type="text" class="block w-full text-center py-2 bg-white border h-8 border-gray-400 px-1"></span>
							</div>
						</div>
						<div class="flex mt-2 border-t border-gray-400 md:justify-between">
							<div class="w-1/2 md:w-1/6 flex justify-begin text-gray-700 pt-2 pr-2">
								&nbsp;
							</div>
							<div class="w-1/2 md:w-1/6 flex flex-col text-gray-700 pt-2 pl-2">
								<button class="flex flex-row justify-between items-center w-full cursor-pointer bg-green-600 rounded p-4 text-white text-sm" type="button" @click="goToStep(2)" data-cy="goto-step-2">
									{{ locale.goStep2 }}
									&nbsp;&nbsp;&nbsp;
									<fa class="s-chevron-right" />
								</button>
							</div>
						</div>
					</div>
					<!-- STEP 1 / end -->

					<!-- STEP 2 / begin -->
					<div v-if="step == 2">
						<div class="flex bg-blue-800 pl-3">
							<div class="w-1/2 flex justify-start">
								<span class="text-white font-bold text-lg" style="line-height:40px">{{ locale.bookingDepartureFlight }}</span>
							</div>
							<div class="w-1/2 flex justify-end">
								<div class="flex" style="width:180px">
									<div class="flex -mr-px">
										<span :disabled="departureFlightsLoading" @click="changeDay('-', 'D')" class="cursor-pointer flex items-center leading-normal bg-blue-400 border border-gray-400 px-3 whitespace-no-wrap text-white text-sm">
											<fa class="s-chevron-left" />
										</span>
									</div>				
									<span v-html="departureDate" style="line-height:20px" type="text" class="font-bold text-blue-600 text-center bg-white flex-shrink flex-grow flex-auto leading-normal flex-1 border h-10 border-gray-400 px-3 relative"></span>
									<div class="flex -mr-px">
										<span :disabled="departureFlightsLoading" @click="changeDay('+', 'D')" class="cursor-pointer flex items-center leading-normal bg-blue-400 border border-l-0 border-gray-400 px-3 whitespace-no-wrap text-white text-sm">
											<fa class="s-chevron-right" />
										</span>
									</div>	
								</div>
							</div>
						</div>
						<div class="flex mb-4">
							<div class="flex-1">
								<table class="text-left w-full border border-gray" data-cy="departure-flights">
									<thead>
										<tr>
											<th width="1%" class="bg-gray-200 font-sans font-medium text-xs text-gray-dark border-b border-gray-400">&nbsp;</th>
											<th class="py-1 px-2 bg-gray-200 font-sans font-medium text-xs text-gray-dark border-b border-gray-400" v-text="locale.bookingFlight"></th>
											<th class="hidden md:table-cell py-1 px-2 bg-gray-200 font-sans font-medium text-xs text-gray-dark border-b border-gray-400" v-text="locale.bookingAircraft"></th>
											<th class="hidden md:table-cell py-1 px-2 bg-gray-200 font-sans font-medium text-xs text-gray-dark border-b border-gray-400" v-text="locale.bookingRoute"></th>
											<th class="hidden md:table-cell py-1 px-2 bg-gray-200 font-sans font-medium text-xs text-gray-dark border-b border-gray-400" v-text="locale.bookingOrigin"></th>
											<th class="py-1 px-2 bg-gray-200 font-sans font-medium text-xs text-gray-dark border-b border-gray-400">
												<span class="hidden md:block">{{ locale.bookingTime }}</span>
												<span class="block md:hidden">{{ locale.bookingTakeoffAbbr }}</span>
											</th>
											<th class="hidden md:table-cell py-1 px-2 bg-gray-200 font-sans font-medium text-xs text-gray-dark border-b border-gray-400" v-text="locale.bookingDestination"></th>
											<th class="py-1 px-2 bg-gray-200 font-sans font-medium text-xs text-gray-dark border-b border-gray-400">
												<span class="hidden md:block">{{ locale.bookingTime }}</span>
												<span class="block md:hidden">{{ locale.bookingLandingAbbr }}</span>
											</th>
											<th v-if="checkRoles(['Admin', 'Prenotazioni', 'PrenotaFull'])" class="py-1 px-2 bg-gray-200 font-sans font-medium text-xs text-gray-dark border-b border-gray-400 text-center">Info</th>
										</tr>
									</thead>
									<tbody>
										<tr v-if="departureFlightsLoading" class="hover:bg-blue-100">
											<td colspan="9">
												<div class="flex items-center justify-center py-3">
													<img src="/img/Heli-loader.gif">
												</div>
											</td>
										</tr>
										<tr v-if="! departureFlightsLoading && departureFlights.length == 0" class="hover:bg-blue-100">
											<td colspan="9" class="text-center px-3 py-3" v-text="locale.noFlightsFound"></td>
										</tr>
										<flight-row
											v-if="! departureFlightsLoading && departureFlights.length > 0"
											v-for="flight in departureFlights"
											:key="flight.flightId"
											:flight="flight"
											:id-selected="form.departureFlightId"
											route-type="departure"
											@checked="({ flightId }) => form.departureFlightId = flightId"
											@loading="val => isLoading = val"
										/>
									</tbody>
								</table>
							</div>
						</div>

						<div v-if="form.flightType == 'DW'" class="flex bg-blue-800 pl-3">
							<div class="w-1/2 flex justify-start">
								<span class="text-white font-bold text-lg" style="line-height:40px">{{ locale.bookingComebackFlight }}</span>
							</div>
							<div class="w-1/2 flex justify-end">
								<div class="flex" style="width:180px">
									<div class="flex -mr-px">
										<span :disabled="comebackFlightsLoading" @click="changeDay('-', 'C')" class="cursor-pointer flex items-center leading-normal bg-blue-400 border border-gray-400 px-3 whitespace-no-wrap text-white text-sm">									
											<fa class="s-chevron-left" />
										</span>
									</div>				
									<span v-html="comebackDate" style="line-height:20px" type="text" class="font-bold text-blue-600 text-center bg-white flex-shrink flex-grow flex-auto leading-normal flex-1 border h-10 border-gray-400 px-3 relative"></span>
									<div class="flex -mr-px">
										<span :disabled="comebackFlightsLoading" @click="changeDay('+', 'C')" class="cursor-pointer flex items-center leading-normal bg-blue-400 border border-l-0 border-gray-400 px-3 whitespace-no-wrap text-white text-sm">
											<fa class="s-chevron-right" />
										</span>
									</div>	
								</div>
							</div>
						</div>
						<div v-if="form.flightType == 'DW'" class="flex mb-4">
							<div class="flex-1">
								<table class="text-left w-full border border-gray" data-cy="comeback-flights">
									<thead>
										<tr>
											<th class="py-1 bg-gray-200 font-sans font-medium text-xs text-gray-dark border-b border-gray-400">&nbsp;</th>
											<th class="py-1 px-2 bg-gray-200 font-sans font-medium text-xs text-gray-dark border-b border-gray-400" v-text="locale.bookingFlight"></th>
											<th class="hidden md:table-cell py-1 px-2 bg-gray-200 font-sans font-medium text-xs text-gray-dark border-b border-gray-400" v-text="locale.bookingAircraft"></th>
											<th class="hidden md:table-cell py-1 px-2 bg-gray-200 font-sans font-medium text-xs text-gray-dark border-b border-gray-400" v-text="locale.bookingRoute"></th>
											<th class="hidden md:table-cell py-1 px-2 bg-gray-200 font-sans font-medium text-xs text-gray-dark border-b border-gray-400" v-text="locale.bookingOrigin"></th>
											<th class="py-1 px-2 bg-gray-200 font-sans font-medium text-xs text-gray-dark border-b border-gray-400">
												<span class="hidden md:block">{{ locale.bookingTime }}</span>
												<span class="block md:hidden">{{ locale.bookingTakeoffAbbr }}</span>
											</th>
											<th class="hidden md:table-cell py-1 px-2 bg-gray-200 font-sans font-medium text-xs text-gray-dark border-b border-gray-400" v-text="locale.bookingDestination"></th>
											<th class="py-1 px-2 bg-gray-200 font-sans font-medium text-xs text-gray-dark border-b border-gray-400">
												<span class="hidden md:block">{{ locale.bookingTime }}</span>
												<span class="block md:hidden">{{ locale.bookingLandingAbbr }}</span>
											</th>
											<th v-if="checkRoles(['Admin', 'Prenotazioni', 'PrenotaFull'])" class="hidden md:table-cell py-1 px-2 bg-gray-200 font-sans font-medium text-xs text-gray-dark border-b border-gray-400 text-center">Info</th>
										</tr>
									</thead>
									<tbody>
										<tr v-if="comebackFlightsLoading" class="hover:bg-blue-100">
											<td colspan="9">
												<div class="flex items-center justify-center py-3">
													<img src="/img/Heli-loader.gif">
												</div>
											</td>
										</tr>
										<tr v-if="! comebackFlightsLoading && comebackFlights.length == 0" class="hover:bg-blue-100">
											<td colspan="9" class="text-center px-3 py-3" v-text="locale.noFlightsFound"></td>
										</tr>
										<flight-row
											v-if="! comebackFlightsLoading && comebackFlights.length > 0"
											v-for="flight in comebackFlights"
											:key="flight.flightId"
											:flight="flight"
											:id-selected="form.comebackFlightId"
											route-type="comeback"
											@checked="({ flightId }) => form.comebackFlightId = flightId"
											@loading="val => isLoading = val"
										/>
									</tbody>
								</table>
							</div>
						</div>
						<div class="flex border-t border-gray-400 md:justify-between">
							<div class="w-1/2 md:w-1/6 flex justify-begin text-gray-700 pt-2 pr-2">
								<button class="flex flex-row justify-between items-center w-full cursor-pointer bg-green-600 rounded p-4 text-white text-sm" type="button" @click="goToStep(1)">
									<fa class="s-chevron-left" />
									{{ locale.backStep1 }}
								</button>
							</div>
							<!--
							<div v-if="checkRoles(['BloccoPosti'])" class="w-1/3 md:w-1/6 flex justify-begin text-gray-700 pt-2 pr-2" :class="{ 'flex-1': bloccaPosti }">
								<button v-if="! bloccaPosti" class="flex flex-row justify-center items-center w-full cursor-pointer border border-gray-400 bg-gray-200 rounded p-4 text-gray-500 text-sm" type="button" @click="mostraBloccoPosti()">
									<span>Blocca posti</span>
								</button>
								<div v-if="bloccaPosti" class="ml-20 w-full flex flex-col">
									<div class="flex flex-col">
										<div class="mb-3">Scrivi la motivazione di blocco posti</div>
										<textarea v-model="bloccaPostiNota" rows="3" class="w-full border p-1" />
									</div>
									<div class="flex justify-between">
										<button class="w-1/5 flex flex-row justify-center items-center cursor-pointer bg-red-400 rounded p-1 text-white text-sm" type="button" @click="bloccaPosti = false">
											<span>Annulla blocco posti</span>
										</button>						
										<button class="w-1/5 flex flex-row justify-center items-center cursor-pointer bg-green-600 rounded p-1 text-white text-sm" type="button" @click="confermaBloccoPosti">
											<span>Conferma blocco posti</span>
										</button>						
									</div>
								</div>
							</div>
							-->
							<div v-if="! bloccaPosti" class="w-1/2 md:w-1/6 flex flex-col text-gray-700 pt-2 pl-2">
								<button data-cy="goto-step-3" class="flex flex-row justify-between items-center w-full cursor-pointer bg-green-600 rounded p-4 text-white text-sm" type="button" @click="goToStep(3)">
									{{ locale.goStep3 }}
									<fa class="s-chevron-right" />
								</button>
							</div>
						</div>
					</div>
					<!-- STEP 2 / end -->

					<!-- STEP 3 / begin -->
					<div v-if="step == 3">
						<pax-block :pax="form.paxes[index]" @update="obj => updatePax(obj, index)" :idx="index" v-for="(pax, index) in form.paxes" :key="pax._uuid" />

						<div class="flex justify-center mb-3 md:flex-row md:mb-0 md:mt-8 space-x-3 text-red-500">
							<svg class="h-6 hidden md:block w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
								<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 13l-3 3m0 0l-3-3m3 3V8m0 13a9 9 0 110-18 9 9 0 010 18z"></path>
							</svg>

							<span v-if="lang == 'it'">
								Si raccomanda di inserire contatti di <b>immediata reperibilità</b> in quanto ogni eventuale comunicazione sarà effettuata su questi canali
							</span>
							<span v-if="lang == 'en'">
								Si raccomanda di inserire contatti di <b>immediata reperibilità</b> in quanto ogni eventuale comunicazione sarà effettuata su questi canali
								<!-- It is recommended to insert contacts that are <b>immediately available</b> as any communication will be carried out on these channels -->
							</span>
							
							<svg class="h-6 hidden md:block w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
								<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 13l-3 3m0 0l-3-3m3 3V8m0 13a9 9 0 110-18 9 9 0 010 18z"></path>
							</svg>
						</div>

						<div class="flex flex-col md:flex-row md:mt-8">
							<div class="flex flex-col w-full mb-2 md:mr-8">
								<label class="label italic">
									{{ locale.bookingContactEmail }}
								</label>
								<input v-model="form.contacts.email" class="appearance-none block w-full text-gray-700 border border-gray-500 rounded px-2 py-1" type="text">
								<button @click="form.contacts.email = user.email" type="button" data-cy="booking-contact-email" class="booking-contact-email-btn float-right cursor-pointer leading-normal bg-blue-400 rounded rounded-l-none border border-l-0 border-gray-400 px-3 whitespace-no-wrap text-white text-sm" content="usa come email di contatto" v-tippy="{ arrow: true }"> 
									{{ user.email }}
									<fa class="s-undo-alt ml-2 text-xs relative" style="top:-0.05em" />
								</button>
							</div>
							<div class="flex flex-col w-full mb-2 md:ml-8">
								<label class="label italic">
									{{ locale.bookingContactTelephone }}
									(
									<span v-if="lang == 'it'" class="underline">solo cellulare</span>
									<span v-if="lang == 'en'" class="underline">mobile number only</span>
									)
								</label>
								<input v-model="form.contacts.telephone" class="appearance-none block w-full text-gray-700 border border-gray-500 rounded px-2 py-1" type="text">
								<button @click="form.contacts.telephone = user.telephone" type="button" data-cy="booking-contact-phone" class="booking-contact-phone-btn float-right cursor-pointer leading-normal bg-blue-400 rounded rounded-l-none border border-l-0 border-gray-400 px-3 whitespace-no-wrap text-white text-sm" content="usa come telefono di contatto" v-tippy="{ arrow: true }">
									{{ user.telephone }}
									<fa class="s-undo-alt ml-2 text-xs relative" style="top:-0.05em" />
								</button>
							</div>
						</div>

						<div v-if="women" class="mt-6 py-1 bg-pink-600 text-white text-center relative">
							<fa class="s-asterisk fa-spin absolute text-xs text-white border border-white rounded-full p-1" style="top:3px; left:5px" />
							<span class="font-bold">ATTENZIONE:</span> NON è consentito l'imbarco alle donne che abbiano superato l'ottavo mese di gravidanza!
						</div>
						<div v-if="women" class="flex justify-center rounded rounded-t-none border-2 border-pink-300 text-xs text-white p-2 pb-4">							
							<div class="flex-1 text-gray-700 text-center flex flex-col">
								<div class="relative">
									Le gestanti entro l'ottavo mese di gravidanza sono tenute a compilare e firmare la
									<button type="button" class="rounded border border-pink-500 text-xs text-pink-500 p-1">
										DICHIARAZIONE LIBERATORIA PER TRASPORTO GESTANTI
										<fa class="s-edit ml-1" />
									</button>
								</div>
								<div class="mt-3">
									<fa class="s-asterisk fa-spin relative text-xs text-pink-600 border border-gray-400 rounded-full p-1 mr-2" style="top:2px" />
									<vue-checkbox v-model="form.email_forms_pdf.pregnant" />
									<span class="ml-1 italic">
										richiedo modulo via mail
									</span>
									<fa class="s-asterisk fa-spin relative text-xs text-pink-600 border border-gray-400 rounded-full p-1 ml-2" style="top:2px" />
								</div>
							</div>
						</div>

						<div class="flex justify-center">
							<div class="flex-1 text-gray-700 bg-white py-8 mb-2 text-center">
								<div class="relative">
									{{ locale.bookingAcceptConditions }}
									<label>&nbsp;</label>
									<vue-checkbox v-model="form.conditions" color="green"></vue-checkbox>
									<a href="javascript:void();" class="appearance-none text-gray-700 py-1 px-2" style="text-decoration:underline" @click="openModalConditions" v-text="locale.bookingConditions"></a>
									&
									<a href="javascript:void();" class="appearance-none text-gray-700 py-1 px-2" style="text-decoration:underline" @click="openModalPrivacy" v-text="locale.bookingPrivacy"></a>
								</div>
							</div>
						</div>
						<div class="flex border-t border-gray-400 md:justify-between">
							<div class="w-1/2 md:w-1/6 flex justify-begin text-gray-700 pt-2 pr-2">
								<button v-if="! prenBloccataSel" class="flex flex-row justify-between items-center w-full cursor-pointer bg-green-600 rounded p-4 text-white text-sm" type="button" @click="goToStep(2)">
									<fa class="s-chevron-left" />
									{{ locale.backStep2 }}
								</button>
							</div>
							<div class="w-1/2 md:w-1/6 flex flex-col text-gray-700 pt-2 pl-2">
								<button data-cy="goto-step-4" class="flex flex-row justify-between items-center w-full cursor-pointer bg-green-600 rounded p-4 text-white text-sm" type="button" @click="goToStep(4)">
									{{ locale.goStep4 }}
									<fa class="s-chevron-right" />
								</button>
							</div>
						</div>
					</div>
					<!-- STEP 3 / end -->

					<!-- STEP 4 / begin -->
					<div v-if="step == 4">
						<div class="flex items-start pt-1 mb-4">
							<div class="w-1/2 text-right pr-3 text-xs italic">
								Se disponi di un codice sconto, puoi inserirlo qui:
							</div>
							<div class="w-1/2 flex flex-col">
								<div v-if="coupon.counter < 3 && coupon.status !== true" class="flex flex-wrap items-stretch w-full relative">
									<input v-model="form.coupon.code" data-cy="cpn-text" type="text" class="appearance-none block w-full text-gray-700 border border-gray-500 rounded rounded-r-none py-1 px-2 flex-1"></span>
									<div class="flex -mr-px border border-gray-500 rounded rounded-l-none">
										<span @click="checkCoupon" data-cy="cpn-submit" class="text-blue-600 cursor-pointer flex items-center leading-normal rounded rounded-l-none border border-l-0 border-gray-400 px-3 whitespace-no-wrap text-white text-sm">
											<fa class="s-check mr-1" />
											Applica
										</span>
									</div>	
								</div>
								<div v-if="coupon.status !== null" class="py-1 px-2 text-xs" :class="{ 'bg-green-200': coupon.status === true, 'bg-red-200': coupon.status === false }" v-html="coupon.message"></div>
							</div>
						</div>

						<!-- FATTURAZIONE QUI -->

						<div v-if="lang == 'it'" class="border text-red-600 leading-normal border-orange bg-red-100 p-4 mb-4">
							Cliccando "Pagamento" verrete reindirizzati sul server sicuro di Banca Sella per procedere con l'acquisto.
							<br />
							<span class="font-semibold">Il termine massimo per completare l'operazione è di <span class="font-bold bg-yellow-500 rounded px-1">10 minuti</span></span>, trascorsi i quali la prenotazione sarà automaticamente annullata.
							<br />
							Ad avvenuto pagamento, il sistema invierà alla mail <strong style="color:blue" v-text="form.contacts.email"></strong> i biglietti, che saranno anche disponibili nella propria area utente.
							<br />
							Eventuali comunicazioni relative al viaggio saranno effettuate al numero <strong style="color:blue" v-text="form.contacts.telephone"></strong>.
						</div>

						<div v-if="lang == 'en'" class="border text-red-600 leading-normal border-orange bg-red-100 p-4 mb-4">
							By clicking, "Checkout" you'll be redirected to secure server of Banca Sella to proceed to purchase.
							<br />
							The deadline to perform the transaction is <strong>10 minutes</strong>. If exceeded, the reservation will be automatically cancelled.
							<br />
							At purchase completed, our server will send an email to <strong style="color:blue" v-text="form.contacts.email"></strong> with the tickets, which will be also available in your personal area.
							<br />
							Any communication about the trip will be carried out to <strong style="color:blue" v-text="form.contacts.telephone"></strong>.
						</div>

						<div v-if="byBirthdateMinorsAlert" class="border flex-1 flex flex-col mb-3">
							<div class="py-1 bg-orange-600 text-white text-center relative">
								<span class="font-bold">ATTENZIONE:</span> minore non accompagnato
							</div>
							<div class="flex justify-center rounded rounded-t-none border-2 border-orange-300 text-xs text-white p-2">							
								<div class="flex-1 text-gray-700 text-center">
									<div class="relative">
										Per l'imbarco del minore non accompagnato, è obbligatorio compilare e consegnare al check-in la
										<button type="button" class="rounded border border-orange-500 text-xs text-orange-500 p-1">
											DICHIARAZIONE PER IL TRASPORTO DI MINORI NON ACCOMPAGNATI
											<fa class="s-edit ml-1" />
										</button>
										<br>
										<span class="ml-1 italic">( il modulo da compilare sarà inviato via mail al termine della procedura di acquisto )</span>

										<div class="mt-6">
											<vue-checkbox v-model="flagUnattendedChildDisclaimer" :disabled="true" />
											<label>sono consapevole di dover consegnare il modulo compilato e firmato al check-in</label>
										</div>
									</div>
								</div>
							</div>
						</div>

						<!--
						<div v-if="byBirthdateWomen">
							<vue-checkbox v-model="flagPregnant"></vue-checkbox>
							<label>sono consapevole di dover consegnare il modulo compilato e firmato al check-in</label>
						</div>
						-->
						<div v-if="byBirthdateWomenPregnantAlert" class="border flex-1 flex flex-col mb-3">
							<div class="py-1 bg-orange-600 text-white text-center relative">
								<span class="font-bold">ATTENZIONE:</span> minore non accompagnato
							</div>
							<div class="flex justify-center rounded rounded-t-none border-2 border-orange-300 text-xs text-white p-2">							
								<div class="flex-1 text-gray-700 text-center">
									<div class="relative">
										Per l'imbarco del minore non accompagnato, è obbligatorio compilare e consegnare al check-in la
										<button type="button" class="rounded border border-orange-500 text-xs text-orange-500 p-1">
											DICHIARAZIONE PER IL TRASPORTO DI MINORI NON ACCOMPAGNATI
											<fa class="s-edit ml-1" />
										</button>
										<br>
										<span class="ml-1 italic">( il modulo da compilare sarà inviato via mail al termine della procedura di acquisto )</span>

										<div class="mt-6">
											<vue-checkbox v-model="flagUnattendedChildDisclaimer" />
											<label>sono consapevole di dover consegnare il modulo compilato e firmato al check-in</label>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div v-if="lang == 'it'" class="flex flex-col text-blue-600 leading-normal border border-blue-500 bg-blue-100 p-4 mb-4">
							<div class="flex flex-col mb-4">
								<div class="flex font-bold text-base">
									Si ricorda che...
								</div>
								<div class="flex">
									<ul class="pl-4">
										<li>• è possibile portare un solo bagaglio per persona, di dimesioni max cm. 60x40x20.</li>
										<li class="mt-2">• animali di piccola taglia possono essere portati in gabbiette di dimensioni max cm. 43x33x34.</li>
										<li class="mt-2">
											• in caso di traporto armi/munizioni, <span class="underline">è obbligatorio compilare e consegnare</span> al check-in la
												<button type="button" class="rounded bg-blue-500 text-xs text-white p-1">
													DICHIARAZIONE PER IL TRASPORTO DI ARMI E MUNIZIONI
													<fa class="s-edit ml-1" />
												</button>
											<br>
											<vue-checkbox v-model="form.email_forms_pdf.weapons" /> <span class="ml-1 italic text-xs">desidero ricevere il modulo via email</span>
										</li>
										<li class="mt-2">• l'auto può restare all'interno dell'eliporto Alidaunia, sulle aree di sosta antistanti il terminal, appositamente riservate ai passeggeri ed individuate dalle strisce di colore blu. Il parcheggio è tariffato 0,20 €/ora e la disponibilità di posti è limitata.</li>
									</ul>
								</div>
							</div>
							<div class="flex font-bold text-xl">
								ATTENZIONE
							</div>
							<div class="flex">
								<ul class="pl-4">
									<li>Presentarsi all'elisuperficie almeno <span class="bg-yellow-500 font-bold rounded px-1">30 minuti prima</span> dell'orario di partenza.</strong></li>
									<li>Il check-in ed il cancello d'ingresso chiudono tassativamente <span class="bg-yellow-500 font-bold rounded px-1">10 minuti prima</span> dell'orario di partenza.</li>
								</ul>
							</div>
						</div>

						<div v-if="lang == 'en'" class="flex flex-col text-blue-600 leading-normal border border-blue-500 bg-blue-100 p-4 mb-4">
							<div class="flex flex-row mb-4">
								<div class="flex w-1/6 justify-end">
									Please note that:
								</div>
								<div class="flex w-5/6">
									<ul>
										<li>is possible to carry only one luggage for person: max size cm. 60x40x20.</li>
										<li>small pets can be carried in in cages of max size cm. 43x33x34.</li>
									</ul>
								</div>
							</div>
							<div class="flex font-bold text-xl">
								CAUTION
							</div>
							<div class="flex">
								<ul>
									<li>Show up to the elipad at least <span style="font-size:16px;font-weight:bold">30 minutes before</span> the departure time.</strong></li>
									<li>Check-in and entrance gate will close <u>strictly</u> <span style="font-size:16px;font-weight:bold">10 minutes before</span> the departure time.</li>
								</ul>
							</div>
						</div>

						<div>
							<div class="w-full flex flex-col items-start text-gray-700 bg-white text-xs mb-2 bg-blue-100 border border-blue-200 py-1 px-2 ">
								<div class="mt-1 p-1 flex bg-yellow-100 rounded border border-yellow-500">
									Gli enti e le società soggette a "split payment" per l'acquisto con fattura devono contattare il call center.
								</div>
								<div class="mt-3 flex items-center">
									<vue-checkbox v-model="invoice.requested"></vue-checkbox>
									<div v-if="lang == 'it'" class="ml-2 leading-normal">Richiedi fattura</div>
									<div v-if="lang == 'en'" class="ml-2 leading-normal">Request invoice</div>
								</div>
								<div v-if="invoice.requested" class="mt-4 w-full">
									<div class="w-full flex">
										<div class="flex-1">
											<vue-radio v-model="invoice.invoice_type" label="azienda" :value="0" />
										</div>
										<div class="flex-1">
											<vue-radio v-model="invoice.invoice_type" label="persona fisica" :value="1" />
										</div>
									</div>
									<div class="w-full flex mt-5 mb-2">
										<div class="w-1/2 md:w-1/3 font-semibold flex flex-col pr-2">
											<span>Partita IVA</span>
											<input type="text" maxlength="255" class="border rounded px-2 py-1" v-model="invoice.p_iva">
										</div>
										<div v-if="invoice.invoice_type == 0" class="w-1/2 md:w-1/3 font-semibold flex flex-col">
											<span>Ragione Sociale</span>
											<input type="text" maxlength="255" class="border rounded px-2 py-1" v-model="invoice.business_name">
										</div>
										<div v-if="invoice.invoice_type == 1" class="w-1/2 md:w-1/3 font-semibold flex flex-col pr-2">
											<span>Codice fiscale</span>
											<input type="text" maxlength="255" class="border rounded px-2 py-1" v-model="invoice.fiscal_code">
										</div>
									</div>
									<div v-if="invoice.invoice_type == 1" class="w-full flex mb-2">
										<div class="w-1/2 font-semibold flex flex-col pr-2">
											<span>Nome</span>
											<input type="text" maxlength="255" class="border rounded px-2 py-1" v-model="invoice.firstname">
										</div>
										<div class="w-1/2 font-semibold flex flex-col">
											<span>Cognome</span>
											<input type="text" maxlength="255" class="border rounded px-2 py-1" v-model="invoice.lastname">
										</div>
									</div>
									<div class="w-full flex flex-wrap md:flex-no-wrap">
										<div class="w-full md:w-6/12 font-semibold flex flex-col md:mr-2 mb-2 md:mb-0">
											<span>Indirizzo</span>
											<input type="text" maxlength="255" class="border rounded px-2 py-1" v-model="invoice.address">
										</div>
										<div class="w-1/2 md:w-2/12 font-semibold flex flex-col mr-2">
											<span>Città</span>
											<input type="text" maxlength="255" class="border rounded px-2 py-1 w-full" v-model="invoice.city">
										</div>
										<div class="w-1/3 md:w-2/12 font-semibold flex flex-col pr-2">
											<span>CAP</span>
											<input type="text" maxlength="255" class="border rounded px-2 py-1 w-full" v-model="invoice.zip_code">
										</div>
									</div>
									<div class="w-full flex flex-wrap md:flex-no-wrap mt-2">
										<div class="w-1/2 font-semibold flex flex-col mr-2 mb-2">
											<span>PEC</span>
											<input type="text" maxlength="255" class="border rounded px-2 py-1" v-model="invoice.pec">
										</div>
										<div class="w-2/5 font-semibold flex flex-col mr-2 mb-2">
											<span>Codice destinatario</span>
											<input type="text" maxlength="255" class="border rounded px-2 py-1" v-model="invoice.fiscal_recipient_code">
										</div>
									</div>
									<div class="text-xs italic flex flex-col">
										<span>• tutti i campi sono obbligatori</span>
										<span v-if="invoice.invoice_type == 1">• almeno un campo richiesto tra "Partita IVA" e "Codice fiscale"</span>
										<span>• almeno un campo richiesto tra "PEC" e "Codice destinatario"</span>
									</div>
								</div>

							</div>
						</div>

						<div class="flex flex-wrap justify-center mb-3">
							<img class="m-1" src="/img/payment/visa.png" style="height:30px;float:right;margin-right:20px">
							<img class="m-1" src="/img/payment/mastercard.gif" style="height:30px;float:right;margin-right:20px">
							<img class="m-1" src="/img/payment/hype.jpg" style="height:30px;float:right;margin-right:20px">
							<img class="m-1" src="/img/payment/mybank.png" style="height:30px;float:right;margin-right:20px">
							<img class="m-1" src="/img/payment/masterpass.png" style="height:30px;float:right;margin-right:20px">
							<img class="m-1" src="/img/payment/tim-personal.png" style="height:30px;float:right;margin-right:20px">
							<img class="m-1" src="/img/payment/sella.png" style="height:30px;float:right;margin-right:20px">
						</div>

						<div class="flex border-t border-gray-400 md:justify-between">
							<div class="w-1/2 md:w-1/5 flex flex-col text-gray-700 py-2 pr-2">
								<button class="flex flex-row justify-between items-center w-full cursor-pointer bg-green-600 rounded p-4 text-white text-sm" type="button" @click="goToStep(3)">
									<fa class="s-chevron-left" />
									{{ locale.backStep3 }}
								</button>
							</div>
							<div class="hidden w-3/5 pt-2">
								<div v-if="lang == 'it'" class="text-center text-xs rounded text-red-800 leading-normal border border-red-300 bg-yellow-200 p-2 mb-2">
									<p class="font-bold text-lg">IMPORTANTE</p>
									<p>
										Con l'entrata in vigore della <strong>Direttiva PSD2</strong>, dal 14 settembre 2019 gli acquisti online dovranno essere convalidati tramite un token inviato dalla propria banca via sms (sul numero telefonico collegato alla carta di pagamento scelta) oppure tramite app per smartphone.
										<br />
										Pertanto, <span class="font-bold">in caso di mancata conferma del pagamento</span>, vi suggeriamo di rivolgervi al supporto clienti della vostra banca di riferimento.
									</p>
								</div>
								<div v-if="lang == 'en'" class="text-center rounded text-red-800 leading-normal border border-red-300 bg-yellow-200 p-4 mb-4">
									<p class="font-bold text-lg">IMPORTANT</p>
									<p>
										With the entry into force of the PSD2 directive, online purchases must be validated from 14 September 2019 through a token sent by your bank via sms or via smartphone app. The phone number must be connected to the chosen payment card.
										<br />
										Therefore, <span class="font-bold">in case of failure to confirm payment</span>, we recommend you to contact the customer support of your reference bank.
									</p>
								</div>
							</div>
							<div class="w-1/2 md:w-1/5 flex flex-col text-gray-700 py-2 pl-2">
								<button v-if="! checkRoles(['Admin', 'Prenotazioni', 'PrenotaFull'])" :disabled="checkRoles(['Admin', 'Prenotazioni', 'PrenotaFull'])" :class="{ 'bg-gray-300 text-gray-600 cursor-not-allowed': checkRoles(['Admin', 'Prenotazioni', 'PrenotaFull']), 'bg-green-600 text-white': ! checkRoles(['Prenota', 'PrenotaFull']) }" class="flex flex-row justify-between items-center w-full cursor-pointer rounded p-4 text-sm" type="button" @click="storeBooking(1)">
									<span v-if="lang == 'it'">Pagamento</span>
									<span v-if="lang == 'en'">Checkout</span>
									<fa class="r-credit-card text-xl" />
								</button>
							</div>
						</div>
						<div v-if="checkRoles(['Admin', 'Prenotazioni', 'PrenotaFull'])" class="flex border-t border-gray-400 md:justify-between">
							<div class="w-1/2 md:w-1/6 flex justify-begin text-gray-700 py-2 pr-2">
								&nbsp;
							</div>
							<div class="w-1/2 md:w-1/4 flex flex-col text-gray-700 py-2 pl-2">
								<input type="text" v-model="form.contacts.email_payment_link" class="appearance-none block w-full text-gray-700 border border-gray-500 rounded rounded-b-none border-b-0 py-1 px-2 flex-1" />
								<button v-if="checkRoles(['Prenotazioni', 'PrenotaFull'])" class="flex flex-row justify-between items-center w-full cursor-pointer bg-green-500 text-white border border-green-400 rounded rounded-t-none px-2 py-2 text-sm" type="button" @click="storeBooking(-1)">
									Invia link di pagamento
									<fa class="s-share text-xl" />
								</button>

								<div v-if="checkRoles(['Admin', 'PagamentoCash'])" class="mt-5 w-full flex items-center select-none border-2 border-yellow-500 rounded">
									<button @click="cashLocked = ! cashLocked" type="button" class="border h-full p-2 flex items-center focus:outline-none">
										<fa class="text-xl" :class="{ 's-lock text-red-500': cashLocked, 's-lock-open text-green-500': ! cashLocked }" />
									</button>
									<button @click.prevent="storeBooking(2)" type="button" class="flex justify-between w-full h-full text-white text-sm font-semibold p-2" :class="{ 'bg-gray-400 pointer-events-none': cashLocked, 'bg-orange-500 hover:bg-orange-600': ! cashLocked }">
										Contanti
										<fa class="s-hand-holding-usd text-xl" />
									</button>
								</div>

								<div v-if="checkRoles(['Admin', 'PagamentoAlidaunia'])" class="mt-5 w-full flex items-center select-none border-2 border-red-500 rounded">
									<button @click="alidauniaLocked = ! alidauniaLocked" type="button" class="border h-full p-2 flex items-center focus:outline-none">
										<fa class="text-xl" :class="{ 's-lock text-red-500': alidauniaLocked, 's-lock-open text-green-500': ! alidauniaLocked }" />
									</button>
									<button @click.prevent="storeBooking(3)" type="button" class="flex justify-between w-full h-full text-white text-sm font-semibold p-2" :class="{ 'bg-gray-400 pointer-events-none': alidauniaLocked, 'bg-red-500 hover:bg-red-700': ! alidauniaLocked }">
										Alidaunia
										<fa class="s-helicopter text-xl" />
									</button>
								</div>
							</div>
						</div>
					</div>
					<!-- STEP 4 / end -->

					<!-- STEP 40 - con redirect manuale -->
					<div v-if="step == 40">
						<div class="flex h-auto items-center justify-center pt-8">
							<div class="is-active loading-overlay" style="width: 88px;	position: inherit;	display: block;	height: 88px;">
								<div class="loading-icon"></div>
							</div>
						</div>
						<div class="bg-white rounded m-4 max-h-full text-center">
							<div class="flex flex-col items-center" style="margin-bottom:150px">
								<p class="p-4 text-justify leading-loose rounded">
									<span v-if="lang == 'it'">
										Se il browser non ti reindirizza in automatico verso il server di Banca Sella, per favore clicca il pulsante di seguito.
									</span>
									<span v-if="lang == 'en'">
										If the browser doesn't redirect you automatically in a few seconds, please click the button below.
									</span>
								</p>
								<a :href="redirectUrl" class="w-full md:w-1/2 text-lg text-white bg-green-600 p-3 rounded no-underline cursor-pointer">
									<span v-if="lang == 'it'">Prosegui verso Banca Sella</span>
									<span v-if="lang == 'en'">Continue to Banca Sella</span>
								</a>
							</div>
						</div>
					</div>

					<!-- STEP 41 - errori server-side -->
					<div v-if="step == 41">
						<div class="bg-white rounded m-4 max-h-full text-center">
							<div class="flex flex-col items-center my-8">
								<p class="flex p-4 text-justify leading-loose rounded w-full">
									<span class="border border-red-500 p-2 text-center w-full">
										<span v-if="lang == 'it'" v-html="error_it"></span>
										<span v-if="lang == 'en'" v-html="error_en"></span>
									</span>
								</p>
								<a v-if="lang == 'it'" href="/booking" class="w-full md:w-1/2 text-lg text-white bg-green-600 p-3 rounded no-underline cursor-pointer">
									<span v-if="lang == 'it'">Riprova con una prenotazione differente</span>
									<span v-if="lang == 'en'">Retry with a different booking</span>
								</a>
							</div>
						</div>
					</div>

					<!-- STEP 401 - sessione scaduta / mancata autenticazione -->
					<div v-if="step == 401">
						<div class="flex h-auto items-center justify-center pt-8">
							<div class="is-active loading-overlay" style="width: 88px;	position: inherit;	display: block;	height: 88px;">
								<div class="loading-icon"></div>
							</div>
						</div>
						<div class="bg-white rounded m-4 max-h-full text-center">
							<div class="flex flex-col items-center" style="margin-bottom:150px">
								<p class="p-4 text-justify leading-loose rounded">
									<span v-if="lang == 'it'">
										La tua sessione è scaduta. Ti preghiamo di rieffettuare il login.
									</span>
									<span v-if="lang == 'en'">
										Your session has expired.  Please login again.
									</span>
								</p>
								<a :href="`/login?lang=${lang}`" class="w-full md:w-1/2 text-lg text-white bg-green-600 p-3 rounded no-underline cursor-pointer">
									Login
								</a>
							</div>
						</div>
					</div>

					<!-- STEP 41 - errori server-side -->
					<div v-if="step == 41">
						<div class="bg-white rounded m-4 max-h-full text-center">
							<div class="flex flex-col items-center my-8">
								<p class="flex p-4 text-justify leading-loose rounded w-full">
									<span class="border border-red-500 p-2 text-center w-full">
										<span v-if="lang == 'it'" v-html="error_it"></span>
										<span v-if="lang == 'en'" v-html="error_en"></span>
									</span>
								</p>
								<a v-if="lang == 'it'" href="/it/prenotazioni" class="w-full md:w-1/2 text-lg text-white bg-green-600 p-3 rounded no-underline cursor-pointer">
									Riprova con una prenotazione differente
								</a>
								<a v-if="lang == 'en'" href="/en/bookings" class="w-full md:w-1/2 text-lg text-white bg-green-600 p-3 rounded no-underline cursor-pointer">
									Retry with a different booking
								</a>
							</div>
						</div>
					</div>

					<!-- STEP 42 - mostra link -->
					<div v-if="step == 42">
						<div class="bg-green-400 border border-green-600 text-white text-lg p-2 flex flex-col leading-normal rounded mb-4">
							<p class="text-base text-center flex flex-col md:flex-row items-center justify-center">								
								<fa class="s-check mr-2" />
								Link di pagamento
								<span class="text-black rounded border border-gray-200 bg-white opacity-75 px-1 py-0 leading-none mx-1 flex items-center">
									<a target="_blank" :href="redirectUrl" v-text="redirectUrl" />
									<fa class="r-copy ml-2 cursor-pointer" v-clipboard="() => redirectUrl" title="clicca per copiare" v-tippy="{ arrow : true }" />
								</span>					
								inviato all'indirizzo
								<span class="italic ml-1" v-text="form.contacts.email_payment_link" />
							</p>
						</div>
					</div>

					<!-- modal condizioni -->
					<modal name="modal-conditions-it" ref="modal-conditions-it" height="auto" class="w-full" :pivot-y="0.3">
						<div class="bg-white rounded m-4 max-h-full text-center">
							<div class="text-left mb-4" style="background: url(https://alidaunia.it/img/linea.svg) center bottom / contain no-repeat;">
								<img src="https://alidaunia.it/img/logo150px.jpg" style="width:130px">
							</div>
							<div class="mb-8">
								<iframe src="https://booking.alidaunia.it/pdf/docs/conditions-it.htm" style="width:100%; height:400px"/>
							</div>
						</div>
					</modal>

					<!-- modal conditions -->
					<modal name="modal-conditions-en" ref="modal-conditions-en" height="auto" class="w-full" :pivot-y="0.3">
						<div class="bg-white rounded m-4 max-h-full text-center">
							<div class="text-left mb-4" style="background: url(https://alidaunia.it/img/linea.svg) center bottom / contain no-repeat;">
								<img src="https://alidaunia.it/img/logo150px.jpg" style="width:130px">
							</div>
							<div class="mb-8">
								<iframe src="https://booking.alidaunia.it/pdf/docs/conditions-en.htm" style="width:100%; height:400px"/>
							</div>
						</div>
					</modal>

					<!-- modal informativa -->
					<modal name="modal-privacy-it" ref="modal-privacy-it" height="auto" class="w-full" :pivot-y="0.3">
						<div class="bg-white rounded m-4 max-h-full text-center">
							<div class="text-left mb-4" style="background: url(https://alidaunia.it/img/linea.svg) center bottom / contain no-repeat;">
								<img src="https://alidaunia.it/img/logo150px.jpg" style="width:130px">
							</div>
							<div class="mb-8">
								<iframe src="https://booking.alidaunia.it/pdf/docs/privacy-it.htm" style="width:100%; height:400px"/>
							</div>
						</div>
					</modal>

					<!-- modal informativa -->
					<modal name="modal-privacy-en" ref="modal-privacy-en" height="auto" class="w-full" :pivot-y="0.3">
						<div class="bg-white rounded m-4 max-h-full text-center">
							<div class="text-left mb-4" style="background: url(https://alidaunia.it/img/linea.svg) center bottom / contain no-repeat;">
								<img src="https://alidaunia.it/img/logo150px.jpg" style="width:130px">
							</div>
							<div class="mb-8">
								<iframe src="https://booking.alidaunia.it/pdf/docs/privacy-en.htm" style="width:100%; height:400px"/>
							</div>
						</div>
					</modal>

					<!-- modal di redirect manuale verso BancaSella -->
					<!--
					<modal name="modal-redirect" ref="modal-redirect" height="auto" :pivot-y="0.3" class="w-full">
						<div class="bg-white rounded m-4 max-h-full text-center">
							<div class="text-left mb-4" style="background: url(https://alidaunia.it/img/linea.svg) center bottom / contain no-repeat;">
								<img src="https://alidaunia.it/img/logo150px.jpg" style="width:130px">
							</div>
							<div class="mt-8 flex flex-col">
								<p class="border border-gray-400 p-4 mb-4 text-justify leading-loose rounded">
									<span v-if="lang == 'it'">
										Se il browser non ti reindirizza in automatico verso il server di Banca Sella, per favore clicca il pulsante di seguito.
									</span>
									<span v-if="lang == 'en'">
										If the browser doesn't redirect you automatically in a few seconds, please click the button below.
									</span>
								</p>
								<a :href="redirectUrl" class="text-lg text-white bg-blue-800 p-3 rounded no-underline">
									<span v-if="lang == 'it'">Prosegui verso Banca Sella</span>
									<span v-if="lang == 'en'">Continue to Banca Sella</span>
								</a>
							</div>
						</div>
					</modal>
					-->
				</div>
			</div>
		</div>
	</section>
</template>

<script>
// Import component
import Loading from 'vue-loading-overlay'
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css'

import Locales from '../../locales.json'

export default {
	name: 'BookingsWizard',
	components: {
		Loading
	},
	props: {
		lang: {
			type: String,
			default: 'it'
		},
		bloccate: {
			type: String,
			default: '[]'
		}
	},
	data:() => ({
		bookingChannel: 'WEB',
		loading: false,
		isLoading: true,
		step: 1,
		stepMax: 1,
		invoice: {
			requested: false,
			invoice_type: 0,
			p_iva: null,
			business_name: null,
			fiscal_code: null,
			firstname: null,
			lastname: null,
			address: null,
			country: null,
			city: null,
			zip_code: null,
			pec: null,
			fiscal_recipient_code: null
		},
		animal: false,
		flagUnattendedChild: false,
		flagUnattendedChildDisclaimer: false,
		flagPregnant: false,
		flagWeapons: false,
		prenBloccate: [],
		prenBloccataSel: null,
		bloccaPosti: false,
		bloccaPostiNota: null,
		bloccaPostiPannello: false,
		bloccaPostiRisposta: null,
		departureFlights: [],
		departureFlightsCheckDateTime: null,
		comebackFlights: [],
		comebackFlightsCheckDateTime: null,
		departureFlightsLoading: true,
		comebackFlightsLoading: true,
		originPlaces: [
			{ id: 1348, txt: 'Foggia' },
			{ id: 1511, txt: 'Tremiti' },
			{ id: 1514, txt: 'Vieste' },
			{ id: 1529, txt: 'Peschici' }
		],
		destinationPlaces: [
			{ id: 1349, txt: 'Foggia' },
			{ id: 1511, txt: 'Tremiti' },
			{ id: 1514, txt: 'Vieste' },
			{ id: 1529, txt: 'Peschici' }
		],
		maps: {
			origin: {
				'1348_1349': false,
				'1348_1511': true,
				'1348_1514': false,
				'1511_1349': false,
				'1511_1514': false,
				'1514_1349': false,
				'1514_1511': false
			},
			destination: {
				'1348_1349': false,
				'1348_1511': false,
				'1348_1514': false,
				'1511_1349': true,
				'1511_1514': false,
				'1514_1349': false,
				'1514_1511': false
			}
		},
		form: {
			flightType: 'DW',
			originId: 1348,
			destinationId: 1511,
			departureDate: null,
			comebackDate: null,
			departureFlightId: 0,
			comebackFlightId: 0,
			paxes: [],
			totals: {
				tickets: 0,
				ticketsDiscounted: 0,
				taxes: 0,
				landingTaxes: 0,
				grandTotal: 0,
				grandTotalDiscounted: 0
			},
			contacts: {
				email: '',
				email_payment_link: '',
				telephone: ''
			},
			conditions: false,
			coupon: {
				code: null,
				perc: null
			},
			email_forms_pdf: {
				minor: false,
				pregnant: false,
				weapons: false
			}
		},
		fares: {
			departure: null,
			comeback: null
		},
		taxes: {
			departure: null,
			comeback: null
		},
		landingTaxes: {
			departure: 0,
			comeback: 0
		},
		prices: {
			departure: null,
			comeback: null
		},
		departureFlight: null,
		comebackFlight: null,
		coupon: {
			counter: 0,
			message: '',
			status: null
		},
		redirectTimeout: 3,
		redirectUrl: null,
		error_it: null,
		error_en: null,
		show_link: false,
		locales: null,
		cashLocked: true,
		alidauniaLocked: true,
		failedBookings: 0
	}),
	computed: {
		user() {
			return this.$parent.auth.user
		},
		roles() {
			return this.$parent.roles
		},
		locale() {
			return this.locales[this.lang]
		},
		adults() {
			return this.men + this.women
		},
		men() {
			return this.form.paxes.filter(p => p.category == 'a' && p.gender == 'M').length
		},
		women() {
			return this.form.paxes.filter(p => p.category == 'a' && p.gender == 'F').length
		},
		children() {
			return this.form.paxes.filter(p => p.category == 'c').length
		},
		infants() {
			return this.form.paxes.filter(p => p.category == 'i').length
		},
		animals() {
			return this.form.paxes.filter(p => p.category == 'an').length
		},
		origin() {
			var originId = this.form.originId
			if (! originId) { return '' }
			return this.originPlaces.filter(p => p.id == originId)[0].txt
		},
		originAbbr() {
			if (this.origin == '') {
				return ''
			}
			else {
				if (this.origin === 'Foggia') { return 'FG'}
				if (this.origin === 'Tremiti') { return 'TR'}
				if (this.origin === 'Vieste') { return 'VI'}
			}
		},
		destination() {
			var destinationId = this.form.destinationId
			if (! destinationId) { return '' }
			return this.destinationPlaces.filter(p => p.id == destinationId)[0].txt
		},
		destinationAbbr() {
			if (this.destination == '') {
				return ''
			}
			else {
				if (this.destination === 'Foggia') { return 'FG'}
				if (this.destination === 'Tremiti') { return 'TR'}
				if (this.destination === 'Vieste') { return 'VI'}
			}
		},
		maxInfants() {
			return Math.ceil((this.men + this.women) / 2)
		},
		totPax() {
			return parseInt(this.men) + parseInt(this.women) + parseInt(this.children) + (this.animal ? 1 : 0)
		},
		departureDate() {
			if (this.form.departureDate) {
				moment.locale(this.lang)
				var formattedDate = moment(this.form.departureDate, 'DD/MM/YYYY')
				return formattedDate.format('dddd') + '<br>' + formattedDate.format('DD/MM/YYYY')
			}
		},
		comebackDate() {
			if (this.form.comebackDate) {
				moment.locale(this.lang)
				var formattedDate = moment(this.form.comebackDate, 'DD/MM/YYYY')
				return formattedDate.format('dddd') + '<br>' + formattedDate.format('DD/MM/YYYY')
			}
		},
		departureFlightsHaveExtra() {
			return this.checkRoles(['Admin', 'Prenotazioni', 'PrenotaFull']) && this.departureFlights.filter(f => f.extra).length
		},
		comebackFlightsHaveExtra() {
			return this.checkRoles(['Admin', 'Prenotazioni', 'PrenotaFull']) && this.comebackFlights.filter(f => f.extra).length
		},
		costs() {
			// var tickets = 
			// 	parseFloat(this.form.paxes.men.reduce(p => p.ticket)).toFixed(2)
			return {
				tickets: 0,
				taxes: 0,
				landingTaxes: 0,
				total: 0
			}
		},
		thresholds() {
			if (! this.form.departureDate) {
				return {
					adult: null,
					child: null,
					adult_100: null,
					adult_18: null
				}
			}
			else {
				return {
					adult: moment(this.form.departureDate, 'DD/MM/YYYY').clone().subtract(12, 'years'),
					child: moment(this.form.departureDate, 'DD/MM/YYYY').clone().subtract(2, 'years'),
					adult_100: moment(this.form.departureDate, 'DD/MM/YYYY').clone().subtract(100, 'years'),
					adult_18: moment(this.form.departureDate, 'DD/MM/YYYY').clone().subtract(18, 'years')
				}
			}
		},
		logged() {
			return this.user.logged
		},
		// showMinorAlert() {
		// 	return ! this.adults && this.children
		// },
		byBirthdateAdultsOver18() {
			return this.form.paxes.filter(p => {
				let status = false
				if (p.birthDate) {
					let birthDate = moment(p.birthDate, 'DD/MM/YYYY')
					if (birthDate.format('DD/MM/YYYY') == p.birthDate) {
						if (birthDate <= this.thresholds.adult_18) {
							status = true
						}
					}
				}
				return status
			}).length
		},
		byBirthdateWomen() {
			return this.form.paxes.filter(p => {
				let status = false
				if (p.birthDate) {
					let birthDate = moment(p.birthDate, 'DD/MM/YYYY')
					if (birthDate.format('DD/MM/YYYY') == p.birthDate) {
						if (birthDate <= this.thresholds.adult_18 && p.gender == 'F') {
							status = true
						}
					}
				}
				return status
			}).length
		},
		byBirthdateMinors() {
			return this.form.paxes.filter(p => {
				let status = false
				if (p.birthDate) {
					let birthDate = moment(p.birthDate, 'DD/MM/YYYY')
					if (birthDate.format('DD/MM/YYYY') == p.birthDate) {
						if (birthDate > this.thresholds.adult_18) {
							status = true
						}
					}
				}
				return status
			}).length
		},
		byBirthdateMinorsAlert() {
			let adultsOver18 = this.byBirthdateAdultsOver18
			let minors = this.byBirthdateMinors
			if (minors && ! adultsOver18) {
				return true
			}
			else {
				return false
			}
		},
		byBirthdateWomenPregnantAlert() {
			return this.byBirthdateWomen && this.flagPregnant
		}
	},
	watch: {
		animal(val) {
			if (val) {
				var sign = '+'
			}
			else {
				var sign = '-'	
			}
			this.modifyPaxNum('an', sign, false)
		},
		flagUnattendedChildDisclaimer(val) {
			this.form.email_forms_pdf.minor = val
		},
		cashLocked(val) {
			if (! val) {
				this.alidauniaLocked = true
			}
		},
		alidauniaLocked(val) {
			if (! val) {
				this.cashLocked = true
			}
		},
		flagUnattendedChild(val) {
			this.form.paxes = []
			if (! val) {
				this.fetchUser()
			}
		},
		'form.flightType'(val) {
			if (val === 'OW') {
				this.form.comebackDate = null
			}
		},
		'form.originId'(val) {
			var orig = this.form.originId + '_' + this.form.destinationId
			var dest = (this.form.destinationId == 1349 ? 1348 : this.form.destinationId)  + '_' + (this.form.originId == 1348 ? 1349 : this.form.originId)
			Object.keys(this.maps.origin).forEach(k => this.maps.origin[k] = false)
			Object.keys(this.maps.destination).forEach(k => this.maps.destination[k] = false)
			this.maps.origin[orig] = true
			this.maps.destination[dest] = true
			// console.info('O', orig, dest)
		},
		'form.destinationId'(val) {
			var orig = this.form.originId + '_' + this.form.destinationId
			var dest = (this.form.destinationId == 1349 ? 1348 : this.form.destinationId)  + '_' + (this.form.originId == 1348 ? 1349 : this.form.originId)
			Object.keys(this.maps.origin).forEach(k => this.maps.origin[k] = false)
			Object.keys(this.maps.destination).forEach(k => this.maps.destination[k] = false)
			this.maps.origin[orig] = true
			this.maps.destination[dest] = true
			// console.info('D', orig, dest)
		},
		'form.departureDate'(val) {
			this.stepMax = 2
			if (this.step == 2) {
				this.searchFlights('D')
			}
		},
		'form.comebackDate'(val) {
			this.stepMax = 2
			if (this.step == 2) {
				this.searchFlights('C')
			}
		},
		'form.departureFlightId'(val) {
			if (val) {
				var flight = this.departureFlights.filter(f => f.flightId == val)[0]
				this.departureFlight = flight
			}
			else {
				this.departureFlight = null
			}
		},
		'form.comebackFlightId'(val) {
			if (val) {
				var flight = this.comebackFlights.filter(f => f.flightId == val)[0]
				this.comebackFlight = flight
			}
			else {
				this.comebackFlight = null
			}
		},
		'form.paxes': {
			handler() {
				var tickets = this.form.paxes.reduce((a, b) => { return a + b.ticket_nodsc }, 0)
				this.form.totals.tickets = tickets
				var taxes = this.form.paxes.reduce((a, b) => { return a + b.tax }, 0)
				this.form.totals.taxes = taxes
				var landingTaxes = this.form.paxes.reduce((a, b) => { return a + b.landingTax }, 0)
				this.form.totals.landingTaxes = landingTaxes

				this.form.totals.grandTotal = parseFloat(tickets + taxes + landingTaxes)
			},
			deep: true
		},
		'invoice.invoice_type'() {
			this.invoice.p_iva = null
			this.invoice.business_name = null
			this.invoice.fiscal_code = null
			this.invoice.fiscal_recipient_code = null
		},
		step(val, oldVal) {
			if (val == 1) {
				this.stepMax = 1
			}
			if (val == 2 && oldVal == 1) {
				this.processStep1()
			}
			if (val == 4) {
				this.form.contacts.email_payment_link = this.form.contacts.email
			}
		},
		// showMinorAlert(val) {
		// 	if (! val) {
		// 		this.form.email_forms_pdf.minor = false
		// 		this.flagUnattendedChild = false
		// 	}
		// },
		women(val, oldVal) {
			if (val == 0 && oldVal == 1) {
				this.form.email_forms_pdf.pregnant = false
			}
		}
	},
	methods: {
		hasAnyRole(roles) {
			return this.$parent.hasAnyRole(roles)
		},
		vueTippyPaxCell(uuid)	{
			return {
				allowHTML: true,
				content: '#pax-costs-' + uuid,
				arrow: true
			}
		},			
		rempax(pax) {
			this.form.paxes = this.form.paxes.filter(p => p._uuid != pax._uuid)
			// alert(JSON.stringify(pax))
		},
		async cancellaPrenBloccata(prenBloccata) {
			this.isLoading = true

			let bookingCode = prenBloccata.CodPrenotazione
			let userId = this.user.userId

			this.prenBloccataSel = null

			let { data } = await axios.post('/api/rem-blocco-posti', {
				bookingCode,
				userId
			})
			if (data && data.hasOwnProperty('status') && data.status == 'success') {
				var bloccate = JSON.parse(this.bloccate)
				this.prenBloccate = this.prenBloccate.filter(pb => pb.CodPrenotazione != bookingCode)
				this.isLoading = false
				notifyS(`Prenotazione ${bookingCode} eliminata!`)
			}
			else if (data && data.hasOwnProperty('status') && data.status == 'error') {
				this.isLoading = false
				notifyE(data.message)
			}
			else {
				this.isLoading = false
				notifyE(`Errore di sistema`)
			}
		},
		async selPrenBloccata(prenBloccata) {

			this.isLoading = true

			function generateUUID() {
				var d = new Date().getTime()
				var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
					var r = (d + Math.random()*16)%16 | 0
					d = Math.floor(d/16)
					return (c=='x' ? r : (r&0x3|0x8)).toString(16)
				})
				return uuid
			}

			this.prenBloccataSel = prenBloccata.CodPrenotazione

			this.form.flightType = prenBloccata.DataRit ? 'DW' : 'OW';
			this.form.originId = prenBloccata.idProvenienza;
			this.form.destinationId = prenBloccata.idDestinazione;
			this.form.departureDate = prenBloccata.DataAnd;
			if (this.form.flightType == 'DW') {
				this.form.comebackDate = prenBloccata.DataRit;
			}
			if (prenBloccata.AdM) {
				for (var i=1; i<=prenBloccata.AdM; i++) {
					this.form.paxes.push({
						"_uuid":generateUUID(),"logged":false,"id":0,"category":"a","tktCategory":"AD","lastname":"","firstname":"","gender":"M","birthDate":"","residence":"","document_type":0,"document_num":"","email":"","telephone":"","fare":"","exemption":0,"dsc":0,"ticket":0,"ticket_nodsc":0,"tax":0,"landingTax":0,"total":0,"total_nodsc":0
					})
				}
			}
			if (prenBloccata.AdF) {
				for (var i=1; i<=prenBloccata.AdF; i++) {
					this.form.paxes.push({
						"_uuid":generateUUID(),"logged":false,"id":0,"category":"a","tktCategory":"AD","lastname":"","firstname":"","gender":"F","birthDate":"","residence":"","document_type":0,"document_num":"","email":"","telephone":"","fare":"","exemption":0,"dsc":0,"ticket":0,"ticket_nodsc":0,"tax":0,"landingTax":0,"total":0,"total_nodsc":0
					})
				}
			}
			if (prenBloccata.Ch) {
				for (var i=1; i<=prenBloccata.Ch; i++) {
					this.form.paxes.push({
						"_uuid":generateUUID(),"logged":false,"id":0,"category":"c","tktCategory":"CH","lastname":"","firstname":"","gender":"","birthDate":"","residence":"","document_type":0,"document_num":"","email":"","telephone":"","fare":"","exemption":0,"dsc":0,"ticket":0,"ticket_nodsc":0,"tax":0,"landingTax":0,"total":0,"total_nodsc":0
					})
				}
			}
			if (prenBloccata.Inf) {
				for (var i=1; i<=prenBloccata.Inf; i++) {
					this.form.paxes.push({
						"_uuid":generateUUID(),"logged":false,"id":0,"category":"i","tktCategory":"INF","lastname":"","firstname":"","gender":"","birthDate":"","residence":"","document_type":0,"document_num":"","email":"","telephone":"","fare":"","exemption":0,"dsc":0,"ticket":0,"ticket_nodsc":0,"tax":0,"landingTax":0,"total":0,"total_nodsc":0
					})
				}
			}

			this.form.departureFlightId = prenBloccata.idDepFlight
			this.form.comebackFlightId = prenBloccata.idComFlight

			let { data } = await api.get('api.flights.index', {
				children: 0,
				comebackDate: this.form.comebackDate,
				departureDate: this.form.departureDate,
				destination: prenBloccata.Destinazione,
				infants: 0,
				men: 1,
				origin: prenBloccata.Provenienza,
				reverse: false,
				women: 0
			})
			if (data) {
				this.fares.departure = data.fares
				this.taxes.departure = data.taxes
				this.landingTaxes.departure = data.landingTax
				this.prices.departure = data.prices
			}


			if (this.form.comebackDate) {
				let { data } = await api.get('api.flights.index', {
					children: 0,
					comebackDate: this.form.comebackDate,
					departureDate: this.form.departureDate,
					destination: prenBloccata.Destinazione,
					infants: 0,
					men: 1,
					origin: prenBloccata.Provenienza,
					reverse: true,
					women: 0
				})
				if (data) {
					this.fares.comeback = data.fares
					this.taxes.comeback = data.taxes
					this.landingTaxes.comeback = data.landingTax			
					this.prices.comeback = data.prices
				}
			}

			this.step = 3
			this.isLoading = false
		},
		checkRoles(roles) {
			if (! roles) { roles = [] }
			function intersect(nums1, nums2) {
				var m = new Map()				
				nums1.forEach(a => m.set(a, (m.get(a) || 0) + 1))
				return nums2.filter(a => m.get(a) && m.set(a, m.get(a) - 1))
			}
			return intersect(this.roles, roles).length > 0
		},
		async calculateGrandTotal() {
			try {
				let form = {
					...form,
					categories: {
						men: this.men,
						women: this.women,
						children: this.children,
						infants: this.infants						
					}
				}
				let payload = {
					type: 1,
					form
				}
				let { data } = await api.post('api.guest.grandtotal', payload)
				console.log(data)
			}
			catch (error) {
				callErrors(error)
			}
		},
		async storeBooking(type) {

			let { invoice } = this

			if (invoice.requested)
			{
				// azienda
				if (invoice.invoice_type == 0)
				{
					if (! invoice.p_iva || ! invoice.business_name)
					{
						notifyW('Fattura: i campi "Partita IVA" e "Ragione sociale" sono obbligatori')
						return
					}
				}

				// persona fisica
				if (invoice.invoice_type == 1)
				{
					if ((! invoice.p_iva && ! invoice.fiscal_code) || (invoice.p_iva && invoice.fiscal_code))
					{
						notifyW('Fattura: solo un campo richiesto tra "Partita IVA" e "Codice fiscale"')
						return
					}

					if (! invoice.firstname || ! invoice.lastname)
					{
						notifyW('Fattura: i campi "Nome" e "Cognome" sono obbligatori')
						return
					}
				}

				if (! invoice.address || ! invoice.city || ! invoice.zip_code)
				{
					notifyW('Fattura: i campi "Indirizzo", "Città" e "CAP" sono obbligatori')
					return
				}

				if (! invoice.pec && ! invoice.fiscal_recipient_code)
				{
					notifyW('Fattura: almeno un campo richiesto tra "PEC" e "Codice destinatario"')
					return
				}
			}

			function validateEmail(email) {
				var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
				return re.test(String(email).toLowerCase());
			}

			if (type == -1) {
				if (! validateEmail(this.form.contacts.email_payment_link)) {
					notifyW('Email per invio link non valida!<br \>- ricontrollare anche possibili spazi vuoti -')
					return
				}
			}

			this.isLoading = true

			try {
				var form = this.form
				if (this.prenBloccataSel) {
					form.prenBloccataSel = this.prenBloccataSel
				}
				form.categories = {
					men: this.men,
					women: this.women,
					children: this.children,
					infants: this.infants
				}

				let { invoice, bookingChannel } = this

				let paxesList = clone(this.form.paxes).map(p => {

					let pax = {
						id: p.id,
						category: p.category,
						gender: p.gender,
						birthDate: p.birthDate,
						residence: p.residence,
						document_type: p.document_type,
						document_num: p.document_num,
						email: p.email,
						telephone: p.telephone,
						exemption: p.exemption,
						animal_owner_key: p.hasOwnProperty('animal_owner_key') ? p.animal_owner_key : null
					}

					if (p.id < 0) {
						pax = { ...pax, ...{
								lastname: p.lastname,
								firstname: p.firstname,
								gender: p.gender
							}
						}
					}

					if (this.checkRoles(['Prenotazioni', 'PrenotaFull'])) {
						pax = { ...pax, ...{
								dsc: p.dsc
							}
						}
					}

					return pax
				})

				form = {
					originId: form.originId,
					destinationId: form.destinationId,
					departureFlightId: form.departureFlightId,
					comebackFlightId: form.comebackFlightId,
					contacts: {
						email: form.contacts.email,
						email_payment_link: form.contacts.email_payment_link,
						telephone: form.contacts.telephone
					},
					coupon: form.coupon.code,
					email_forms_pdf: form.email_forms_pdf,
					paxes: paxesList
				}

				dbg(form)

				let payload = {
					type,
					form,
					invoice,
					bookingChannel
				}

				if (type == 4) {
					payload.bloccaPostiNota = this.bloccaPostiNota
				}

				let { data } = await api.post('api.bookings.store', payload)
				dbg(data, 'HERE')
				window.scrollTo(0, 0)

				if (data.hasOwnProperty('status') && data.status == 'success') {

					if (data.hasOwnProperty('bank_link')) {
						if (data.type == 1) {
							location.href = data.bank_link
							return
						}
						else if (data.type == -1) {
							this.redirectUrl = data.bank_link
							this.show_link = true
							this.isLoading = false
							this.step = 42							
							return
						}
					}

					var url = location.origin + '/booking-status/' + btoa(data.bookingCode)
					this.redirectUrl = url
					window.location = url

					setInterval(() => {
						this.redirectTimeout--
						dbg(this.redirectTimeout)
						if (this.redirectTimeout <= 0) {
							this.isLoading = false
							this.step = 40
						}
					}, 1000)
				}

				if (data.hasOwnProperty('status') && data.status == 'error') {
					this.error_it = data.message_it
					this.error_en = data.message_en
					this.isLoading = false
					this.step = 41
				}

				if (data.hasOwnProperty('status') && data.status == 'block-confirmed') {
					this.isLoading = false
					this.step = 1

					this.bloccaPostiRisposta = data.message
					this.bloccaPostiPannello = true
				}

				this.isLoading = false
			}
			catch (error) {
				callErrors(error)
				if (error.response.status == 401) {
					this.goToStep(401)
				}				

				this.isLoading = false
			}
		},
		applyCoupon() {
			this.form.paxes.forEach(p => {
				p.dsc = parseInt(this.form.coupon.perc)
			})
		},
		async checkCoupon() {
			let { lang } = this
			try {
				let { coupon: { code }, totals: { tickets, grandTotal } } = this.form

				let payload = {
					coupon: this.form.coupon.code,
					tickets: parseFloat(this.form.totals.tickets).toFixed(2),
					tot: parseFloat(this.form.totals.grandTotal).toFixed(2),
					att: this.coupon.counter
				}

				let { data } = await api.post('api.check-coupon', payload)
				if (data) {
					dbg(data, 'check coupon')
					let message = (lang == 'it' ? data.message_it : data.message_en)
					this.coupon.message = message
					if (data.hasOwnProperty('status') && data.status == 'success') {
						this.form.coupon.perc = data.perc
						this.form.totals.ticketsDiscounted = data.new_tkts_total
						this.form.totals.grandTotalDiscounted = data.new_grand_total
						this.coupon.counter = 0
						this.coupon.status = true
						this.form.paxes.forEach(p => {
							var tkt_dsc = p.ticket - (p.ticket / 100 * data.perc)
							p.dsc = data.perc
							p.ticket = parseFloat(tkt_dsc).toFixed(2)
							p.total = parseFloat(p.ticket_nodsc - tkt_dsc + p.tax + p.landingTax).toFixed(2)
						})
						notifyS(message)
					}
					else if (data.hasOwnProperty('status') && data.status == 'error') {
						dbg(data.att)
						this.coupon.counter = data.att
						this.coupon.status = false
						notifyE(message)						
					}
				}
			}
			catch (error) {
				dbg(error)
				callErrors(error)
				notifyE('Server error. Discount not available.')
			}
		},
		updatePax(obj, index) {
			this.form.paxes[index] = obj
			// console.info(index, obj)
			// if (obj.lastname == '') { return }

			// if (obj.extra) {
			// 	if (obj.extra.firstname !== undefined) {
			// 		this.form.paxes[index] = obj
			// 	}
			// 	else {
			// 		this.form.lastname = obj.extra.lastname
			// 	}
			// }
		},
		openModalConditions() {
			this.$refs['modal-conditions-' + this.lang].$modal.show('modal-conditions-' + this.lang)
		},
		closeModalConditions() {
			console.info('hide')
			this.$refs['modal-conditions'].$modal.hide('modal-conditions')
		},
		openModalPrivacy() {
			this.$refs['modal-privacy-' + this.lang].$modal.show('modal-privacy-' + this.lang)
		},
		closeModalPrivacy() {
			console.info('hide')
			this.$refs['modal-privacy'].$modal.hide('modal-privacy')
		},
		formattedPrice(val) {
			if (isNaN(val)) { return '0.00' }
			return parseFloat(val).toFixed(2)
		},
		modifyPaxNum(paxCategory, sign, logged = false) {

			console.log(paxCategory, sign, logged)

			if (sign == '-') {
				if (this.user.pax && this.user.pax.gender == 'M' && paxCategory == 'm' && ! this.hasAnyRole('Prenotazioni|PrenotaFull') && this.men == 1 && ! this.flagUnattendedChild) {
					return false
				}
				if (this.user.pax && this.user.pax.gender == 'F' && paxCategory == 'w' && ! this.hasAnyRole('Prenotazioni|PrenotaFull') && this.women == 1 && ! this.flagUnattendedChild) {
					return false
				}
			}
			
			var pax = {
				_uuid: generateUUID(),
				logged,
				id: 0,
				category: '',
				tktCategory: '',
				
				lastname: '',
				firstname: '',
				gender: '',
				birthDate: '',
				residence: '',
				document_type: 0,
				document_num: '',
				email: '',
				telephone: '',

				fare: '',
				exemption: 0,
				dsc: 0,
				ticket: 0,
				ticket_nodsc: 0,
				tax: 0,
				landingTax: 0,
				total: 0,
				total_nodsc: 0
			}

			var usersLogged = this.form.paxes.filter(p => p.logged)
			var userLogged = usersLogged.length ? usersLogged[0] : { category: null, gender: null }

			var userLoggedCategory = ''
			if (usersLogged.length) {
				userLoggedCategory = usersLogged[0].category + usersLogged[0].gender
			}

			// dbg(userLogged, 'logged')
			// dbg(userLoggedCategory, 'loggedCategory')

			function addPax (paxes, pax, category, gender) {
				if (category == 'a') {
					pax.tktCategory = 'AD'
				}
				else if (category == 'c' || category == 'an') { // animals same fare as children
					pax.tktCategory = 'CH'
				}
				else if (category == 'i') {
					pax.tktCategory = 'INF'
				}

				pax.category = category
				pax.gender = gender

				if (category == 'an') {
					pax.id = 100
				}

				paxes.push(pax)
				return paxes
			}

			function removePax (paxes, loggedCategory, category, gender) {
				var categoryGenderPaxes = paxes.filter(p => p.category == category && p.gender == gender)
				var tot = categoryGenderPaxes.length
				var category_gender = category + gender
				if (tot == 0) { return paxes }
				// if (tot == 1 && loggedCategory == category_gender) { return paxes }

				var uuid = categoryGenderPaxes.reverse()[0]._uuid
				paxes = paxes.filter(p => p._uuid != uuid)
				return paxes
			}

			if (paxCategory == 'm') {
				if (sign == '+') {
					this.form.paxes = addPax(this.form.paxes, pax, 'a', 'M')
				}
				else {
					this.form.paxes = removePax(this.form.paxes, userLoggedCategory, 'a', 'M')
				}
			}

			if (paxCategory == 'w') {
				if (sign == '+') {
					this.form.paxes = addPax(this.form.paxes, pax, 'a', 'F')
				}
				else {
					this.form.paxes = removePax(this.form.paxes, userLoggedCategory, 'a', 'F')
				}
			}

			if (paxCategory == 'c' || paxCategory == 'i' || paxCategory == 'an') {
				if (sign == '+') {
					this.form.paxes = addPax(this.form.paxes, pax, paxCategory, '')
				}
				else {
					this.form.paxes = removePax(this.form.paxes, userLoggedCategory, paxCategory, '')
				}
			}

			this.sortPaxes()
		},
		sortPaxes() {
			var loggedPax = this.form.paxes.filter(p => p.logged)
			var men = this.form.paxes.filter(p => ! p.logged && p.category == 'a' && p.gender == 'M')
			var women = this.form.paxes.filter(p => ! p.logged && p.category == 'a' && p.gender == 'F')
			var children = this.form.paxes.filter(p => ! p.logged && p.category == 'c')
			var infants = this.form.paxes.filter(p => ! p.logged && p.category == 'i')
			var animals = this.form.paxes.filter(p => ! p.logged && p.category == 'an')

			this.form.paxes = [
				...loggedPax,
				...men,
				...women,
				...children,
				...infants,
				...animals
			]
		},
		changeDay(sign, type) {
			if (type == 'D') {
				if (this.departureFlightsLoading) { return }

				var departureDate = moment(this.form.departureDate, "DD/MM/YYYY")
				if (sign == '-') {
					if (departureDate.format('DD/MM/YYYY') == moment().startOf('day').format('DD/MM/YYYY')) { return }
					departureDate.subtract(1, 'day')
				}
				else if (sign == '+') {
					departureDate.add(1, 'day')
				}
				this.form.departureFlightId = 0
				this.form.departureDate = departureDate.format('DD/MM/YYYY')

				var comebackDate = moment(this.form.comebackDate, "DD/MM/YYYY")

				if (departureDate > comebackDate) {
					this.form.comebackDate = this.form.departureDate
				}
			}
			if (type == 'C') {
				if (this.comebackFlightsLoading) { return }

				var comebackDate = moment(this.form.comebackDate, "DD/MM/YYYY")
				if (sign == '-') {
					comebackDate.subtract(1, 'day')
				}
				else if (sign == '+') {
					comebackDate.add(1, 'day')
				}

				var departureDate = moment(this.form.departureDate, "DD/MM/YYYY")

				if (comebackDate < departureDate) {
					return
				}

				this.form.comebackFlightId = 0
				this.form.comebackDate = comebackDate.format('DD/MM/YYYY')
			}
		},
		validateStep(step) {
			if (step == 1) {
				return this.validateStep1()
			}
			else if (step == 2) {
				return this.validateStep2()
			}
			else if (step == 3) {
				return this.validateStep3()
			}
			else if (step == 400) {
				return true
			}
		},
		validateStep1() {
			var locale = this.locale
			var { flightType, originId, destinationId, departureDate, comebackDate } = this.form
			var men = this.men
			var women = this.women
			var children = this.children
			var infants = this.infants
			var totPax = this.totPax
			var flagUnattendedChild = this.flagUnattendedChild
			var maxInfants = this.maxInfants

			if (! originId || ! destinationId) {
				console.info(originId, destinationId)
				notifyW(locale.errorOrigDestMissing)
				return false
			}
			if (originId === destinationId) {
				console.info(originId, destinationId)
				notifyW(locale.errorOrigDestIdentical)
				return false
			}
			if (! departureDate) {
				notifyW(locale.errorDepartureDateMissing)
				return false
			}
			if (flightType == 'DW' && ! comebackDate) {
				notifyW(locale.errorComebackDateMissingForDW)
				return false
			}
			if (flightType == 'DW' && moment(comebackDate, "DD/MM/YYYY") < moment(departureDate, "DD/MM/YYYY")) {
				notifyW(locale.errorComebackSmallerThanDeparture)
				return false
			}
			if (false && moment(departureDate, "DD/MM/YYYY") < moment().startOf('day') || false && moment(comebackDate, "DD/MM/YYYY") < moment().startOf('day')) {
				notifyW(locale.errorUnselectableDates)
				return false
			}
			if (! totPax) {
				notifyW(locale.errorEmptyPaxes)
				return false
			}
			if (infants > maxInfants) {
				notifyW(`
					${locale.errorInfantsNum}:<br>
					${this.adults} ad. => max ${maxInfants}
				`)
				return false
			}

			if (this.logged && this.user.gender == 'M' && this.men == 0 && this.women > 0) {
				notifyW(locale.errorMissingLoggedCategoryM)
				return false
			}

			if (this.logged && this.user.gender == 'F' && this.women == 0 && this.men > 0) {
				notifyW(locale.errorMissingLoggedCategoryF)
				return false
			}

			if (! this.flagUnattendedChild && ! this.adults) {
				notifyW('Hai selezionato bambini senza adulti.<br>Modifica i passeggeri oppure spunta `prenotazione per "minore non accompagnato"` per procedere con soli minori.')
				return false
			}

			if (this.flagUnattendedChild && ! this.flagUnattendedChildDisclaimer) {
				notifyW(locale.errorMissingMinorDisclaimer)
				return false
			}

			this.stepMax = 2

			return true
		},
		validateStep2() {
			var locale = this.locale
			var { flightType, departureFlightId, comebackFlightId } = this.form

			if (! departureFlightId) {
				notifyW(locale.errorDepartureFlightMissing)
				return false
			}
			if (flightType == 'DW') {
				if (! comebackFlightId) {
					notifyW(locale.errorComebackFlightMissing)
					return false
				}

				var departureDate = moment(this.departureFlight.takeOffDateTime)
				var comebackDate = moment(this.comebackFlight.takeOffDateTime)

				if (departureDate > comebackDate) {
					notifyW(locale.errorComebackFlightNotFollowingDeparture)
					return false
				}

				if (departureFlightId == comebackFlightId) {
					notifyW('Non è possibile selezionare lo stesso volo per andata e ritorno')
					return false
				}
			}
			if (false && departureDate < moment().startOf('day') || false && comebackDate < moment().startOf('day')) {
				notifyW(locale.errorUnselectableDates)
				return false
			}

			this.stepMax = 3

			return true
		},
		validateStep3() {
			var locale = this.locale
			var { paxes, contacts, conditions } = this.form

			var errors = []

			function validateEmail(email) {
				var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
				return re.test(String(email).toLowerCase());
			}

			// VALIDATE PAXES
			var globalValid = true
			var adult_18 = false

			paxes.forEach((p,i) => {
				if (p.category != 'an') {
					if (! p.lastname || ! p.firstname || ! p.gender) {
						errors.push('<li>pax ' + (i + 1) + '</li>')
						globalValid = false
					}
				}
			})
			if (! globalValid) {
				notifyW(`${locale.errorPaxesIncomplete}` + '<ul>' + errors.join('') + '</ul>')
				return
			}

			// VALIDATE PAX-AGE	
			globalValid = true
			paxes.forEach((p,i) => {
				if (p.category != 'an') {
					var validAge = this.checkAge(p.category, p.birthDate)
					if (globalValid && ! validAge) {
						errors.push('<li>pax ' + (i + 1) + '</li>')
						globalValid = false
					}
					var paxBirthDate = moment(p.birthDate, 'DD/MM/YYYY')
					if (globalValid && paxBirthDate < this.thresholds.adult_100) {
						errors.push('<li>pax ' + (i + 1) + '</li>')
						globalValid = false
					}
					if (paxBirthDate <= this.thresholds.adult_18) {
						adult_18 = true
					}
				}
			})
			if (! globalValid) {
				notifyW('le seguenti date di nascita non risultano valide:<br><ul>' + errors.join('') + '</ul>')
				return
			}

			// VALIDATE PAX-DOCUMENTS
			globalValid = true
			// var atLeastOneDocumentRegistered = false
			paxes.forEach(p => {
				if (p.category != 'an') {
					if (globalValid && (p.document_type == 0 || ! p.document_num)) {
						globalValid = false
					}
				}
			})
			if (! globalValid) {
				notifyW('per tutti i passeggeri è obbligatorio un documento')
				return
			}

			// VALIDATE PAX-EMAIL
			globalValid = true
			paxes.forEach(p => {
				if (p.category != 'an') {
					if (p.email && p.email.length) {
						var validEmail = validateEmail(p.email)
						if (globalValid && ! validEmail) { globalValid = false }
					}
				}
			})
			if (! globalValid) {
				notifyW('email passeggeri non valide!')
				return
			}

			// VALIDATE CONTACTS
			if (! contacts.email || ! contacts.telephone) {
				notifyW('inserire recapiti email e telefono')
				return false
			}

			if (! validateEmail(contacts.email)) {
				notifyW('email di contatto non valida')
				return false
			}


			// VALIDATE CONDITIONS
			if (! conditions) {
				notifyW(locale.errorConditions)
				return false
			}

			if (! this.flagUnattendedChild && ! adult_18) {
				notifyW(`almeno un passeggero dev'essere maggiorenne alla data del volo`)
				return
			}

			if (this.flagUnattendedChild && adult_18) {
				notifyW(`non sono previsti adulti per prenotazioni "minori non accompagnati"`)
				return
			}

			this.stepMax = 4

			return globalValid
		},
		checkAge(paxCategory, paxBirthDate) {
			// var paxCategory = this.form.category
			var paxBirthDate = moment(paxBirthDate, 'DD/MM/YYYY')
			
			var threshold_Ad = this.thresholds.adult
			var threshold_Ch = this.thresholds.child
			
			var valid = false

			if (paxCategory == 'a') {
				// console.info('check Ad')
				valid = paxBirthDate <= threshold_Ad
			}
			if (paxCategory == 'c') {
				// console.info('check Ch')
				valid = paxBirthDate > threshold_Ad && paxBirthDate <= threshold_Ch
			}
			if (paxCategory == 'i') {
				// console.info('check Inf')
				valid = paxBirthDate > threshold_Ch
			}

			return valid
		},
		async searchFlights(type) {
			let reverse = false

			if (type == 'D') {
				this.departureFlightsLoading = true
				this.departureFlightsCheckDateTime = null
			}
			else if (type == 'C') {
				this.comebackFlightsLoading = true
				this.comebackFlightsCheckDateTime = null
				reverse = true
			}

			var origin = this.originPlaces.filter(p => p.id == this.form.originId)[0].txt
			var destination = this.destinationPlaces.filter(p => p.id == this.form.destinationId)[0].txt
	
			var { flightType, departureDate, comebackDate } = this.form		
			
			var men = this.men
			var women = this.women
			var children = this.children + this.animals
			var infants = this.infants

			var payload = { 
				reverse,
				origin,
				destination,
				departureDate,
				comebackDate,
				men,
				women,
				children,
				infants
			}

			try {
				let { data } = await api.get('api.flights.index', payload)
				if (data) {
					console.info('flights', type)
					if (type == 'D') {
						this.fares.departure = data.fares
						this.taxes.departure = data.taxes
						this.landingTaxes.departure = data.landingTax
						
						this.prices.departure = data.prices

						this.departureFlights = data.flights
						this.departureFlightsCheckDateTime = moment().format('DD/MM/YYYY - HH:mm:ss')
						
						var firstFlight = data.flights[0]
						if (firstFlight) {
							// this.form.departureFlightId = firstFlight.flightId
						}
						this.departureFlightsLoading = false
					}
					else if (type == 'C') {
						this.fares.comeback = data.fares
						this.taxes.comeback = data.taxes
						this.landingTaxes.comeback = data.landingTax
						
						this.prices.comeback = data.prices
						this.comebackFlights = data.flights
						this.comebackFlightsCheckDateTime = moment().format('DD/MM/YYYY - HH:mm:ss')
						
						var firstFlight = data.flights[0]
						if (firstFlight) {
							// this.form.comebackFlightId = firstFlight.flightId
						}
						this.comebackFlightsLoading = false
					}
				}
			}
			catch (error) {
				callErrors(error)
			}
		},
		processStep1() {
			var flightType = this.form.flightType

			if (flightType == 'OW' || flightType == 'DW') {
				this.searchFlights('D')
			}
			if (flightType == 'DW') {
				this.searchFlights('C')
			}
		},
		goToStep(step, direct = false) {
			if (direct && step > this.stepMax) {
				return
			}

			if (step == 1) {
				this.bloccaPosti = false
			}

			if (step == 2) {
				this.bloccaPosti = false
				this.bloccaPostiNota = null;
				this.bloccaPostiPannello = false;
				this.bloccaPostiRisposta = null;
			}

			console.log(step)
			if (step == 3) {
				this.form.totals.ticketsDiscounted = this.form.totals.tickets
				this.form.totals.grandTotalDiscounted = this.form.totals.grandTotal
				this.form.paxes.forEach(p => {
					p.dsc = 0
					p.ticket = p.ticket_nodsc
					p.total = p.total_nodsc
				})

				this.coupon.code = null
				this.coupon.perc = 0
				this.coupon.status = null

				this.form.coupon.code = null
				this.form.coupon.perc = 0
				this.form.coupon.status = null
			}

			// reset scroll on viewport-top
			window.scrollTo(0, 0)

			// NAVIGATE BACK
			if (step < this.step) {

				if (step == 1) {
					this.form.departureFlightId = 0
					this.form.comebackFlightId = 0
					this.departureFlights = []
					this.comebackFlights = []
				}

				this.step = step

				// if (step == 1) {
				// 	this.stepMax = 1
				// }
				// else if (step == 2) {
				// 	this.stepMax = 2
				// }

				return
			}

			// NAVIGATE FORWARD
			var stepToValidate = step - 1
			if (this.validateStep(stepToValidate)) {
				this.step = step
			}
		},
		fetchUser() {
			this.form.contacts.email = this.user.email
			this.form.contacts.telephone = this.user.telephone

			if (this.user.basic) {
				if (this.user.pax.gender == 'M') {
					this.modifyPaxNum('m', '+', true)
				}
				else if (this.user.pax.gender == 'F') {
					this.modifyPaxNum('w', '+', true)
				}
			}

			this.isLoading = false
		},
		mostraBloccoPosti() {
			if (! this.validateStep2()) {
				return	
			}
			this.bloccaPosti = true
		},
		confermaBloccoPosti() {
			if (! this.validateStep2()) {
				return	
			}

			if (! this.bloccaPostiNota || ! this.bloccaPostiNota.length) {
				notifyW('Motivazione obbligatoria')
				return
			}
			
			this.form.bloccaPostiNota = this.bloccaPostiNota

			this.storeBooking(4)
		},
		async fetchFailedBookings() {
			try {
				this.loading = true

				let { data } = await api.get('api.bookings-failed.index')
				this.failedBookings = data.bookings.length

				this.loading = false
			}
			catch (error) {
				this.loading = false
				callErrors(error)
			}
		}		
	},
	created() {
		this.fetchFailedBookings()

		this.locales = Locales

		if (this.$parent.isLogged === false) {
			this.$router.push({ name: 'login' })
			return	
		}

		if (this.$parent.auth.user.id && ! this.$parent.auth.user.pax) {
			this.$router.push({ name: 'pending' })
			return			
		}

		if (this.$parent.auth.user.id && this.$parent.auth.user.is_agency && ! this.$parent.auth.user.is_agency_auth) {
			this.$router.push({ name: 'pending' })
			return			
		}

		EventBus.$on('booking-step-1', x => {
			if (this.step !== 1) {
				this.goToStep(1)
			}
		})

		if (! this.$parent.auth.user.id) {
			EventBus.$on('user-ready', x => this.fetchUser())		
		}
		else {
			this.fetchUser()
		}

		if (this.bloccate) {
			this.prenBloccate = JSON.parse(this.bloccate)
		}

		if (window.location.href.includes('admin/posti-bloccati')) {
			this.step = 0
		}
	}
}
</script>

<style>
div.iziToast-color-red {
	background: orangered !important;
}
div.iziToast-color-red strong.iziToast-title,
div.iziToast-color-red p.iziToast-message {
	color: #fff !important;
}
</style>
