<template>
	<on-click-outside :do="handleClickOutside">
		<div>
			<div role="button" class="select-none flex items-center" @click="open = !open">
				<slot name="link" />
			</div>
			<div class="relative">
				<div class="w-full absolute pin-l mt-px" v-show="open" style="z-index:1">
					<slot name="dropdown" />
				</div>
			</div>
		</div>
	</on-click-outside>
</template>

<script>
import OnClickOutside from './OnClickOutside.vue'

export default {
	components: {
		OnClickOutside
	},
	data() {
		return {
			open: false
		}
	},
	methods: {
		handleClickOutside() {
			if (this.open) {
				this.open = false
			}
		}
	}
}
</script>
