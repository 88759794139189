<template>
  <div class="mt-4 rounded border border-blue-800 flex flex-col relative">
    <vue-loading :loading="slotLoading" />
    <div class="bg-blue-800 text-white py-1 px-2 flex justify-between">
      <div>
        VOLO <span class="ml-1 bg-white text-blue-600 leading-none rounded-full font-bold" style="padding:0px 6px">{{ label }}</span>
      </div>
      <button v-if="label != '1'" type="button" class="bg-red-600 rounded px-2" @click="$emit('removed')">
        X
      </button>
    </div>
    <div class="flex flex-col md:flex-row p-4" :class="{ 'opacity-75 pointer-events-none': loading }">

      <div class="w-full md:w-1/4 md:border-r md:pr-5 md:mr-5">
        <table class="w-full border">
          <thead>
            <tr class="text-left border-b-2 bg-blue-200 text-blue-600 text-xs">
              <th style="width:85px" class="border px-1">Biglietto</th>
              <th class="border px-1">Passeggero</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="booking in bookings">
              <td class="border px-2 py-1">
                <div class="flex justify-between items-center">
                  {{ booking.ticketNr }}
                  <sup class="ml-2 flex flex-col items-center mt-2 cursor-pointer" :content="supContent(booking)" v-tippy="{ arrow: true }">
                    <span v-if="booking.dw && booking.dw_flight == 'd'">A</span>
                    <span class="mt-2 text-sm" v-if="booking.dw && booking.dw_flight == 'd'">→</span>

                    <span v-if="booking.dw && booking.dw_flight == 'c'">R</span>
                    <span class="mt-2 text-sm" v-if="booking.dw && booking.dw_flight == 'c'">←</span>
                  </sup>
                </div>
              </td>
              <td class="border px-2 py-1">
                {{ booking.lastname}} {{ booking.firstname }}
              </td>
            </tr>
            <tr v-if="! bookings.length">
              <td colspan="2" class="text-center text-xs">
                nessun selezionato
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="flex-1 flex flex-col">
        <div class="flex flex-col md:flex-row">
          <div class="flex flex-row mt-3 md:mt-0">
            <div class="w-32 flex flex-col items-start mr-6">
              <label class="text-xs italic mr-3">Partenza</label>
              <div class="inline-block relative w-full">
                <select v-model="travel.origin" class="block bg-white appearance-none w-full border border-gray-400 hover:border-gray-500 px-2 py-1 pr-8 rounded">
                  <option v-for="heliport in heliports_departure" :key="heliport.id" :value="heliport.id" v-text="heliport.txt"></option>
                </select>
                <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                </div>
              </div>
            </div>
            <div class="w-32 flex flex-col items-start mr-6">
              <label class="text-xs italic mr-3">Destinazione</label>
              <div class="inline-block relative w-full">
                <select v-model="travel.destination" class="block bg-white appearance-none w-full border border-gray-400 hover:border-gray-500 px-2 py-1 pr-8 rounded">
                  <option v-for="heliport in heliports_comeback" :key="heliport.id" :value="heliport.id" v-text="heliport.txt"></option>
                </select>
                <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                </div>
              </div>
            </div>
          </div>
          <div class="flex flex-row mt-3 md:mt-0">
            <div class="flex flex-col items-start mr-6">
              <label class="text-xs italic mr-3">Nuova data volo</label>
              <div class="w-32">
                <vue-datepicker v-model="travel.flightDate" />
              </div>
            </div>
            <div class="flex flex-col items-start mr-6">
              <label class="text-xs italic mr-3">&nbsp;</label>
              <button type="button" @click="search" :disabled="! searchButtonEnabled" class="w-24 flex justify-center items-center text-white text-sm font-semibold p-1 rounded" :class="{ 'bg-gray-300 cursor-not-allowed': ! searchButtonEnabled, 'bg-blue-500 hover:bg-blue-600': searchButtonEnabled }" style="height:31px">
                <span v-if="! loading">cerca volo</span>
                <fa v-else class="s-circle-notch fa-spin" />
              </button>
            </div>
          </div>
          <div v-if="no_flights_available" class="flex flex-col">
            <label>&nbsp;</label>
            <span class="italic text-orange-500">nessun volo disponibile per la data scelta</span>
          </div>
        </div>
        <div class="w-full mt-4 md:mt-0" v-if="flightSelectVisible">
          <div class="flex justify-end mb-2">
            <button v-if="travel.flightId" type="button" @click="travel.flightId = null" class="bg-orange-500 text-white px-2 rounded text-xs leading-tight">annulla<br>selezione volo</button>
          </div>
          <table class="text-left w-full border border-gray" data-cy="departure-flights">
            <thead>
              <tr>
                <th width="1%" class="bg-gray-200 font-sans font-medium text-xs text-gray-dark border-b border-gray-400">&nbsp;</th>
                <th class="py-1 px-2 bg-gray-200 font-sans font-medium text-xs text-gray-dark border-b border-gray-400" v-text="locale.bookingFlight"></th>
                <th v-if="checkRoles(['Admin', 'Prenotazioni', 'PrenotaFull'])" class="hidden md:table-cell py-1 px-2 bg-gray-200 font-sans font-medium text-xs text-gray-dark border-b border-gray-400" v-text="locale.bookingAircraft"></th>
                <th class="hidden md:table-cell py-1 px-2 bg-gray-200 font-sans font-medium text-xs text-gray-dark border-b border-gray-400" v-text="locale.bookingRoute"></th>
                <th class="hidden md:table-cell py-1 px-2 bg-gray-200 font-sans font-medium text-xs text-gray-dark border-b border-gray-400" v-text="locale.bookingOrigin"></th>
                <th class="py-1 px-2 bg-gray-200 font-sans font-medium text-xs text-gray-dark border-b border-gray-400">
                  <span class="hidden md:block">{{ locale.bookingTime }}</span>
                  <span class="block md:hidden">{{ locale.bookingTakeoffAbbr }}</span>
                </th>
                <th class="hidden md:table-cell py-1 px-2 bg-gray-200 font-sans font-medium text-xs text-gray-dark border-b border-gray-400" v-text="locale.bookingDestination"></th>
                <th class="py-1 px-2 bg-gray-200 font-sans font-medium text-xs text-gray-dark border-b border-gray-400">
                  <span class="hidden md:block">{{ locale.bookingTime }}</span>
                  <span class="block md:hidden">{{ locale.bookingLandingAbbr }}</span>
                </th>
                <th v-if="checkRoles(['Admin', 'Prenotazioni', 'PrenotaFull'])" class="hidden md:table-cell py-1 px-2 bg-gray-200 font-sans font-medium text-xs text-gray-dark border-b border-gray-400 text-center">Info</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="loading" class="hover:bg-blue-100">
                <td colspan="9">
                  <div class="flex items-center justify-center py-3">
                    <img src="/img/Heli-loader.gif">
                  </div>
                </td>
              </tr>
              <tr v-if="! loading && flights.length == 0" class="hover:bg-blue-100">
                <td colspan="9" class="text-center px-3 py-3" v-text="locale.noFlightsFound"></td>
              </tr>
              <flight-row
                v-if="! loading && flights.length > 0 && flight.flightId"
                v-for="flight in filteredFlights"
                :key="flight.flightId"
                :flight="flight"
                :id-selected="travel.flightId"
                @checked="({ flightId }) => travel.flightId = flightId"
                @loading-pax-list="status => slotLoading = status"
              />
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Locales from '../../locales.json'
import FlightRow from './../../public/components/FlightRow'

export default {
  name: 'travel-slot',
  components: {
    FlightRow
  },
  props: {
    label: {
      type: Number
    },
    progressive: {
      type: Number
    },
    bookings: {
      type: Array
    }
  },
  data:() => ({
    slotLoading: false,
    loading: false,
    flights: [],
    heliports_departure: [
      {
        id: 1348,
        txt: 'Foggia'
      },
      {
        id: 1511,
        txt: 'Tremiti'
      },
      {
        id: 1514,
        txt: 'Vieste'
      },
      {
        id: 1529,
        txt: 'Peschici'
      }
    ],
    heliports_comeback: [
      {
        id: 1349,
        txt: 'Foggia'
      },
      {
        id: 1511,
        txt: 'Tremiti'
      },
      {
        id: 1514,
        txt: 'Vieste'
      },
      {
        id: 1529,
        txt: 'Peschici'
      }
    ],
    travel: {
      flightDate: null,
      origin: null,
      destination: null,
      flightId: null,
      takeOffDate: null,
      takeOffTime: null,
      paxes: []
    },
    no_flights_available: false
  }),
  computed: {
		locale() {
			return Locales['it']
		},
    searchButtonEnabled() {
      let { bookings } = this
      let { flightDate, origin, destination } = this.travel
      return bookings.length && flightDate && origin && destination
    },
    flightSelectVisible() {
      let { flightDate, origin, destination } = this.travel
      let { flights } = this
      return flightDate && origin && destination && flights.length
    },
    Flights() {
      return this.flights
    },
    filteredFlights() {
      if (this.travel.flightId) {
        return this.flights.filter(f => f.flightId == this.travel.flightId)
      }
      else {
        return this.flights
      }
    },
    bookingsCount() {
      return this.bookings.length
    },
    men() {
      return this.bookings.filter(b => b.movedOn == this.progressive && b.paxCategory == 'AD' && b.gender == 'M').length
    },
    women() {
      return this.bookings.filter(b => b.movedOn == this.progressive && b.paxCategory == 'AD' && b.gender == 'F').length
    },
    children() {
      return this.bookings.filter(b => b.movedOn == this.progressive && ['CH', 'AN'].includes(b.paxCategory)).length
    },
    infants() {
      return this.bookings.filter(b => b.movedOn == this.progressive && b.paxCategory == 'INF').length
    }
  },
  watch: {
    bookingsCount() {
      this.flights = []
      this.travel.flightId = null
    },
    'travel.flightId'(val) {
      var travel

      if (val) {
        let flightSelected = this.flights.filter(f => f.flightId == val)[0]

        travel = clone(this.travel)
        travel.id = this.progressive
        travel.description = flightSelected.description
        travel.flightCode = flightSelected.flightCode
        travel.takeOffDate = flightSelected.takeOffDate
        travel.takeOffTime = flightSelected.takeOffTime
        travel.originLabel = flightSelected.origin
        travel.destinationLabel = flightSelected.destination
        travel.seasonFare = flightSelected.season_fare
        travel.paxes = this.bookings.map(b => {
          return {
            bookingId: b.id,
            id: b.idPax,
            gender: b.gender,
            category: b.category,
            birthDate: b.birthDate,
            exemption: b.exemption,
            dsc: b.dsc
          }
        })
      }
      else {
        travel = {
          id: this.progressive,
          flightCode: null,
          description: null,
          takeOffDate: null,
          takeOffTime: null,
          flightDate: null,
          origin: null,
          flightId: null,
          takeOffDate: null,
          takeOffTime: null,
          originLabel: null,
          destinationLabel: null,
          seasonFare: null,
          paxes: []
        }
      }

      this.$emit('updated', { travel })
    },
    'travel.flightDate'() {
      this.resetSelection()
    },
    'travel.origin'() {
      this.resetSelection()
    },
    'travel.destination'() {
      this.resetSelection()
    }
  },
  methods: {
    resetSelection() {
      this.no_flights_available = false
      this.flights = []
      this.travel.flightId = null
    },
    supContent(booking) {
      if (booking.dw && booking.dw_flight == 'd') {
        return `R: ${booking.ticketNrInv}`
      }
      else if (booking.dw && booking.dw_flight == 'c') {
        return `A: ${booking.ticketNrInv}`
      }
    },
    async search() {
      try {
        this.no_flights_available = false

        this.loading = true
        this.$emit('lock', true)

        this.travel.flightId = null

        let { flightDate: departureDate , origin, destination } = this.travel

        let men = this.men
        let women = this.women
        let children = this.children
        let infants = this.infants

        let { data } = await api.get('api.flights.search', {
          departureDate,
          comebackDate: null,
          origin,
          destination,
          men,
          women,
          children,
          infants
        })
        if (data.hasOwnProperty('flights')) {
          if (! data.flights.length) {
            this.no_flights_available = true
          }
          this.flights = data.flights
        }
        if (data.hasOwnProperty('message')) {
          notifyE(data.message)
        }
        this.loading = false
        this.$emit('lock', false)
      }
      catch(error) {
        callErrors(error)
        this.loading = false
        this.$emit('lock', false)
      }
    },
		checkRoles(roles) {
			if (! roles) { roles = [] }
      let userRoles = this.$root.$children[0].roles
			let intersection = userRoles.filter(x => roles.includes(x))
			return intersection.length > 0
		}
  }
}
</script>
