<template>
	<section class="w-full max-w-5xl mx-auto">
		<div class="relative w-full flex flex-col bg-white">
			<div class="flex flex-col">
        <div class="flex">
          <div class="w-full flex my-2 h-8">
            <span class="flex-1 self-center border-t border-gray-300"></span>
            <span class="text-xl font-semibold text-blue-900 text-center px-3">
              {{ locale.Timetable }}
            </span>
            <span class="flex-1 self-center border-t border-gray-300"></span>
          </div>
        </div>        
        <div id="timetable" class="flex flex-col md:flex-row pt-0 md:pt-6 p-6 md:border rounded">
          <div class="w-full md:w-1/3">
            <vue-datepicker v-model="date" :inline="true" />
          </div>
          <div v-if="date" class="w-full mt-5 md:w-2/3 md:mt-0 md:ml-6">
            <div class="relative h-full">
              <vue-loading :loading="loading" />
              <table class="w-full border">
                <thead>
                  <tr class="bg-blue-200">
                    <td colspan="5">
                      <div class="w-full font-bold text-center py-1">
                        <span v-if="flights !== null">
                          <span v-if="lang == 'it'">programmazione voli del</span>
                          <span v-if="lang == 'en'">flights scheduled on</span>
                        </span>
                        {{ date_selected }}
                      </div>
                    </td>
                  </tr>
                  <tr v-if="flights !== null" class="text-left text-xs" style="background-color: #e0eff7">
                    <td class="py-1 pl-1 font-bold" width="15%" style="color: #114d8b">{{ locale.Flight }}</td>
                    <td class="py-1">{{ locale.Origin }}</td>
                    <td class="py-1">{{ locale.Takeoff }}</td>
                    <td class="py-1">{{ locale.Destination }}</td>
                    <td class="py-1">{{ locale.Landing }}</td>
                  </tr>                    
                </thead>
                <tbody v-for="(flight, index) in flights" :key="index">
                  <tr style="background-color: #f0f8f8">
                    <td colspan="5">
                      <div class="font-semibold text-xs border-b py-1 pl-1" style="color: #114d8b">{{ flight.description }}</div>
                    </td>
                  </tr>
                  <tr v-for="segment in flight.segments" :key="segment.counter" class="border-b border-gray-200">
                    <td>&nbsp;</td>
                    <td v-text="segment.source" />
                    <td v-text="segment.takeoff" />
                    <td v-text="segment.destination" />
                    <td v-text="segment.landing" />
                  </tr>
                </tbody>
                <tbody v-if="flights === null">
                  <tr>
                    <td colspan="5">
                      <div class="my-20 text-center">{{ locale.noFlightsWarning }}</div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="hidden md:block border rounded my-3 p-3 pt-0">
          <iframe class="w-full" :src="`/timetable-grid?lang=${lang}`" style="height:580px" ></iframe>
        </div>        
      </div>
    </div>
	</section>
</template>

<script>
import Locales from '../../locales.json'

export default {
  name: 'Timetable',
  data:() => ({
    loading: false,
    date: null,
    date_selected: null,
    flights: [],
    locales: null
  }),
  computed: {
    lang() {
      return this.$parent.lang
    },
    year() {
      return moment().format('YYYY')
    },
		locale() {
			return this.locales[this.lang]
		}
  },
  watch: {
    date(val) {
      this.loadFlights()
    }
  },
  methods: {
    async loadFlights() {
      // this.flights = []
      try {
        this.loading = true
        let { date } = this
        let { data } = await api.get('api.calendar-flights.index', { date })
        console.log(data)
        // setTimeout(x => {
          this.date_selected = this.date
          this.flights = data.flights
          this.loading = false
        // }, 3000)
      }
      catch (error) {
        this.loading = false
        callErrors(error)
      }
    }
  },
  created() {
    this.locales = Locales
    
    this.date = moment().format('DD/MM/YYYY')
    this.date_selected = this.date
  }
}
</script>
