<template>
	<section class="w-full max-w-5xl mx-auto">
		<div class="relative w-full flex flex-col bg-white">
			<div class="flex flex-col">
        <div class="flex">
          <div class="w-full flex my-2 h-8">
            <span class="flex-1 self-center border-t border-gray-300"></span>
            <span class="text-xl font-semibold text-blue-900 text-center px-3">
              {{ locale.Directions }}
            </span>
            <span class="flex-1 self-center border-t border-gray-300"></span>
          </div>
        </div>
        <div class="mt-6 flex flex-col md:flex-row mb-10 px-3 md:px-0" style="height: 340px">
          <div class="md:w-1/2 md:pr-10">
            <iframe class="w-full h-full" src="https://www.google.com/maps/embed?pb=!1m19!1m8!1m3!1d2991.761070435232!2d15.534265!3d41.422705!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x1339d96ff32dbfab%3A0x572117a2246558db!2sAlidaunia%20Servizio%20Elicotteri%2C%20S.S.673%20Km.19%2C00%2C%2071122%20Foggia%20FG!3m2!1d41.422705!2d15.534265!5e0!3m2!1sit!2sit!4v1583402131166!5m2!1sit!2sit" frameborder="0" style="border:0;" allowfullscreen=""></iframe>
          </div>
          <div class="md:w-1/2 flex flex-col">
            <div class="text-blue-800 font-semibold mb-1 text-xl">
              {{ locale.MainBase }}
            </div>
            <p class="leading-normal">
              S.S. 673 km. 19,00<br>
              71122 - Foggia<br>
              Italy<br>
              <p class="mt-3 leading-tight border-t pt-2">
                <i class="text-gray-700">{{ locale.distances }}</i>
              </p>
              <p class="mt-2 leading-tight">
                2,4 km ... Aeroporto Gino Lisa - Foggia
                <br>
                122 km ... Aeroporto Internazionale Karol Wojtyla - Bari
                <br>
                168 km ... Aeroporto Internazionale di Capodichino - Napoli
                <br>
                193 km ... Aeroporto Internazionale d'Abruzzo - Pescara
                <br>
              </p>
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Locales from '../../locales.json'

export default {
  name: 'Directions',
  data:() => ({
    locales: null
  }),
  computed: {
    lang() {
      return this.$parent.lang
    },
		locale() {
			return this.locales[this.lang]
		}
  },
  created() {
    if (location.pathname.includes('come-raggiungerci')) {
      this.$root.$children[0].lang = 'it'
      localStorage.setItem('lang', 'it')
    }
    else if (location.pathname.includes('directions')) {
      this.$root.$children[0].lang = 'en'
      localStorage.setItem('lang', 'en')
    }
    this.locales = Locales
  }
}
</script>
