<template>
  <section id="main-content" class="flex-1 w-full max-w-5xl justify-start items-start mx-auto px-2 flex flex-col bg-white min-h-screen mt-3 mb-5">
    <div class="md:mx-auto w-full md:w-2/3">
      <div class="my-4 md:my-0 rounded-lg shadow-xl border w-full h-full relative bg-white">
        <vue-loading :loading="loading" />
        <div class="w-full p-4">
          <div class="flex flex-col">
            <div class="w-full flex justify-center h-6">
              <span class="text-xl font-semibold text-blue-900 text-center px-3">
                {{ locale.user_forms.sign_up }}
              </span>
            </div>
          </div> 
          <div v-if="message.status" class="w-full md:pt-2 text-blue-800 flex flex-col flex-wrap md:flex-no-wrap mt-5 p-4">
            <div class="w-full border rounded text-justify" :class="messageStatus">
              La tua registrazione è stata presa in carico dal sistema ed è in attesa di conferma dell'email.
              Controlla la tua casella di posta (anche nella cartella SPAM/JUNK) e clicca sul link ricevuto per attivare l'account.
            </div>
            <div class="mt-4 flex justify-center">
              <span class="bg-red-600 text-white px-2">ATTENZIONE</span>
            </div>
            <div class="border border-red-600 rounded p-2 text-center text-red-600" v-if="type == 'agency'">
              L'account "AGENZIA" sarà sbloccato solo dopo conferma dell'amministratore di sistema.
            </div>
          </div>
          <div v-if="! message.status" class="md:pt-2 flex flex-wrap md:flex-no-wrap">
            <form class="w-full flex flex-col px-0 md:px-10">
              <div class="mt-4 w-full flex justify-center items-center">
                <vue-radio v-model="type" value="user" :label="locale.user_forms.sign_up_user" class="cursor-pointer mr-10" />
                <vue-radio v-model="type" value="agency" :label="locale.user_forms.sign_up_agency" class="cursor-pointer" />
              </div>
              <div class="mt-4">
                <div class="border-t border-gray-300 flex text-xs py-1 mb-1 italic text-gray-500 select-none">
                  <div class="w-full" v-html="locale.user_forms.label1" />
                </div>                  

                <div class="flex items-stretch mb-4">
                  <label class="select-none text-right text-blue-700 text-xs pt-2 pr-3 align-middle w-1/4">E-Mail</label>
                  <div class="flex flex-col w-3/4">
                    <input v-model="form.email" type="email" data-cy="email" class="flex-grow h-8 px-2 border rounded" autofocus>
                  </div>
                </div>

                <div class="flex items-stretch mb-4">
                  <label class="select-none text-right text-blue-700 text-xs pt-2 pr-3 align-middle w-1/4">
                    Password
                  </label>
                  <div class="flex-1 flex w-full md:w-40">
                    <input v-model="form.password" :type="pwdInvisible ? 'password' : 'text'" class="flex-grow h-8 px-2 border rounded-r-none">
                    <span @click="pwdInvisible = ! pwdInvisible" class="flex items-center leading-normal bg-gray-200 rounded rounded-l-none border border-l-0 border-gray-300 px-2 whitespace-no-wrap text-gray-600 text-sm cursor-pointer outline-none" content="mostra password" v-tippy="{ arrow: true }">
											<fa :class="{ 's-eye': pwdInvisible, 's-eye-slash': ! pwdInvisible }" />
										</span>
                  </div>                  
                </div>

                <div class="flex items-stretch mb-4">
                  <label class="select-none text-right text-blue-700 text-xs pt-0 md:pt-2 pr-3 align-middle w-1/4">
                    {{ locale.user_forms.password_confirmation }}
                  </label>
                  <div class="flex-1 flex w-full md:w-40">
                    <input v-model="form.password_confirmation" :type="pwdInvisible ? 'password' : 'text'" class="flex-grow h-8 px-2 border rounded">
                  </div>
                </div>

                <div class="border-t border-gray-300 flex text-xs py-1 mb-1 italic text-gray-500 select-none">
                  <div v-show="type == 'user'" class="w-full" v-text="locale.user_forms.label2a" />
                  <div v-if="type == 'agency'" class="w-full" v-text="locale.user_forms.label2b" />
                </div>                  

                <div class="flex items-stretch mb-4">
                  <label class="select-none text-right text-blue-700 text-xs pt-2 pr-3 align-middle w-1/4">
                    <div v-show="type == 'user'" v-text="locale.user_forms.lastname" />
                    <div v-if="type == 'agency'" v-text="locale.user_forms.company_name" />
                  </label>
                  <div class="flex flex-col w-3/4">
                    <input v-model="form.lastname" type="text" class="flex-grow h-8 px-2 rounded border">
                  </div>
                </div>

                <div class="flex items-stretch mb-4">
                  <label class="select-none text-right text-blue-700 text-xs pt-2 pr-3 align-middle w-1/4">
                    <div v-show="type == 'user'" v-text="locale.user_forms.firstname" />
                    <div v-if="type == 'agency'" v-text="locale.user_forms.vat" />
                  </label>
                  <div class="flex flex-col w-3/4">
                    <input v-model="form.firstname" type="text" class="flex-grow h-8 px-2 rounded border">
                  </div>
                </div>

                <div v-show="type == 'user'" class="flex items-stretch mb-4">
                  <label class="select-none text-right text-blue-700 text-xs pt-2 pr-3 align-middle w-1/4">
                    {{ locale.user_forms.gender }}
                  </label>
                  <div class="relative flex">
                    <div class="inline-block relative w-16">
                      <select v-model="form.gender" class="block appearance-none w-full bg-white border border-gray-300 hover:border-gray-500 px-2 py-1 pr-8 rounded" data-cy="pax-gender">
                        <option value="M">M</option>
                        <option value="F">F</option>
                      </select>
                    </div>
                    <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-1 text-gray-700">
                      <fa class="s-caret-down" />
                    </div>
                  </div>
                </div>

                <div v-show="type == 'user'" class="flex items-stretch mb-4">
                  <label class="select-none text-right text-blue-700 text-xs pt-2 pr-3 align-middle w-1/4">
                    {{ locale.user_forms.birthdate }}
                  </label>
                  <div class="flex w-3/4">
                    <span class="flex items-center leading-normal bg-gray-200 rounded rounded-r-none border border-r-0 border-gray-300 px-2 whitespace-no-wrap text-gray-600 text-sm">
											<fa class="s-calendar-alt" />
										</span>
                    <input v-model="form.birthdate" type="text" class="w-32 flex-shrink flex-grow flex-auto w-px flex-1 border border-gray-300 rounded rounded-l-none px-2 py-1 relative mask-date" v-mask="dynMask" style="max-width:100px" />
                  </div>
                </div>

                <div class="flex items-stretch mb-4">
                  <label class="select-none text-right text-blue-700 text-xs pt-2 pr-3 align-middle w-1/4">
                    {{ locale.user_forms.address }}
                  </label>
                  <div class="flex flex-col w-3/4">
                    <input v-model="form.address" type="text" class="flex-grow h-8 px-2 rounded border">
                  </div>
                </div>

                <div class="flex items-stretch mb-4">
                  <label class="select-none text-right text-blue-700 text-xs pt-2 pr-3 align-middle w-1/4">
                    {{ locale.user_forms.city }}
                  </label>
                  <div class="flex flex-col w-3/4">
                    <input v-model="form.city" type="text" class="flex-grow h-8 px-2 rounded border">
                  </div>
                </div>

                <div class="flex items-stretch mb-4">
                  <label class="select-none text-right text-blue-700 text-xs pt-2 pr-3 align-middle w-1/4">
                    {{ locale.user_forms.province }}
                  </label>
                  <div class="flex flex-col w-3/4">
                    <input v-model="form.province" type="text" class="flex-grow h-8 px-2 rounded border">
                  </div>
                </div>

                <div class="flex items-stretch mb-4">
                  <label class="select-none text-right text-blue-700 text-xs pt-2 pr-3 align-middle w-1/4">
                    {{ locale.user_forms.country }}
                  </label>
                  <div class="relative flex">
                    <div class="inline-block relative">
                      <select v-model="form.country" class="block appearance-none w-full bg-white border border-gray-300 hover:border-gray-500 px-2 py-1 pr-8 rounded" data-cy="pax-gender">
                        <option v-for="country in lists.countries" :key="country.code" :value="country.name" v-text="country.name" />
                      </select>
                    </div>
                    <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-1 text-gray-700">
                      <fa class="s-caret-down" />
                    </div>
                  </div>
                </div>

                <div class="flex items-stretch mb-4">
                  <label class="select-none text-right text-blue-700 text-xs pt-2 pr-3 align-middle w-1/4">
                    {{ locale.user_forms.zipcode }}
                  </label>
                  <div class="flex flex-col w-3/4">
                    <input v-model="form.zipcode" type="text" class="flex-grow h-8 px-2 rounded border" style="max-width:80px">
                  </div>
                </div>

                <div class="border-t border-gray-300 flex text-xs py-1 mb-1 italic text-gray-500 select-none">
                  <div class="w-full">
                    {{ locale.user_forms.label3 }}
                  </div>
                </div>                  

                <div class="flex items-stretch mb-4">
                  <label class="select-none text-right text-blue-700 text-xs pt-2 pr-3 align-middle w-1/4">
                    {{ locale.user_forms.telephone }}
                  </label>
                  <div class="flex flex-col w-3/4">
                    <input v-model="form.telephone" type="text" class="flex-grow h-8 px-2 rounded border">
                  </div>
                </div>

                <div v-show="type == 'user'" class="border-t border-gray-300 flex text-xs py-1 mb-1 italic text-gray-500 select-none">
                  <div class="w-full">
                    {{ locale.user_forms.label4 }}
                  </div>
                </div>

                <div v-show="type == 'user'" class="flex items-stretch mb-4">
                  <label class="select-none text-right text-blue-700 text-xs pt-2 pr-3 align-middle w-1/4">
                    {{ locale.user_forms.document_type }}
                  </label>
                  <div class="relative flex">
                    <div class="inline-block relative">
                      <select v-model="form.document_type" class="block appearance-none w-full bg-white border border-gray-300 hover:border-gray-500 px-2 py-1 pr-8 rounded" data-cy="pax-gender">
                        <option :value="null"></option>
                        <option v-for="doc_type in lists.document_types" :key="doc_type.id" :value="doc_type.id" v-text="doc_type.text" />
                      </select>
                    </div>
                    <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-1 text-gray-700">
                      <fa class="s-caret-down" />
                    </div>
                  </div>
                </div>

                <div v-show="type == 'user'" class="flex items-stretch mb-4">
                  <label class="select-none text-right text-blue-700 text-xs pt-2 pr-3 align-middle w-1/4">
                    {{ locale.user_forms.document_num }}
                  </label>
                  <div class="flex flex-col w-40">
                    <input v-model="form.document_num" type="text" class="flex-grow h-8 px-2 rounded border">
                  </div>
                </div>

                <div class="flex flex-col mt-10 md:mt-0">
                  <div class="w-full flex flex-col items-end">
                    <button type="button" @click="signup" data-cy="submit-login" class="w-32 bg-blue-500 hover:bg-blue-600 text-white text-sm font-semibold py-2 px-4 rounded">
                      {{ locale.user_forms.sign_up_button }}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Locales from '../../locales.json'

export default {
  name: 'SignUp',
  data:() => ({
    loading: false,
    locales: null,
    type: 'user',
    message: {
      status: null,
      content: null
    },
    pwdInvisible: true,
    form: {
      email: null,
      password: null,
      password_confirmation: null,
      firstname: null,
      lastname: null,
      gender: null,
      birthdate: null,
      address: null,
      city: null,
      province: null,
      country: 'Italy',
      zipcode: null,
      telephone: null,
      document_type: null,
      document_num: null
    },
    lists: {
      document_types: null,
      countries: [
        {name: 'Italy', code: 'IT'},
        {name: 'Afghanistan', code: 'AF'}, 
        {name: 'Åland Islands', code: 'AX'}, 
        {name: 'Albania', code: 'AL'}, 
        {name: 'Algeria', code: 'DZ'}, 
        {name: 'American Samoa', code: 'AS'}, 
        {name: 'AndorrA', code: 'AD'}, 
        {name: 'Angola', code: 'AO'}, 
        {name: 'Anguilla', code: 'AI'}, 
        {name: 'Antarctica', code: 'AQ'}, 
        {name: 'Antigua and Barbuda', code: 'AG'}, 
        {name: 'Argentina', code: 'AR'}, 
        {name: 'Armenia', code: 'AM'}, 
        {name: 'Aruba', code: 'AW'}, 
        {name: 'Australia', code: 'AU'}, 
        {name: 'Austria', code: 'AT'}, 
        {name: 'Azerbaijan', code: 'AZ'}, 
        {name: 'Bahamas', code: 'BS'}, 
        {name: 'Bahrain', code: 'BH'}, 
        {name: 'Bangladesh', code: 'BD'}, 
        {name: 'Barbados', code: 'BB'}, 
        {name: 'Belarus', code: 'BY'}, 
        {name: 'Belgium', code: 'BE'}, 
        {name: 'Belize', code: 'BZ'}, 
        {name: 'Benin', code: 'BJ'}, 
        {name: 'Bermuda', code: 'BM'}, 
        {name: 'Bhutan', code: 'BT'}, 
        {name: 'Bolivia', code: 'BO'}, 
        {name: 'Bosnia and Herzegovina', code: 'BA'}, 
        {name: 'Botswana', code: 'BW'}, 
        {name: 'Bouvet Island', code: 'BV'}, 
        {name: 'Brazil', code: 'BR'}, 
        {name: 'British Indian Ocean Territory', code: 'IO'}, 
        {name: 'Brunei Darussalam', code: 'BN'}, 
        {name: 'Bulgaria', code: 'BG'}, 
        {name: 'Burkina Faso', code: 'BF'}, 
        {name: 'Burundi', code: 'BI'}, 
        {name: 'Cambodia', code: 'KH'}, 
        {name: 'Cameroon', code: 'CM'}, 
        {name: 'Canada', code: 'CA'}, 
        {name: 'Cape Verde', code: 'CV'}, 
        {name: 'Cayman Islands', code: 'KY'}, 
        {name: 'Central African Republic', code: 'CF'}, 
        {name: 'Chad', code: 'TD'}, 
        {name: 'Chile', code: 'CL'}, 
        {name: 'China', code: 'CN'}, 
        {name: 'Christmas Island', code: 'CX'}, 
        {name: 'Cocos (Keeling) Islands', code: 'CC'}, 
        {name: 'Colombia', code: 'CO'}, 
        {name: 'Comoros', code: 'KM'}, 
        {name: 'Congo', code: 'CG'}, 
        {name: 'Congo, The Democratic Republic of the', code: 'CD'}, 
        {name: 'Cook Islands', code: 'CK'}, 
        {name: 'Costa Rica', code: 'CR'}, 
        {name: 'Cote D\'Ivoire', code: 'CI'}, 
        {name: 'Croatia', code: 'HR'}, 
        {name: 'Cuba', code: 'CU'}, 
        {name: 'Cyprus', code: 'CY'}, 
        {name: 'Czech Republic', code: 'CZ'}, 
        {name: 'Denmark', code: 'DK'}, 
        {name: 'Djibouti', code: 'DJ'}, 
        {name: 'Dominica', code: 'DM'}, 
        {name: 'Dominican Republic', code: 'DO'}, 
        {name: 'Ecuador', code: 'EC'}, 
        {name: 'Egypt', code: 'EG'}, 
        {name: 'El Salvador', code: 'SV'}, 
        {name: 'Equatorial Guinea', code: 'GQ'}, 
        {name: 'Eritrea', code: 'ER'}, 
        {name: 'Estonia', code: 'EE'}, 
        {name: 'Ethiopia', code: 'ET'}, 
        {name: 'Falkland Islands (Malvinas)', code: 'FK'}, 
        {name: 'Faroe Islands', code: 'FO'}, 
        {name: 'Fiji', code: 'FJ'}, 
        {name: 'Finland', code: 'FI'}, 
        {name: 'France', code: 'FR'}, 
        {name: 'French Guiana', code: 'GF'}, 
        {name: 'French Polynesia', code: 'PF'}, 
        {name: 'French Southern Territories', code: 'TF'}, 
        {name: 'Gabon', code: 'GA'}, 
        {name: 'Gambia', code: 'GM'}, 
        {name: 'Georgia', code: 'GE'}, 
        {name: 'Germany', code: 'DE'}, 
        {name: 'Ghana', code: 'GH'}, 
        {name: 'Gibraltar', code: 'GI'}, 
        {name: 'Greece', code: 'GR'}, 
        {name: 'Greenland', code: 'GL'}, 
        {name: 'Grenada', code: 'GD'}, 
        {name: 'Guadeloupe', code: 'GP'}, 
        {name: 'Guam', code: 'GU'}, 
        {name: 'Guatemala', code: 'GT'}, 
        {name: 'Guernsey', code: 'GG'}, 
        {name: 'Guinea', code: 'GN'}, 
        {name: 'Guinea-Bissau', code: 'GW'}, 
        {name: 'Guyana', code: 'GY'}, 
        {name: 'Haiti', code: 'HT'}, 
        {name: 'Heard Island and Mcdonald Islands', code: 'HM'}, 
        {name: 'Holy See (Vatican City State)', code: 'VA'}, 
        {name: 'Honduras', code: 'HN'}, 
        {name: 'Hong Kong', code: 'HK'}, 
        {name: 'Hungary', code: 'HU'}, 
        {name: 'Iceland', code: 'IS'}, 
        {name: 'India', code: 'IN'}, 
        {name: 'Indonesia', code: 'ID'}, 
        {name: 'Iran, Islamic Republic Of', code: 'IR'}, 
        {name: 'Iraq', code: 'IQ'}, 
        {name: 'Ireland', code: 'IE'}, 
        {name: 'Isle of Man', code: 'IM'}, 
        {name: 'Israel', code: 'IL'}, 
        {name: 'Jamaica', code: 'JM'}, 
        {name: 'Japan', code: 'JP'}, 
        {name: 'Jersey', code: 'JE'}, 
        {name: 'Jordan', code: 'JO'}, 
        {name: 'Kazakhstan', code: 'KZ'}, 
        {name: 'Kenya', code: 'KE'}, 
        {name: 'Kiribati', code: 'KI'}, 
        {name: 'Korea, Democratic People\'S Republic of', code: 'KP'}, 
        {name: 'Korea, Republic of', code: 'KR'}, 
        {name: 'Kuwait', code: 'KW'}, 
        {name: 'Kyrgyzstan', code: 'KG'}, 
        {name: 'Lao People\'S Democratic Republic', code: 'LA'}, 
        {name: 'Latvia', code: 'LV'}, 
        {name: 'Lebanon', code: 'LB'}, 
        {name: 'Lesotho', code: 'LS'}, 
        {name: 'Liberia', code: 'LR'}, 
        {name: 'Libyan Arab Jamahiriya', code: 'LY'}, 
        {name: 'Liechtenstein', code: 'LI'}, 
        {name: 'Lithuania', code: 'LT'}, 
        {name: 'Luxembourg', code: 'LU'}, 
        {name: 'Macao', code: 'MO'}, 
        {name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK'}, 
        {name: 'Madagascar', code: 'MG'}, 
        {name: 'Malawi', code: 'MW'}, 
        {name: 'Malaysia', code: 'MY'}, 
        {name: 'Maldives', code: 'MV'}, 
        {name: 'Mali', code: 'ML'}, 
        {name: 'Malta', code: 'MT'}, 
        {name: 'Marshall Islands', code: 'MH'}, 
        {name: 'Martinique', code: 'MQ'}, 
        {name: 'Mauritania', code: 'MR'}, 
        {name: 'Mauritius', code: 'MU'}, 
        {name: 'Mayotte', code: 'YT'}, 
        {name: 'Mexico', code: 'MX'}, 
        {name: 'Micronesia, Federated States of', code: 'FM'}, 
        {name: 'Moldova, Republic of', code: 'MD'}, 
        {name: 'Monaco', code: 'MC'}, 
        {name: 'Mongolia', code: 'MN'}, 
        {name: 'Montserrat', code: 'MS'}, 
        {name: 'Morocco', code: 'MA'}, 
        {name: 'Mozambique', code: 'MZ'}, 
        {name: 'Myanmar', code: 'MM'}, 
        {name: 'Namibia', code: 'NA'}, 
        {name: 'Nauru', code: 'NR'}, 
        {name: 'Nepal', code: 'NP'}, 
        {name: 'Netherlands', code: 'NL'}, 
        {name: 'Netherlands Antilles', code: 'AN'}, 
        {name: 'New Caledonia', code: 'NC'}, 
        {name: 'New Zealand', code: 'NZ'}, 
        {name: 'Nicaragua', code: 'NI'}, 
        {name: 'Niger', code: 'NE'}, 
        {name: 'Nigeria', code: 'NG'}, 
        {name: 'Niue', code: 'NU'}, 
        {name: 'Norfolk Island', code: 'NF'}, 
        {name: 'Northern Mariana Islands', code: 'MP'}, 
        {name: 'Norway', code: 'NO'}, 
        {name: 'Oman', code: 'OM'}, 
        {name: 'Pakistan', code: 'PK'}, 
        {name: 'Palau', code: 'PW'}, 
        {name: 'Palestinian Territory, Occupied', code: 'PS'}, 
        {name: 'Panama', code: 'PA'}, 
        {name: 'Papua New Guinea', code: 'PG'}, 
        {name: 'Paraguay', code: 'PY'}, 
        {name: 'Peru', code: 'PE'}, 
        {name: 'Philippines', code: 'PH'}, 
        {name: 'Pitcairn', code: 'PN'}, 
        {name: 'Poland', code: 'PL'}, 
        {name: 'Portugal', code: 'PT'}, 
        {name: 'Puerto Rico', code: 'PR'}, 
        {name: 'Qatar', code: 'QA'}, 
        {name: 'Reunion', code: 'RE'}, 
        {name: 'Romania', code: 'RO'}, 
        {name: 'Russian Federation', code: 'RU'}, 
        {name: 'RWANDA', code: 'RW'}, 
        {name: 'Saint Helena', code: 'SH'}, 
        {name: 'Saint Kitts and Nevis', code: 'KN'}, 
        {name: 'Saint Lucia', code: 'LC'}, 
        {name: 'Saint Pierre and Miquelon', code: 'PM'}, 
        {name: 'Saint Vincent and the Grenadines', code: 'VC'}, 
        {name: 'Samoa', code: 'WS'}, 
        {name: 'San Marino', code: 'SM'}, 
        {name: 'Sao Tome and Principe', code: 'ST'}, 
        {name: 'Saudi Arabia', code: 'SA'}, 
        {name: 'Senegal', code: 'SN'}, 
        {name: 'Serbia and Montenegro', code: 'CS'}, 
        {name: 'Seychelles', code: 'SC'}, 
        {name: 'Sierra Leone', code: 'SL'}, 
        {name: 'Singapore', code: 'SG'}, 
        {name: 'Slovakia', code: 'SK'}, 
        {name: 'Slovenia', code: 'SI'}, 
        {name: 'Solomon Islands', code: 'SB'}, 
        {name: 'Somalia', code: 'SO'}, 
        {name: 'South Africa', code: 'ZA'}, 
        {name: 'South Georgia and the South Sandwich Islands', code: 'GS'}, 
        {name: 'Spain', code: 'ES'}, 
        {name: 'Sri Lanka', code: 'LK'}, 
        {name: 'Sudan', code: 'SD'}, 
        {name: 'Suriname', code: 'SR'}, 
        {name: 'Svalbard and Jan Mayen', code: 'SJ'}, 
        {name: 'Swaziland', code: 'SZ'}, 
        {name: 'Sweden', code: 'SE'}, 
        {name: 'Switzerland', code: 'CH'}, 
        {name: 'Syrian Arab Republic', code: 'SY'}, 
        {name: 'Taiwan, Province of China', code: 'TW'}, 
        {name: 'Tajikistan', code: 'TJ'}, 
        {name: 'Tanzania, United Republic of', code: 'TZ'}, 
        {name: 'Thailand', code: 'TH'}, 
        {name: 'Timor-Leste', code: 'TL'}, 
        {name: 'Togo', code: 'TG'}, 
        {name: 'Tokelau', code: 'TK'}, 
        {name: 'Tonga', code: 'TO'}, 
        {name: 'Trinidad and Tobago', code: 'TT'}, 
        {name: 'Tunisia', code: 'TN'}, 
        {name: 'Turkey', code: 'TR'}, 
        {name: 'Turkmenistan', code: 'TM'}, 
        {name: 'Turks and Caicos Islands', code: 'TC'}, 
        {name: 'Tuvalu', code: 'TV'}, 
        {name: 'Uganda', code: 'UG'}, 
        {name: 'Ukraine', code: 'UA'}, 
        {name: 'United Arab Emirates', code: 'AE'}, 
        {name: 'United Kingdom', code: 'GB'}, 
        {name: 'United States', code: 'US'}, 
        {name: 'United States Minor Outlying Islands', code: 'UM'}, 
        {name: 'Uruguay', code: 'UY'}, 
        {name: 'Uzbekistan', code: 'UZ'}, 
        {name: 'Vanuatu', code: 'VU'}, 
        {name: 'Venezuela', code: 'VE'}, 
        {name: 'Viet Nam', code: 'VN'}, 
        {name: 'Virgin Islands, British', code: 'VG'}, 
        {name: 'Virgin Islands, U.S.', code: 'VI'}, 
        {name: 'Wallis and Futuna', code: 'WF'}, 
        {name: 'Western Sahara', code: 'EH'}, 
        {name: 'Yemen', code: 'YE'}, 
        {name: 'Zambia', code: 'ZM'}, 
        {name: 'Zimbabwe', code: 'ZW'} 
      ]
    }
  }),
  computed: {
    lang() {
      return this.$parent.lang
    },
		locale() {
			return this.locales[this.lang]
		},    
    messageStatus() {
      let { status } = this.message
      if (status == 'success') {
        return ['border-green-500', 'bg-green-600', 'text-white']
      }
      else if (status == 'error') {
        return ['border-red-500', 'bg-red-600', 'text-white']
      }
      else {
        return ['border-white']
      }
    },
    dynMask() {
      let obj = {
        alias: 'date',
        inputFormat: 'dd/mm/yyyy'
      }

      if (this.lang == 'it') {
        obj = { ...obj, ...{ placeholder: 'gg/mm/aaaa' } }
      }
      else {
        obj = { ...obj, ...{ placeholder: 'dd/mm/yyyy' } }
      }

      return obj
    }
  },
  watch: {
    type(val) {
      if (val == 'agency') {
        this.form.gender = null
        this.form.birthdate = null
        this.form.document_type = null
        this.form.document_num = null
      }
    }
  },
  methods: {
    pippo(field) {
      console.log(field)
    },
    async signup() {
      try {
        this.loading = true
        
        let { form } = this

        form.type = this.type

        let { data } = await api.post('api.auth.register', form)

        this.loading = false

        if (data.hasOwnProperty('errors')) {
          return
        }

        this.$parent.$refs['user-drop'].getUserData()

        this.$router.push({ name: 'pending' })
      }
      catch(error) {
        callErrors(error)
        this.loading = false
      }
    },
		checkToken() {
			let token = localStorage.getItem('token')
			if (token) {
				// location.href = '/it/prenotazioni'
			}
    },
    async startData() {
      try {
        this.loading = true
        let { data: { document_types } } = await api.get('api.document-types.index')
        this.lists.document_types = document_types
        this.loading = false
      }
      catch(error) {
        callErrors(error)
        this.loading = false
      }
    }
  },
	created() {
    this.locales = Locales

    this.startData()

    if (this.$parent.auth.token && this.$parent.auth.user) {
      this.$router.push({ name: 'booking' })
      return
    }
		this.checkToken()
	}
}
</script>
