<template>
	<section class="w-full max-w-5xl mx-auto">
		<div class="relative w-full flex flex-col bg-white">
      <vue-loading :loading="loading" />
			<div class="flex flex-col">
        <div class="flex">
          <div class="w-full flex my-5 h-10">
            <span class="flex-1 self-center border-t border-gray-300"></span>
            <span class="text-2xl font-semibold text-blue-900 text-center px-3">
              <span v-if="lang == 'it'">INAUGURAZIONI ELISUPERFICI</span>
              <span v-if="lang == 'en'">HELIPADS INAUGURATIONS</span>              
            </span>
            <span class="flex-1 self-center border-t border-gray-300"></span>
          </div>
        </div>        
        <div class="flex flex-col md:flex-row mb-10">
          
          <!-- BEGIN CONTENT -->
          <div class="w-full _md:w-2/3 pl-0 md:pl-10 mt-5 md:mt-0">

            <div v-for="post in posts" class="w-full flex mb-5 py-5 border-b">
              <router-link :to="{ name: 'helipad-post', params: { slug: post.slug } }">
                <img class="w-64" :src="`/img/helipad-posts/${post.progr}/00.jpg`" />
              </router-link>
              <div class="ml-3 flex-1">
                <div class="text-sm" v-text="post.post_date" />
                <router-link class="font-semibold text-blue-800 text-lg" v-text="post.title" :to="{ name: 'helipad-post', params: { slug: post.slug } }" />
              </div>
            </div>

          </div>
          <!-- END CONTENT -->
          
          <!-- BEGIN SIDEBAR -->
          <div class="hidden w-full md:w-1/3 pl-0 md:pl-10 mt-5 md:mt-0">
            <!-- CATEGORIES START -->
            <ul id="sidebarMenu" class="nav sidebar-categories mb40">

              <router-link tag="li" :to="{ name: 'company' }" class="hover:bg-gray-200 cursor-pointer border-b p-1 flex justify-between items-center" :class="sidebarItemActive('company')">
                <span>
                  Azienda
                </span>
                <i class="fa fa-caret-right" />
              </router-link>

              <router-link tag="li" :to="{ name: 'company-activities' }" class="hover:bg-gray-200 text-blue-800 cursor-pointer border-b p-1 flex justify-between items-center" :class="sidebarItemActive('company-activities')">
                <span>
                  Attività operative
                </span>
                <i class="fa fa-caret-right" />
              </router-link>

              <router-link tag="li" :to="{ name: 'company-civil-protection' }" class="hover:bg-gray-200 text-blue-800 cursor-pointer border-b p-1 flex justify-between items-center" :class="sidebarItemActive('company-civil-protection')">
                <span>
                  Protezione civile
                </span>
                <i class="fa fa-caret-right" />
              </router-link>

              <router-link tag="li" :to="{ name: 'company-public-transport' }" class="hover:bg-gray-200 text-blue-800 cursor-pointer border-b p-1 flex justify-between items-center" :class="sidebarItemActive('company-public-transport')">
                <span>
                  Trasporto pubblico
                </span>
                <i class="fa fa-caret-right" />
              </router-link>

              <router-link tag="li" :to="{ name: 'company-additional-services' }" class="hover:bg-gray-200 text-blue-800 cursor-pointer border-b p-1 flex justify-between items-center" :class="sidebarItemActive('company-additional-services')">
                <span>
                  Servizi aggiuntivi
                </span>
                <i class="fa fa-caret-right" />
              </router-link>

              <router-link tag="li" :to="{ name: 'company-maintenance' }" class="hover:bg-gray-200 text-blue-800 cursor-pointer border-b p-1 flex justify-between items-center" :class="sidebarItemActive('company-maintenance')">
                <span>
                  Attività di manutenzione
                </span>
                <i class="fa fa-caret-right" />
              </router-link>

              <router-link tag="li" :to="{ name: 'company-auths-certs' }" class="hover:bg-gray-200 text-blue-800 cursor-pointer border-b p-1 flex justify-between items-center" :class="sidebarItemActive('company-auths-certs')">
                <span>
                  Autorizzazioni e certificazioni
                </span>
                <i class="fa fa-caret-right" />
              </router-link>

              <router-link tag="li" :to="{ name: 'company-design-construction' }" class="hover:bg-gray-200 text-blue-800 cursor-pointer border-b p-1 flex justify-between items-center" :class="sidebarItemActive('company-design-construction')">
                <span>
                  Progettazione, costruzione e<br>gestione eliporti
                </span>
                <i class="fa fa-caret-right" />
              </router-link>

              <router-link tag="li" :to="{ name: 'company-heli-network' }" class="hover:bg-gray-200 text-blue-800 cursor-pointer p-1 flex justify-between items-center" :class="[sidebarItemActive('company-heli-network'), { 'border-b': $route.name != 'company-heli-network' }]">
                <span>
                  Rete Eliportuale Pugliese
                </span>
                <i class="fa fa-caret-right" />
              </router-link>

              <li v-if="$route.name == 'company-heli-network'" class="hover:bg-gray-200 text-blue-800 cursor-pointer flex justify-between items-center">
                <a href="#foggia" class="border-b-2 py-1 flex-1 pl-2 text-xs border-l-8 border-r-8 border-blue-100">
                  <i class="fa fa-caret-right mr-1" />
                  Foggia
                </a>
              </li>

              <li v-if="$route.name == 'company-heli-network'" class="hover:bg-gray-200 text-blue-800 cursor-pointer flex justify-between items-center">
                <a href="#san-domino" class="border-b-2 py-1 flex-1 pl-2 text-xs border-l-8 border-r-8 border-blue-100">
                  <i class="fa fa-caret-right mr-1" />
                  Isole Tremiti San Domino
                </a>
              </li>

              <li v-if="$route.name == 'company-heli-network'" class="hover:bg-gray-200 text-blue-800 cursor-pointer flex justify-between items-center">
                <a href="#vieste" class="border-b-2 py-1 flex-1 pl-2 text-xs border-l-8 border-r-8 border-blue-100">
                  <i class="fa fa-caret-right mr-1" />
                  Vieste
                </a>
              </li>

              <li v-if="$route.name == 'company-heli-network'" class="hover:bg-gray-200 text-blue-800 cursor-pointer flex justify-between items-center">
                <a href="#melendugno" class="border-b-2 py-1 flex-1 pl-2 text-xs border-l-8 border-r-8 border-blue-100">
                  <i class="fa fa-caret-right mr-1" />
                  Melendugno
                </a>
              </li>

              <li v-if="$route.name == 'company-heli-network'" class="hover:bg-gray-200 text-blue-800 cursor-pointer border-b flex justify-between items-center">
                <a href="#sgr" class="border-b-2 py-1 flex-1 pl-2 text-xs border-l-8 border-r-8 border-blue-100">
                  <i class="fa fa-caret-right mr-1" />
                  San Giovanni Rotondo
                </a>
              </li>

              <li v-if="$route.name == 'company-heli-network'" class="hover:bg-gray-200 text-blue-800 cursor-pointer border-b flex justify-between items-center">
                <a href="#celenza" class="border-b-2 py-1 flex-1 pl-2 text-xs border-l-8 border-r-8 border-blue-100">
                  <i class="fa fa-caret-right mr-1" />
                  Celenza Valfortore
                </a>
              </li>

              <li v-if="$route.name == 'company-heli-network'" class="hover:bg-gray-200 text-blue-800 cursor-pointer border-b flex justify-between items-center">
                <a href="#peschici" class="border-b-2 py-1 flex-1 pl-2 text-xs border-l-8 border-r-8 border-blue-100">
                  <i class="fa fa-caret-right mr-1" />
                  Peschici
                </a>
              </li>

              <li v-if="$route.name == 'company-heli-network'" class="hover:bg-gray-200 text-blue-800 cursor-pointer border-b flex justify-between items-center">
                <a href="#supersano" class="border-b-2 py-1 flex-1 pl-2 text-xs border-l-8 border-r-8 border-blue-100">
                  <i class="fa fa-caret-right mr-1" />
                  Supersano
                </a>
              </li>

              <li v-if="$route.name == 'company-heli-network'" class="hover:bg-gray-200 text-blue-800 cursor-pointer border-b flex justify-between items-center">
                <a href="#bari" class="border-b-2 py-1 flex-1 pl-2 text-xs border-l-8 border-r-8 border-blue-100">
                  <i class="fa fa-caret-right mr-1" />
                  Bari Policlinico
                </a>
              </li>

              <li v-if="$route.name == 'company-heli-network'" class="hover:bg-gray-200 text-blue-800 cursor-pointer border-b flex justify-between items-center">
                <a href="#san-nicola" class="border-b-8 py-1 flex-1 pl-2 text-xs border-l-8 border-r-8 border-blue-100">
                  <i class="fa fa-caret-right mr-1" />
                  Isole Tremiti San Nicola
                </a>
              </li>

              <router-link tag="li" :to="{ name: 'company-doa' }" class="hover:bg-gray-200 text-blue-800 cursor-pointer border-b p-1 flex justify-between items-center" :class="sidebarItemActive('company-doa')">
                <span>
                  Organizzazione D.O.A.
                </span>
                <i class="fa fa-caret-right" />
              </router-link>

              <router-link tag="li" :to="{ name: 'company-training' }" class="hover:bg-gray-200 text-blue-800 cursor-pointer border-b p-1 flex justify-between items-center" :class="sidebarItemActive('company-training')">
                <span>
                  Centro di Addestramento
                </span>
                <i class="fa fa-caret-right" />
              </router-link>

              <router-link tag="li" :to="{ name: 'company-tea-centre' }" class="hover:bg-gray-200 text-blue-800 cursor-pointer border-b p-1 flex justify-between items-center" :class="sidebarItemActive('company-tea-centre')">
                <span>
                  Centro per esami TEA
                </span>
                <i class="fa fa-caret-right" />
              </router-link>

              <li>
                <div class="mt-5">
                  <!-- Carousel items -->
                  <div class="carousel-inner">
                    <div class="item active">
                      <img style="width:350px" src="/img/carousel/Company-01.jpg" />
                    </div>
                    <div class="item hidden">
                      <img style="width:350px" src="/img/carousel/Company-02.jpg" />
                    </div>
                    <div class="item hidden">
                      <img style="width:350px" src="/img/carousel/Company-03.jpg" />
                    </div>
                    <div class="item hidden">
                      <img style="width:350px" src="/img/carousel/Company-04.jpg" />
                    </div>
                    <div class="item hidden">
                      <img style="width:350px" src="/img/carousel/Company-05.jpg" />
                    </div>
                  </div>
                  <!-- Carousel nav -->
                  <a class="carousel-control left" href="#myCarousel" data-slide="prev">
                    <i class="fa fa-angle-left"></i>
                  </a>
                  <a class="carousel-control right" href="#myCarousel" data-slide="next">
                    <i class="fa fa-angle-right"></i>
                  </a>
                </div>
              </li>
              <li style="padding-top:30px;">
                <iframe class="w-full" height="165" src="https://www.youtube.com/embed/GjgSnATuwEU" frameborder="0" allowfullscreen="" />
              </li>
            </ul>
            <!-- CATEGORIES END -->
          </div>
          <!-- END SIDEBAR -->
        </div>
			</div>
		</div>
	</section>
</template>

<script>
export default {
  name: 'HelipadPosts',
  data:() => ({
    loading: false,
    posts: []
  }),
  computed: {
    lang() {
      return this.$parent.lang
    }
  },
  methods: {
    sidebarItemActive(route) {
      if (route == this.$route.name) {
        return ['font-semibold text-blue-800 bg-blue-100']
      }
    },
    async fetchData() {
      try {
        this.loading = true
        let { data } = await api.get('api.helipad-posts.index')
        this.posts = data.posts
        this.loading = false
      }
      catch(error) {
        callErrors(error)
        this.loading = false
      }
    }
  },
  created() {
    this.fetchData()
  }
}
</script>
