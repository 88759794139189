<template>
  <div class="mt-10 w-full bg-white border-8 border-green-600 text-white text-lg p-2 flex flex-col leading-normal rounded">
    <div class="text-base text-center flex flex-col items-center justify-center p-2">
      <div class="mt-4 text-green-900">
        La prenotazione <span class="font-bold">{{ bookingCode }}</span> dev'essere confermata tramite pagamento entro i prossimi <span class="font-bold">{{ countdown_ms }}</span> minuti.
      </div>
      <div v-if="paypalButtonsVisible" id="paypal-button-container" class="mt-10" style="width:500px"></div>
      <div v-if="! paypalButtonsVisible" class="text-center">
        Prenotazione in attesa di convalida. Ti preghiamo di attendere...
      </div>
      <p id="result-message"></p>
      <!-- <div class="mt-6 text-green-900">
        <button @click="cancelBooking" class="bg-red-600 p-2 rounded text-sm text-white w-64">
          annulla questa prenotazione
        </button>
      </div> -->
    </div>
  </div>
</template>

<script>
import { inject, ref, onMounted } from 'vue'

export default {
  name: 'my-tickets-paypal',
  data:() => ({
    paypalButtonsVisible: true,
    bookingRequestUuid: null,
    bookingCode: null,
    amount: null,
    countdown: 0,
    countdown_ms: null,
  }),
  methods: {
    renderPayPalButtons(bookingCode, amount) {

      let bookingRequestUuid = this.bookingRequestUuid
      let parentLoading = this.$parent.loading
      let paypalButtonsVisible = this.paypalButtonsVisible

      window.paypal
        .Buttons({
            style: {
              shape: 'rect',
              //color:'blue', change the default color of the buttons
              layout: 'vertical', //default value. Can be changed to horizontal
            },
            async createOrder() {
              try {
                console.log({
                          reference_id: `${bookingCode}`,
                          description: `${bookingCode}`,
                          amount: {
                              currency_code: 'EUR',
                              value: `${amount}`,
                          },
                      })
                  const response = await fetch("https://alidaunia.it/api/paypal/api/orders", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    // use the "body" param to optionally pass additional order information
                    // like product ids and quantities
                    body: JSON.stringify({
                        cart: [
                            {
                                reference_id: `${bookingCode}`,
                                description: `${bookingCode}`,
                                amount: {
                                    currency_code: 'EUR',
                                    value: `${amount}`,
                                },
                            }
                        ],
                    }),
                  });

                  const orderData = await response.json();

                  if (orderData.id) {
                  return orderData.id;
                  } else {
                  const errorDetail = orderData?.details?.[0];
                  const errorMessage = errorDetail
                      ? `${errorDetail.issue} ${errorDetail.description} (${orderData.debug_id})`
                      : JSON.stringify(orderData);

                  throw new Error(errorMessage);
                  }
              } catch (error) {
                  console.error(error);
                  resultMessage(`Could not initiate PayPal Checkout...<br><br>${error}`);
              }
            },
            async onApprove(data, actions) {
              try {
                  const response = await fetch(`https://alidaunia.it/api/paypal/api/orders/${data.orderID}/capture`, {
                  method: "POST",
                  headers: {
                      "Content-Type": "application/json",
                  },
                  });

                  const orderData = await response.json();
                  // Three cases to handle:
                  //   (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
                  //   (2) Other non-recoverable errors -> Show a failure message
                  //   (3) Successful transaction -> Show confirmation or thank you message

                  const errorDetail = orderData?.details?.[0];

                  if (errorDetail?.issue === "INSTRUMENT_DECLINED") {
                  // (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
                  // recoverable state, per https://developer.paypal.com/docs/checkout/standard/customize/handle-funding-failures/
                  return actions.restart();
                  } else if (errorDetail) {
                  // (2) Other non-recoverable errors -> Show a failure message
                  throw new Error(`${errorDetail.description} (${orderData.debug_id})`);
                  } else if (!orderData.purchase_units) {
                  throw new Error(JSON.stringify(orderData));
                  } else {
                  // (3) Successful transaction -> Show confirmation or thank you message
                  // Or go to another URL:  actions.redirect('thank_you.html');
                  const transaction =
                      orderData?.purchase_units?.[0]?.payments?.captures?.[0] ||
                      orderData?.purchase_units?.[0]?.payments?.authorizations?.[0];
                  resultMessage(
                      `Transaction ${transaction.status}: ${transaction.id}<br><br>See console for all available details`,
                  );

                console.log(
                    "Capture result",
                    orderData,
                    JSON.stringify(orderData, null, 2),
                );

                console.log(orderData.status)
                if (orderData.status == 'COMPLETED') {
                  console.log('HERE - COMPLETED')
                  console.log(paypalButtonsVisible)
                  paypalButtonsVisible = false
                  console.log(paypalButtonsVisible)
                }

                const handlePayPalResponse = ({ response, requestUuid, userId }) => {

                  const { status, messages, type, bank_link, redirect_link, amount:_amount, bookingCode:_bookingCode } = response

                  console.log(_amount)
                  console.log(_bookingCode)

                  // amount.value = _amount
                  // bookingCode.value = _bookingCode

                  if (! status) {
                    notifyW(messages[language])
                    parentLoading = false
                    return
                  }

                  if (status === true && [1,-1].includes(type))
                  {
                    // interrompe chiamata api/xhr di check-response-status
                    clearInterval(checkRequestStatus)

                    // notifyS('Prenotazione confermata')
                    parentLoading = false
                  }

                  if (status === true && redirect_link)
                  {
                    location.href = redirect_link
                  }
                }

                let checkRequestStatus = setInterval(async() => {
                  let { data:data1 } = await api.get('api.bookings-requests.retrieve-status', {
                    bookingRequestUuid,
                    paymentType: 1
                  })
                  console.log(data1)
                  if (! data1) { return }
                  handlePayPalResponse(data1)
                }, 3000)

                // if (orderData?.status == 'COMPLETED') {
                //     // let { data } = await api.post('api.booking-request-detail', { bookingCode })
                //     // location.href = `https://dev-web.alidaunia.it/booking-status/${data.uuid}`
                //     // return
                // }

                  }
              } catch (error) {
                  console.error(error);
                  resultMessage(
                  `Sorry, your transaction could not be processed...<br><br>${error}`,
                  );
              }
            },
        })
        .render("#paypal-button-container");
    },
    cancelBooking: async() => {
      await console.log('aaaa')
      // await api.put(route('api.booking-request-cancel', { bookingCode: this.bookingCode }))
      // location.href = `/my-tickets`
    }
  },
  mounted() {
    this.bookingRequestUuid = this.$parent.bookingRequestUuid
    this.bookingCode = this.$parent.bookingCode
    this.amount = this.$parent.amount
    console.log(`bookingRequestUuid ${this.bookingRequestUuid}`)
    console.log(`bookingCode ${this.bookingCode}`)
    console.log(`amount ${this.amount}`)

    this.renderPayPalButtons(this.bookingCode, this.amount)

    setInterval(() => {
      this.countdown_ms = moment(`2000-01-01T00:10:00`).subtract(this.countdown, 'seconds').format('mm:ss')
      if (this.countdown < 600) {
          this.countdown++
      }
    }, 1000)
  }
}
</script>
