<template>
	<section class="w-full max-w-5xl mx-auto bg-white min-h-screen">
		<div class="w-full flex flex-col">
			<div class="flex flex-col">
        
				<div class="flex">
          <div class="w-full flex my-2 h-8">
            <span class="flex-1 self-center border-t border-gray-300"></span>
            <span class="text-xl font-semibold text-blue-900 text-center px-3">
              PRENOTAZIONI CON TRANSAZIONE NON COMPLETATA (ultime 24h)
            </span>
            <span class="flex-1 self-center border-t border-gray-300"></span>
          </div>
        </div>

				<div class="relative flex flex-col border rounded mt-5">
					<vue-loading :loading="loading" />
					<div class="flex flex-col p-3">
						<table id="bookings-failed" class="w-full border shadow">
							<thead>
								<tr class="text-left bg-gray-200 text-gray-800 text-sm font-semibold border-b border-gray-400">
									<th class="text-center"></th>
									<th class="px-3 whitespace-no-wrap">prenotazione</th>
									<th class="pl-2">codice</th>
									<th>data volo</th>
									<th>volo</th>
									<th>tratta</th>
									<th>passeggeri</th>
									<th>importo</th>
									<th class="text-center">rimuovi</th>
								</tr>
							</thead>
							<tbody>
								<template v-for="(booking, index) in bookings">
									<tr class="text-left" :key="booking.code" :class="{ 'bg-white': index % 2 == 0, 'bg-blue-100': index % 2 != 0 }">
										<td>
											<div class="flex items-center justify-start pl-1">
												<fa v-if="booking.pending" class="s-exclamation-triangle mr-1 text-red-600" content="questa prenotazione sta occupando posti" v-tippy="{ arrow: true }" />
												<vue-checkbox v-if="! booking.disabled_flag" v-model="booking.flagged" @change="checked(booking)" />
												<div v-else class="w-5 h-5 bg-gray-200 border border-gray-500 rounded cursor-not-allowed" :content="`in uso da ${booking.in_use_by_user}`" v-tippy="{ arrow: true }" />
											</div>
										</td>
										<td>
											<div class="px-3">
												<span :class="{ 'bg-yellow-300 rounded': booking.user != $parent.auth.user.email }">{{ booking.user }}</span><br>
												{{ booking.bookingDate }}
											</div>
										</td>
										<td class="pl-2">
											{{ booking.code }}
											<fa class="s-info-circle text-xs text-red-600" :content="booking.error_description" v-tippy="{ arrow: true }" />
										</td>
										<td>
											<div>{{ booking.departureFlight.date }} {{ booking.departureFlight.time }}</div>
											<div v-if="booking.comebackFlight">{{ booking.comebackFlight.date }} {{ booking.comebackFlight.time }}</div>
										</td>
										<td>
											<div>{{ booking.departureFlight.code }}</div>
											<div v-if="booking.comebackFlight">{{ booking.comebackFlight.code }}</div>
										</td>
										<td>
											<div>{{ booking.departureFlight.source }} ► {{ booking.departureFlight.destination }}</div>
											<div v-if="booking.comebackFlight">{{ booking.comebackFlight.source }} ► {{ booking.comebackFlight.destination }}</div>
										</td>
										<td>
											<div v-for="pax in booking.paxes">
												{{ pax.lastname }} {{ pax.firstname }}
											</div>
										</td>
										<td>
											{{ booking.amount }} €
										</td>
										<td class="text-center">
											<div class="w-full flex justify-center">

												<div class="flex justify-between items-center border border-gray-400 rounded bg-white" style="width:64px">
													<button v-if="! booking.disabled_lock" @click="clickLock(booking)" type="button" class="w-1/2 h-full p-1 flex justify-center items-center focus:outline-none">
														<fa :class="{ 's-lock text-red-500': booking.locked, 's-lock-open text-green-500': ! booking.locked }" />
													</button>
													<div v-else class="w-1/2 h-full p-1 flex justify-center items-center focus:outline-none">
														<fa class="s-lock cursor-not-allowed focus:outline-none" :content="`in uso da ${booking.in_use_by_user}`" v-tippy="{ arrow: true }" />
													</div>
													<button @click.prevent="discard(booking)" type="button" class="w-1/2 px-2 flex justify-center h-full text-white text-sm font-semibold p-1" :class="{ 'bg-gray-400 pointer-events-none': booking.locked, 'bg-red-600 hover:bg-red-700': ! booking.locked }">
														<fa class="s-trash" />
													</button>
												</div>
											</div>
										</td>
									</tr>
									<tr v-show="booking.flagged">
										<td colspan="9" class="bg-green-100">
											<div v-if="! redirectUrl" v-show="booking.flagged" class="flex justify-center items-center py-1">
												<span class="mr-2 italic">email invio link pagamento Banca Sella</span>
												<input v-model="booking.contacts.email_payment_link" class="border py-1 px-2 w-56 rounded-l" type="text" placeholder="email destinatario" />
												<button @click.prevent="storeBooking(booking)" type="button" class="w-32 text-white text-sm font-semibold p-1 rounded-r bg-green-500 hover:bg-green-600 border border-green-500">
													invia link
												</button>
											</div>
											<div v-if="redirectUrl" class="bg-green-400 border border-green-600 text-white text-lg p-2 flex flex-col leading-normal rounded m-2">
												<p class="text-base text-center flex flex-col md:flex-row items-center justify-center">								
													<fa class="s-check mr-2" />
													Link di pagamento
													<span class="text-black rounded border border-gray-200 bg-white opacity-75 px-1 py-0 leading-none mx-1 flex items-center">
														<a target="_blank" :href="redirectUrl" v-text="redirectUrl" />
														<fa class="r-copy ml-2 cursor-pointer" v-clipboard="() => redirectUrl" title="clicca per copiare" v-tippy="{ arrow : true }" />
													</span>					
													inviato all'indirizzo
													<span class="italic ml-1" v-text="booking.contacts.email_payment_link" />
												</p>
											</div>
										</td>
									</tr>
								</template>
							</tbody>
						</table>
						<div class="italic mt-2">
							<span v-if="checkRoles(['Prenotazioni', 'PrenotaFull'])">
								<b>ATTENZIONE!</b><br>
								L'icona <fa class="s-exclamation-triangle text-red-600" /> indica che la prenotazione <u>sta ancora occupando posti e potrebbe essere ancora in fase di pagamento</u>.<br>
								Quando l'email dell'utente che ha creato la prenotazione è <span class="bg-yellow-300">evidenziata in giallo</span>, la procedura di pagamento potrebbe essere ancora in corso!<br>
								Se la prenotazione è stata creata da te (o sei certo di poterla gestire), puoi riprovare il pagamento o annullarla per liberare i posti.<br>
							</span><br>
							<span class="italic text-xs">istruzioni per l'uso :</span><br>
							• la prenotazione per cui vuoi riprovare il pagamento;<br>
							• inserisci la mail a cui ricevere il link di pagamento e clicca "invia link";<br>
							• dopo nuova verifica sulla diponibilità dei posti, si attiverà la procedura di pagamento;<br>
							• per rimuovere una prenotazione dall'elenco, premi il lucchetto per sbloccare e cliccare sul cestino.
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {
	name: 'bookings-failed',
	data:() => ({
		loading: false,
		bookings: [],
		redirectUrl: null
	}),
	methods: {
		async clickLock(booking) {
			let { code } = booking

			booking.locked = ! booking.locked

			let status = ! booking.locked

			booking.flagged = false
			booking.disabled_flag = status
			booking.in_use_by_user = this.$parent.auth.user.email

			let { data } = await api.post('api.bookings.lock', { code, status, locked: true })

			window.Echo
				.private(`bookings-failed`)
				.whisper('BookingHandled', { code, status, userEmail: this.$parent.auth.user.email })
		},
		async checked(booking) {
			let { code } = booking

			this.bookings.filter(b => b.code != code).forEach(b => b.flagged = false)

			var status = booking.flagged
			
			let { data } = await api.post('api.bookings.lock', { code, status })

			booking.locked = true
			booking.disabled_lock = status
			booking.in_use_by_user = this.$parent.auth.user.email

			window.Echo
				.private(`bookings-failed`)
				.whisper('BookingHandled', { code, status, userEmail: this.$parent.auth.user.email })

			this.redirectUrl = null
		},
		async storeBooking(booking) {

			let type = -1

			let form = {
				originId: booking.originId,
				destinationId: booking.destinationId,
				departureDate: booking.departureFlight ? booking.departureFlight.date : null,
				comebackDate: booking.comebackFlight ? booking.comebackFlight.date : null,
				departureFlightId: booking.departureFlightId,
				comebackFlightId: booking.comebackFlightId,
				paxes: booking.paxes,
				categories: {
					men: booking.paxes.filter(p => p.category == 'a' && p.gender == 'M').length,
					women: booking.paxes.filter(p => p.category == 'a' && p.gender == 'F').length,
					children: booking.paxes.filter(p => p.category == 'c').length,
					infants: booking.paxes.filter(p => p.category == 'i').length
				},
				contacts: booking.contacts
			}
		
			function validateEmail(email) {
				var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
				return re.test(String(email).toLowerCase());
			}

			if (type == -1) {
				if (! validateEmail(form.contacts.email_payment_link)) {
					notifyW('Email per invio link non valida!<br \>- ricontrollare anche possibili spazi vuoti -')		
					return
				}
			}

			this.loading = true

			try {
				let invoice = null
				let { bookingChannel } = this

				let paxesList = clone(form.paxes).map(p => {

					let pax = {
						id: p.id,
						category: p.category,
						gender: p.gender,
						birthDate: p.birthDate,
						residence: p.residence,
						document_type: p.document_type,
						document_num: p.document_num,
						email: p.email,
						telephone: p.telephone
					}

					if (p.id < 0) {
						pax = { ...pax, ...{
								lastname: p.lastname,
								firstname: p.firstname,
								gender: p.gender
							}
						}
					}

					if (this.checkRoles(['Prenotazioni', 'PrenotaFull'])) {
						pax = { ...pax, ...{
								exemption: p.exemption,
								dsc: p.dsc
							}
						}
					}

					return pax
				})

				form = {
					originId: form.originId,
					destinationId: form.destinationId,
					departureFlightId: form.departureFlightId,
					comebackFlightId: form.comebackFlightId,
					contacts: {
						email: form.contacts.email,
						email_payment_link: form.contacts.email_payment_link,
						telephone: form.contacts.telephone
					},
					// coupon: form.coupon.code,
					email_forms_pdf: form.email_forms_pdf,
					paxes: paxesList
				}

				let payload = {
					type,
					form,
					invoice,
					bookingChannel,
					retry: true,
					previousBooking: booking.code,
					pending: booking.pending
				}

				let { data } = await api.post('api.bookings.store', payload)
				window.scrollTo(0, 0)

				if (data.hasOwnProperty('status') && data.status == 'success') {

					if (data.hasOwnProperty('bank_link')) {
						if (data.type == 1) {
							location.href = data.bank_link
							return
						}
						else if (data.type == -1) {
							this.redirectUrl = data.bank_link
							this.loading = false
							this.step = 42							
							return
						}
					}

					var url = location.origin + '/booking-status/' + btoa(data.bookingCode)
					this.redirectUrl = url
					window.location = url

					setInterval(() => {
						this.redirectTimeout--
						if (this.redirectTimeout <= 0) {
							this.loading = false
							this.step = 40
						}
					}, 1000)
				}

				if (data.hasOwnProperty('status') && data.status == 'error') {
					notifyE(data.messages.it + '<br>' + data.messages.en)
				}

				if (data.hasOwnProperty('status') && data.status == 'block-confirmed') {
					this.loading = false
					this.step = 1

					this.bloccaPostiRisposta = data.message
					this.bloccaPostiPannello = true
				}

				this.loading = false
			}
			catch (error) {
				callErrors(error)
				if (error.response.status == 401) {
					this.goToStep(401)
				}				

				this.loading = false
			}
		},
		async discard(booking) {
			try {
				let { code, pending } = booking
				this.loading = true

				let { data } = await api.put(route('api.bookings-failed.update', { booking: booking.code }), { pending: booking.pending })
				
				if (booking.pending) {
					notifyS(`E' stata rimossa e sono stati liberati i relativi posti`, { title: `Prenotazione ${booking.code}` })
				}
				else {
					notifyS(`E' stata rimossa`, { title: `Prenotazione ${booking.code}` })
				}

				this.bookings = this.bookings.filter(b => b.code != booking.code)

				window.Echo
					.private(`bookings-failed`)
					.whisper('BookingDiscarded', { code, pending, userEmail: this.$parent.auth.user.email })

				this.loading = false
			}
			catch(error) {
				callErrors(error)
				this.loading = false
			}
		},
		checkRoles(roles) {
      return this.$parent.roles.filter(x => roles.includes(x)).length
		},		
		async fetchBooking() {
			try {
				this.loading = true

				let { data } = await api.get('api.bookings-failed.index')
				this.bookings = data.bookings

				this.loading = false
			}
			catch (error) {
				this.loading = false
				callErrors(error)
			}
		},
		eventBookingHandled(e) {
			let { code, status, userEmail } = e

			var matchingBookings = this.bookings.filter(b => b.code == code)
			if (matchingBookings.length) {
				var booking = matchingBookings[0]

				booking.flagged = false
				booking.disabled_flag = status

				booking.locked = true
				booking.disabled_lock = status

				if (status) {
					booking.in_use_by_user = userEmail
					notifyW(`E' stata presa in carico da ${userEmail}`, { title: `Prenotazione ${code}` })
				}
				else {
					booking.in_use_by_user = null
					notifyS(`E' stata rilasciata da ${userEmail}<br>ed è disponibile per selezione/rimozione`, { title: `Prenotazione ${code}` })
				}
			}	
			else {
				console.log('handled booking not listed')
			}
		},
		eventBookingDiscarded(e) {
			let { code, pending, userEmail } = e

			var matchingBookings = this.bookings.filter(b => b.code == code)
			if (matchingBookings.length) {
				this.bookings = this.bookings.filter(b => b.code != code)

				if (pending) {
					notifyS(`E' stata rimossa da ${userEmail}<br> e sono stati liberati i relativi posti`, { title: `Prenotazione ${code}` })
				}
				else {
					notifyS(`E' stata rimossa da ${userEmail}`, { title: `Prenotazione ${code}` })
				}			
			}
			else {
				console.log('discarded booking not listed')
			}			
		}
	},
	created() {
		window.Echo
			.private(`bookings-failed`)
			.listenForWhisper('BookingHandled', e => this.eventBookingHandled(e))
			.listenForWhisper('BookingDiscarded', e => this.eventBookingDiscarded(e))

		this.fetchBooking()

		if (this.bloccate) {
			this.prenBloccate = JSON.parse(this.bloccate)
		}

		if (window.location.href.includes('admin/posti-bloccati')) {
			this.step = 0
		}
	}
}
</script>

<style>
table#bookings-failed tr td { padding: 10px 2px 10px 2px !important; }
table#bookings-failed tr th { font-weight: inherit !important; }
</style>
