import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

import SignUp from './components/SignUp'
import PasswordRequest from './components/PasswordRequest'
import PasswordReset from './components/PasswordReset'
import LoginForm from './components/LoginForm'
import PendingPage from './components/PendingPage'
import Logout from './components/Logout'

import MyTickets from './components/MyTickets'
import ManageAccount from './components/ManageAccount'

import HomePage from './components/HomePage'
import HomePagePreview from './components/HomePagePreview'
import BookingsWizard from './components/BookingsWizard'
import BookingsFailed from './components/BookingsFailed'
import BookingStatus from './components/BookingStatus'
import Timetable from './components/Timetable'
import Fares from './components/Fares'
import Company from './components/Company'
import Contacts from './components/Contacts'
import Directions from './components/Directions'

import HelipadPost from './components/HelipadPosts/HelipadPost'
import HelipadPosts from './components/HelipadPosts/HelipadPosts'

const routes = [
  {
    path: '/sign-up',
    name: 'sign-up',
    component: SignUp
  },
  {
    path: '/password/reset',
    name: 'password-request',
    component: PasswordRequest
  },
  {
    path: '/password/reset/:token',
    name: 'password-reset',
    component: PasswordReset
  },
  {
    path: '/login',
    name: 'login',
    component: LoginForm
  },
  {
    path: '/pending',
    name: 'pending',
    component: PendingPage
  },
  {
    path: '/logout',
    name: 'logout',
    component: Logout
  },
  {
    path: '/account',
    name: 'manage-account',
    component: ManageAccount
  },
  {
    path: '',
    name: 'home',
    component: HomePage,
  },
  {
    path: '/home-preview',
    name: 'home-preview',
    component: HomePagePreview,
  },
  // {
  //   path: '/booking',
  //   name: 'booking',
  //   component: BookingsWizard
  // },
  {
    path: '/booking-status/:code',
    name: 'booking-status',
    component: BookingStatus
  },
  {
    path: '/bookings-failed',
    name: 'bookings-failed',
    component: BookingsFailed
  },
  {
    path: '/my-tickets',
    name: 'my-tickets',
    component: MyTickets
  },
  {
    path: '/timetable',
    name: 'timetable',
    component: Timetable,
  },
  {
    path: '/fares',
    name: 'fares',
    component: Fares,
  },
  {
    path: '/company',
    component: Company,
    children: [
      {
        path: '',
        name: 'company-profile',
        component: Company
      },
      {
        path: 'flight-operations',
        name: 'company-flight-operations',
        component: Company
      },
      {
        path: 'civil-protection',
        name: 'company-civil-protection',
        component: Company
      },
      {
        path: 'scheduled-flights',
        name: 'company-scheduled-flights',
        component: Company
      },
      {
        path: 'offshore',
        name: 'company-offshore',
        component: Company
      },
      {
        path: 'medical-transport',
        name: 'company-medical-transport',
        component: Company
      },
      {
        path: 'additional-services',
        name: 'company-additional-services',
        component: Company
      },
      {
        path: 'maintenance',
        name: 'company-maintenance',
        component: Company
      },
      {
        path: 'auths-certs',
        name: 'company-auths-certs',
        component: Company
      },
      {
        path: 'heliport-design-construction-management',
        name: 'company-heliport-design-construction-management',
        component: Company
      },
      {
        path: 'apulian-heli-network',
        name: 'company-apulian-heli-network',
        component: Company
      },
      {
        path: 'doa',
        name: 'company-doa',
        component: Company
      },
      {
        path: 'training',
        name: 'company-training',
        component: Company,
      },
      {
        path: 'finanzia-il-tuo-addestramento',
        name: 'company-training-finanziamento',
        component: Company,
      },
      {
        path: 'tea-centre',
        name: 'company-tea-centre',
        component: Company
      },
    ]
  },
  {
    path: '/contacts',
    name: 'contacts',
    component: Contacts,
  },
  {
    path: '/come-raggiungerci',
    name: 'come-raggiungerci',
    component: Directions,
  },
  {
    path: '/directions',
    name: 'directions',
    component: Directions,
  },
  {
    path: '/helipad-posts',
    name: 'helipad-posts',
    component: HelipadPosts
  },
  {
    path: '/helipad-posts/:slug',
    name: 'helipad-post',
    component: HelipadPost
  },
  {
    path: '/setup-email'
  },
  {
    path: '*',
    redirect: {
      name: 'home'
    }
  }
]

const router = new Router({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  console.log(`${from.path} >>> ${to.path}`)
  window.scrollTo(0, 0)
  next()
})

export default router
